import {
  professionalPricingIds,
  businessPricingIds,
} from '../../assets/stripeKeys';
import moment from 'moment';
import { useAxiosWithHeader } from '../../utils';
import { useHistory } from 'react-router-dom';
import { InitCheckoutSession, RedirectToPortal } from '../Stripe/utils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ButtonCss = ({ danger, inactive, children, onClickAction }) => {
  return (
    <a
      onClick={() => onClickAction()}
      className={classNames(
        'mt-8 block w-full bg-primary border border-primary rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700 hover:text-white',
        danger && 'bg-red-500 hover:text-white hover:bg-red-700 border-red-500',
        inactive &&
          'bg-gray-300 text-gray-800 border-gray-300 hover:bg-gray-300 cursor-default',
      )}
    >
      {children}
    </a>
  );
};

export const ButtonBilling = ({
  tier,
  organization,
  players,
  monthlySelected,
}) => {
  const activePlayers = players?.filter(
    (player) => player.communicationToolId?.length > 0,
  )?.length;
  const plan = organization?.plan;
  const axios = useAxiosWithHeader();
  const trialDayRemaining = moment(organization?.createdAt)
    .add('14', 'days')
    .diff(moment(), 'days');
  const history = useHistory();

  const TierFree = () => {
    if (!plan || plan === 'starter')
      return <ButtonCss inactive={true}>Current plan ✅</ButtonCss>;
    else if (
      (plan === 'professional' || plan === 'blocked' || plan === 'business') &&
      activePlayers <= 5
    ) {
      return (
        <ButtonCss
          danger={true}
          onClickAction={() => {
            window.$crisp.push(['do', 'chat:open']);
            window.$crisp.push([
              'set',
              'message:text',
              ['I want to downgrade my plan on Axolo.'],
            ]);
          }}
        >
          Downgrade
        </ButtonCss>
      );
    } else if (plan === 'business' && activePlayers > 50) {
      return (
        <ButtonCss inactive={true}>
          Offboard {activePlayers - 50} engineers to{' '}
          {plan === 'blocked' ? 'enable' : 'downgrade'}
        </ButtonCss>
      );
    } else if (
      (plan === 'professional' || plan === 'business' || plan === 'blocked') &&
      activePlayers > 5
    ) {
      return (
        <ButtonCss inactive={true}>
          Offboard {activePlayers - 5} engineers to{' '}
          {plan === 'blocked' ? 'enable' : 'downgrade'}
        </ButtonCss>
      );
    } else {
      return (
        <ButtonCss inactive={true}>
          {trialDayRemaining} days left in trial
        </ButtonCss>
      );
    }
  };

  const TierStandard = () => {
    if (!plan) {
      return (
        <ButtonCss onClickAction={() => history.push('/home')}>
          Complete your onboarding
        </ButtonCss>
      );
    } else if (plan === 'business' && activePlayers <= 50) {
      return (
        <ButtonCss
          danger={true}
          onClickAction={() => {
            window.$crisp.push(['do', 'chat:open']);
          }}
        >
          Downgrade
        </ButtonCss>
      );
    } else if (plan === 'business' && activePlayers > 50) {
      return (
        <ButtonCss inactive={true}>{'>'} 50 engineers connected</ButtonCss>
      );
    } else if (plan === 'starter' || plan === 'blocked')
      return (
        <ButtonCss
          onClickAction={() =>
            InitCheckoutSession({
              priceId: monthlySelected
                ? professionalPricingIds.monthly
                : professionalPricingIds.yearly,
              quantity: activePlayers,
              axios,
            })
          }
        >
          Upgrade
        </ButtonCss>
      );
    else if (plan === 'professional') {
      return (
        <ButtonCss onClickAction={() => RedirectToPortal({ axios })}>
          Current plan ✅
        </ButtonCss>
      );
    } else if (plan === 'trial') {
      return (
        <ButtonCss
          onClickAction={() =>
            InitCheckoutSession({
              priceId: monthlySelected
                ? professionalPricingIds.monthly
                : professionalPricingIds.yearly,
              quantity: activePlayers,
              axios,
            })
          }
        >
          Upgrade free trial
        </ButtonCss>
      );
    }
  };

  const TierBusiness = () => {
    if (!plan) {
      return (
        <ButtonCss onClickAction={() => history.push('/home')}>
          Complete your onboarding
        </ButtonCss>
      );
    } else if (
      plan === 'starter' ||
      plan === 'blocked' ||
      plan === 'professional'
    )
      return (
        <ButtonCss
          onClickAction={() =>
            InitCheckoutSession({
              priceId: monthlySelected
                ? businessPricingIds.monthly
                : businessPricingIds.yearly,
              quantity: activePlayers,
              axios,
            })
          }
        >
          Upgrade
        </ButtonCss>
      );
    else if (plan === 'business') {
      return (
        <ButtonCss onClickAction={() => RedirectToPortal({ axios })}>
          Current plan ✅
        </ButtonCss>
      );
    } else if (plan === 'trial') {
      return (
        <ButtonCss
          onClickAction={() =>
            InitCheckoutSession({
              priceId: monthlySelected
                ? businessPricingIds.monthly
                : businessPricingIds.yearly,
              quantity: activePlayers,
              axios,
            })
          }
        >
          Upgrade free trial
        </ButtonCss>
      );
    }
  };

  if (tier.name === 'Business') {
    return <TierBusiness />;
  } else if (tier.name === 'Free') {
    return <TierFree />;
  } else if (tier.name === 'Standard') {
    return <TierStandard />;
  } else if (tier.name === 'Enterprise') {
    return (
      <a
        href={tier.href}
        target="_blanck"
        rel="noopener noreferrer nofollow"
        className={classNames(
          'mt-8 block w-full bg-primary border border-primary rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700 hover:text-white',
        )}
      >
        {tier.buttonText}
      </a>
    );
  }
};

export const EndButton = ({ isPaying, axios }) => {
  // async function RedirectToPortal({ axios }) {
  //   const resp = await axios.get(`${URLBACK}stripe/customerPortal`);
  //   window.location.href = resp.data.url;
  //   window.analytics.track('redirectToPortal');
  // }
  if (isPaying) {
    return (
      <button
        className="mt-8 block px-3 bg-primary border border-primary rounded-md py-2 text-sm font-semibold text-white hover:text-white text-center hover:bg-blue-700"
        onClick={() => RedirectToPortal({ axios })}
      >
        Manage subscription
      </button>
    );
  } else {
    return (
      <a
        rel="noreferrer"
        className="mt-8 block px-3 bg-primary border border-primary rounded-md py-2 text-sm font-semibold text-white hover:text-white text-center hover:bg-blue-700"
        href="https://axolo.co/pricing"
        target="_blank"
      >
        More info
      </a>
    );
  }
};
