export const UpdateStatusActionAndDeployment = async (setting, status, type, axios) => {
  const newStatus = status.join(',')
  if (type === 'action') {
    try {
      await axios.put(`settings/${setting.id}`, {
        githubActionStatusNotifications: newStatus,
      })
    } catch (e) {
      console.log('Error, please contact support :', e)
    }
  } else if (type === 'deployment') {
    try {
      await axios.put(`settings/${setting.id}`, {
        githubDeploymentStatusNotifications: newStatus,
      })
    } catch (e) {
      console.log('Error, please contact support :', e)
    }
  }
}
