import React from "react";
import bodies from "../../assets/images/axolo_body.gif"
import eyes from "../../assets/images/axolo_eyes.gif"
import mouths from "../../assets/images/axolo_mouths.gif"


export default function AvatarPlayer({ githubAvatar, avatarInfo }) {
    if (avatarInfo === null || avatarInfo === undefined) {
        return (
            <img className='rounded' src={githubAvatar} style={{ width: "96px" }} alt="badge" />
        )
    }
    let { body: bodyPosition, eyes: eyesPosition, mouth: mouthPosition } = avatarInfo;
    bodyPosition = { x: bodyPosition.x / 2, y: bodyPosition.y / 2 }
    eyesPosition = { x: eyesPosition.x / 2, y: eyesPosition.y / 2 }
    mouthPosition = { x: mouthPosition.x / 2, y: mouthPosition.y / 2 }

    // todo .y different de 0 quand nous aurons plus qu'une ligne 
    return (
        <div id="avatarContainer" className="block" >
            <div id="avatarImage" className="justify-self-center">
                <div id="myAvatar" className="w-full h-full relative "
                    style={{ width: "96px", height: "96px" }}>
                    <div id="bodyPosition" className="w-full h-full " style={{
                        backgroundPositionX: `${bodyPosition.x}px`,
                        backgroundPositionY: `${bodyPosition.y}px`,
                        backgroundSize: "960px 960px",
                        backgroundImage: `url(${bodies})`,
                        backgroundRepeat: "no-repeat", position: "absolute",
                    }}>

                    </div>
                    <div id="eyesPosition" className="w-full h-full " style={{
                        backgroundPositionX: `${eyesPosition.x}px`,
                        backgroundPositionY: `${eyesPosition.y}px`,
                        backgroundSize: "960px 960px",
                        backgroundImage: `url(${eyes})`,
                        backgroundRepeat: "no-repeat", position: "absolute",
                    }}>

                    </div>
                    <div id="mouthPositions" className="w-full h-full " style={{
                        backgroundPositionX: `${mouthPosition.x}px`,
                        backgroundPositionY: `${mouthPosition.y}px`,
                        backgroundSize: "960px 960px",
                        backgroundImage: `url(${mouths})`,
                        backgroundRepeat: "no-repeat", position: "absolute",
                    }}>
                    </div>
                </div>
            </div >

        </div >
    )
}