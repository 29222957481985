export const MainDivOnboarding = ({ children }) => {
  return (
    <div className="flex h-[500px] flex-col justify-between sm:h-full sm:min-h-[50vh] ">
      {' '}
      <div className="">{children}</div>
      <p className="mb-0 flex justify-center">
        Need help?{' '}
        <span
          className="cursor-pointer pl-1 text-primary"
          onClick={() => {
            window.$crisp.push(['do', 'chat:open'])
          }}
        >
          Chat with us.
        </span>
      </p>
    </div>
  )
}
