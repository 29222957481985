import { Select } from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Setting } from '../../../reducers/settingReducer'
import { Analytics } from '../../../reducers/analyticsReducer'
import SkeletonLoading from '../utils/skeletonLoader'

const { Option } = Select
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const RepositoryMultiSelect = () => {
  const setting = useSelector(Setting.selectors.getSetting)
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  const { teams } = analytics
  const { selectedTeams } = setting
  const dispatch = useDispatch()
  const children = []
  const allTeamSelected = selectedTeams?.length === teams?.length

  function handleOnChange(newValue) {
    const index = selectedTeams.findIndex((team) => team.id === newValue.id)
    let newSelect = _.cloneDeep(selectedTeams)
    if (index >= 0) {
      newSelect.splice(index, 1)
    } else {
      newSelect.push(newValue)
    }
    const intersection = teams.filter((team) => newValue.includes(team.name))
    dispatch(Setting.actions.setData({ selectedTeams: intersection }))
    if (process.env.NODE_ENV === 'production') {
      window.analytics.track('Update team selection', {
        numberOfTeams: intersection?.length,
      })
    }
  }

  function handleOnClickSelect() {
    if (allTeamSelected) {
      dispatch(Setting.actions.setData({ selectedTeams: [] }))
      if (process.env.NODE_ENV === 'production') {
        window.analytics.track('Unselect all teams', {
          numberOfTeams: 0,
        })
      }
    } else {
      dispatch(Setting.actions.setData({ selectedTeams: teams }))
      if (process.env.NODE_ENV === 'production') {
        window.analytics.track('Select all teams', {
          numberOfTeams: teams?.length,
        })
      }
    }
  }

  for (let i = 0; i < teams?.length; i++) {
    children.push(<Option key={teams[i].name}>{teams[i]?.name}</Option>)
  }

  return (
    <>
      <p className="text-sm font-medium text-gray-500 pt-1 mb-1">Teams</p>
      <div className="flex items-center place-content-between">
        {false ? (
          <div className="w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 ">
            <SkeletonLoading specificCss="" />
          </div>
        ) : (
          <Select
            mode="tags"
            placeholder="Please select your teams"
            value={selectedTeams?.map((team) => team?.name)}
            onChange={(value, options) => handleOnChange(value, options)}
            style={{ width: '100%' }}
            bordered={false}
            maxTagCount="responsive"
            className=" border border-gray-300 rounded-md shadow-sm pl-1 py-1"
          >
            {children}
          </Select>
        )}
        <button
          type="button"
          onClick={() => handleOnClickSelect()}
          className={classNames(
            allTeamSelected
              ? 'bg-redFlash hover:bg-ourRed'
              : 'bg-indigo-600 hover:bg-indigo-700 ',
            'ml-1 flex justify-center items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white w-24',
          )}
        >
          {allTeamSelected ? 'Unselect' : 'Select all'}
        </button>
      </div>
    </>
  )
}

export default RepositoryMultiSelect
