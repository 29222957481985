import { User } from './userReducer';
import { createSlice, current } from '@reduxjs/toolkit';

let initialState = {
  players: [],
  isOnboarded: null
};

export const playersSlice = createSlice({
  name: 'playerState',
  initialState,
  reducers: {
    setIsOnboarded: (state, action) => ({ ...state, isOnboarded: action.payload }),
    setInitialPlayers: (state, action) => {
      return ({
        ...state,
        players: action.payload.players,
      });
    },
    setPlayer: (state, action) => {
      const currentState = current(state)
      const newPlayers = [...currentState.players]
      const player = action.payload
      const index = newPlayers.findIndex(p => p.id === player.id)
      newPlayers[index] = player;
      return ({
        ...state,
        players: newPlayers,
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(User.actions.logout, _ => initialState);
  },
});

export const { setPlayer } = playersSlice.actions;
export const { setInitialPlayers } = playersSlice.actions;
export const { setIsOnboarded } = playersSlice.actions;
export const { setRequestPlayerAuth } = playersSlice.actions

const getPlayers = state => state.playerState.players;
const isOnboarded = state => state.playerState.isOnboarded
const requestPlayerAuth = state => state.playerState.requestPlayerAuth

export const PlayerState = {
  actions: playersSlice.actions,
  selectors: { getPlayers, isOnboarded, requestPlayerAuth },
};

