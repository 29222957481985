import React, { useState } from 'react'
import { URLBACK } from '../../assets/urls'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useEffectOnce, useLocation } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'
import { updateUser } from '../../reducers/userReducer'
import HomeFooter from '../../pages/FooterHomepage/footerHomePage'
import { PlayerState } from '../../reducers/playersReducer'

export default function SlackAuth() {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [Error, setError] = useState(false)

  const onError = () => {
    console.log('Something wrong just happen')
  }

  const loggingUser = async () => {
    try {
      const { data, status } = await Axios.get(
        `${URLBACK}auth/slack/callback${location.search}`,
      )
      const { user, jwt } = data

      const { data: isAdminUser } = await Axios.get(
        `${URLBACK}organizations/isAdminUser/${user.username}`,
      )
      if (status !== 200) {
        onError()
        return
      }
      dispatch(updateUser({ ...user, jwt, isAdminUser }))
      window.$crisp.push(['set', 'user:email', user.email])
      window.analytics.identify(user.email, {
        name: user.name,
        email: user.email,
      })
      // if (user.activeSlackUsers > 50000) {
      //   message.success("Thank you, book a call with us to finish onboarding");
      //   window.location.href = `https://calendly.com/arthur-coudouy/demo-axolo?email=${user.email}`
      // } else
      if (isAdminUser) {
        history.push('/home')
      } else if (user?.role?.type === 'engineer') {
        history.push(`/engineer/settings/standups`)
      } else {
        history.push(`/redirection-admin`)
      }
    } catch (e) {
      console.log('e?.response?.data?.message', e?.response?.data?.message)
      if (
        e?.response?.data?.message &&
        e?.response?.data?.message[0]?.messages &&
        e?.response?.data?.message[0]?.messages[0]?.id ===
          'Auth.form.error.email.taken.username'
      ) {
        setError('Same email different workspace.')
        return
      }
      if (
        e?.response?.data?.message &&
        e?.response?.data?.message[0]?.messages &&
        e?.response?.data?.message[0]?.messages[0]?.id === 'Auth.advanced.couldntsignin'
      ) {
        setError('Could not sign you in, try signing up instead or contact support')
        return
      }
      onError()
      console.error({ e })
      if (typeof e?.response?.data?.message === 'string') {
        setError(e?.response?.data?.message)
      } else {
        setError('Something wrong just happened')
      }
    }
  }

  useEffectOnce(() => {
    loggingUser().then()
  })

  const ErrorMessage = () => {
    return (
      <div className="">
        <h2 className="text-ourBlack mt-6 text-center text-3xl font-bold">
          There has been an error logging you in:
        </h2>
        <p className="mt-2 text-center text-sm text-ourGrey">Error: {Error}</p>
        <p className="mt-2 text-center text-sm text-ourGrey">
          Contact support or try again.
        </p>
      </div>
    )
  }

  const LoadingMessage = () => {
    return (
      <div className="">
        <h2 className="text-ourBlack mt-6 text-center text-3xl font-bold">Loading</h2>
        <div className="mt-24 flex justify-center">
          <Spin spinning />
        </div>
      </div>
    )
  }

  return (
    <div className=" h-screen bg-white">
      <div className="flex h-[88vh] flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-3xl">
          <img className="mx-auto h-12 w-auto" src="/logo_axolo.png" alt="Logo Axolo" />
          {Error ? <ErrorMessage /> : <LoadingMessage />}
        </div>
      </div>
      <HomeFooter />
    </div>
  )
}
