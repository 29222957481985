import { Fragment } from 'react'
import { useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { useDispatch, useSelector } from 'react-redux'
import { PlayerState } from '../../reducers/playersReducer'
import { User } from '../../reducers/userReducer'
import { Organization } from '../../reducers/organizationReducer'
import { useHistory, useLocation } from 'react-router-dom'
import { userMenu, navigation, subNavigation } from './roots'
import { ExternalLinkIcon } from '@heroicons/react/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DocumentationButton = () => {
  return (
    <a
      href="https://axolo.co/docs"
      target="_blank"
      rel="noreferrer"
      className={classNames(
        'rounded-md py-2 px-3 text-sm font-medium text-white hover:text-gray-200',
      )}
    >
      <div className="flex">
        Documentation <ExternalLinkIcon className="ml-2 mt-1 w-4" />
      </div>
    </a>
  )
}

export default function Header() {
  const dispatch = useDispatch()
  let isOnboarded = useSelector(PlayerState.selectors.isOnboarded)
  const organization = useSelector(Organization.selectors.getOrganization)
  const user = useSelector(User.selectors.selectUser)
  const avatar =
    user.avatarUrl?.length > 0
      ? user.avatarUrl
      : 'https://axolo.s3.eu-west-3.amazonaws.com/analytics/media-app/default-avatar.png'
  const orgName =
    organization?.providerLogin || user?.credential?.teamName || 'Onboarding'
  const history = useHistory()
  const userNavigation = userMenu(orgName)

  // this set the right navigation when you reload or enter a url
  const location = useLocation()
  let headerLocation = location?.pathname?.split('/')?.[1]
  let subHeaderLocation = location?.pathname?.split('/')?.[2]
  // this prevent a bug from the redirect after authentication from Home
  if (headerLocation === 'home') {
    headerLocation = 'settings'
    subHeaderLocation = 'pull-request-channel'
  }
  const [activePage, setActivePage] = useState(headerLocation)
  const [activeSubPage, setActiveSubPage] = useState(subHeaderLocation)

  function handleClick({ item, dispatch, disabled }) {
    if (item.name === 'Log out') {
      if (process.env.NODE_ENV === 'production') {
        window.analytics.track('Sign out user')
      }
      dispatch(User.actions.logout({}))
    }
    if (!disabled && item.route) {
      setActivePage('admin')
      setActiveSubPage(item.route)
      history.push(`/admin/${item.route}`)
    }
  }

  return (
    <>
      <div className="min-h-full">
        <div className="bg-primary pb-32">
          <Disclosure
            as="nav"
            className="border-b border-hoverPrimary border-opacity-25 bg-primary lg:border-none"
          >
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                  <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-hoverPrimary lg:border-opacity-25">
                    <div className="flex items-center px-2 lg:px-0">
                      <div className="flex-shrink-0">
                        <img
                          className="block h-14 w-14"
                          src="/axolo_face_square.png"
                          alt="Axolo logo"
                        />
                      </div>
                      <div className="hidden lg:ml-10 lg:block">
                        <div className="flex space-x-4">
                          {isOnboarded
                            ? navigation.map((item) => {
                                if (
                                  organization.provider === 'gitlab' &&
                                  item.name === 'Analytics'
                                ) {
                                  return null
                                }
                                return (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                      activePage === item.route
                                        ? 'bg-hoverPrimary text-white'
                                        : 'text-white hover:bg-hoverPrimary ',
                                      'rounded-md py-2 px-3 text-sm font-medium hover:text-gray-200',
                                    )}
                                    onClick={() => {
                                      setActivePage(item.route)
                                      setActiveSubPage(subNavigation[item.route][0].route)
                                      history.push(
                                        `/${item.route}/${
                                          subNavigation[item.route][0].route
                                        }`,
                                      )
                                    }}
                                    aria-current={
                                      activePage === item.route ? 'page' : undefined
                                    }
                                  >
                                    {item.name}
                                  </a>
                                )
                              })
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="flex lg:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-primary p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="hidden lg:ml-4 lg:block">
                      {/* Profile dropdown */}

                      <div className="flex items-center">
                        {/* Hide documentation button if not onboarded */}
                        {isOnboarded ? <DocumentationButton /> : null}
                        <Menu as="div" className="relative z-50 ml-3 flex">
                          <div>
                            <Menu.Button className="flex rounded-full bg-primary text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary">
                              <span className="sr-only">Open user menu</span>
                              <img
                                className="h-10 w-10 rounded-full"
                                src={avatar}
                                alt="user avatar"
                              />
                            </Menu.Button>
                          </div>
                          <div className="hidden border-t border-white border-opacity-20 py-5 lg:block"></div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => {
                                if (
                                  organization.provider === 'gitlab' &&
                                  item.name === 'Analytics'
                                ) {
                                  return null
                                }
                                // orgName does not have any menu or setting in the user navigation
                                const disabled = item.name === orgName
                                if (!isOnboarded && item.name !== 'Log out') return
                                return (
                                  <Menu.Item
                                    key={item.name}
                                    disabled={disabled}
                                    onClick={() =>
                                      handleClick({ item, dispatch, disabled })
                                    }
                                  >
                                    {({ active }) => (
                                      <button
                                        key={item.name}
                                        className={classNames(
                                          'block w-[inherit] px-4 py-2 text-left  text-sm text-gray-700',
                                          // active means hover
                                          active ? 'bg-gray-100' : '',
                                          activeSubPage === item.route ? 'font-bold' : '',
                                          disabled
                                            ? 'pointer-events-none border-b  '
                                            : '',
                                        )}
                                      >
                                        {item.name}
                                      </button>
                                    )}
                                  </Menu.Item>
                                )
                              })}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="lg:hidden">
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {isOnboarded
                      ? navigation.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            onClick={() => {
                              setActivePage(item.route)
                              setActiveSubPage(subNavigation[item.route][0].route)
                              history.push(
                                `/${item.route}/${subNavigation[item.route][0].route}`,
                              )
                            }}
                            className={classNames(
                              activePage === item.route
                                ? ' bg-hoverPrimary text-white'
                                : 'text-white',
                              'block rounded-md py-2 px-3 text-base font-medium',
                            )}
                            aria-current={activePage === item.route ? 'page' : undefined}
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))
                      : null}
                  </div>
                  <div className="border-t border-indigo-700 pt-4 pb-3">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={avatar} alt="" />
                      </div>
                      <div className="ml-3">
                        <div className="text-sm font-medium text-white">{orgName}</div>
                        <div className="text-sm font-medium text-white">{user.email}</div>
                      </div>
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {/* Opened mobile menu navigation */}
                      {userNavigation.map((item) => {
                        const disabled = item.name === orgName
                        // no need to show the org name in the list, we have it before
                        if (disabled) return null
                        if (!isOnboarded && item.name !== 'Log out') return

                        return (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className={classNames(
                              'block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75',
                            )}
                          >
                            {item.name}
                          </Disclosure.Button>
                        )
                      })}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <header className="py-4">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="grid grid-cols-3 items-center gap-8">
                <div className="col-span-2">
                  <nav className="flex space-x-4">
                    {isOnboarded
                      ? subNavigation[activePage]?.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              activeSubPage === item.route
                                ? 'text-white'
                                : 'text-gray-300 ',
                              'rounded-md px-3 py-2  text-sm font-medium',
                            )}
                            aria-current={
                              activeSubPage === item.route ? 'page' : undefined
                            }
                            onClick={() => {
                              const newSubRoute = subNavigation[activePage].find(
                                (element) => element.route === item.route,
                              ).route
                              setActiveSubPage(newSubRoute)
                              history.push(`/${activePage}/${newSubRoute}`)
                            }}
                          >
                            {item.name}
                          </a>
                        ))
                      : null}
                  </nav>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    </>
  )
}
