import React, { useState } from 'react'
import { CheckIcon } from '@heroicons/react/solid'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { User } from '../../reducers/userReducer'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import { PlayerState } from '../../reducers/playersReducer'
import { ButtonBilling, EndButton } from '../../components/Pricing/components'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const tiers = [
  {
    name: 'Free',
    priceMonthly: 0,
    priceYearly: 0,
    description: 'Perfect to get you started and improve your code review workflow.',
    includedFeatures: [
      'Up to 5 engineers',
      'A channel for each pull request',
      'Daily pull request reminders',
      'Deployment & PR checks notifications',
      'Weekly analytics for last week pull requests',
    ],
  },
  {
    name: 'Standard',
    priceMonthly: 8,
    priceYearly: 6.7,
    description: 'Perfect to take your team up to speed and monitor the progress.',
    includedFeatures: [
      'Up to 50 engineers',
      'A channel for each pull request',
      'Daily pull request reminders',
      'Deployment & PR checks notifications',
      'Weekly analytics for last week pull requests',
      'Automatic channel archiver',
      'Private pull request channels',
      'Direct Slack support',
    ],
  },
  {
    name: 'Business',
    href: 'https://api.axolo.co/identify/slack',
    priceMonthly: 16,
    priceYearly: 13.3,
    description: 'Perfect for multi-pillar organizations looking for custom workflows.',
    includedFeatures: [
      'Up to 250 engineers',
      'A channel for each pull request',
      'Daily pull request reminders',
      'Deployment & PR checks notifications',
      'Weekly analytics for last week pull requests',
      'Automatic channel archiver',
      'Private pull request channels',
      'Direct Slack support',
      'GitHub team-specific channels for notifications',
      'Access to the analytics dashboard',
      'Custom onboarding',
      'Workflow & custom automations',
    ],
    buttonText: 'Book a demo',
  },
  {
    name: 'Enterprise',
    href: 'https://calendly.com/arthur-coudouy/meeting-arthur-axolo',
    priceMonthly: 'Contact us',
    priceYearly: 'Contact us',
    description: 'Enterprise-level services, dedicated support and custom integrations.',
    includedFeatures: [
      'Unlimited engineers',
      'A channel for each pull request',
      'Daily pull request reminders',
      'Deployment & PR checks notifications',
      'Weekly analytics for last week pull requests',
      'Automatic channel archiver',
      'Private pull request channels',
      'Direct Slack support',
      'GitHub team-specific channels for notifications',
      'Access to the analytics dashboard',
      'Custom onboarding',
      'Workflow & custom automations',
      'Dedicated technical support',
      'Custom integrations',
    ],
    buttonText: 'Book a demo',
  },
]

export default function Billing() {
  let { status } = useParams()
  const user = useSelector(User.selectors.selectUser)
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  const [monthlySelected, setMonthlySelected] = useState(true)
  const organization = useSelector(Organization.selectors.getOrganization)
  let players = useSelector(PlayerState.selectors.getPlayers)

  useEffectOnce(() => {
    if (user.organizations.length > 0) {
      ;(async () => {
        const org = await axios.get(`/organizations/${user.organizations[0].id}`)
        dispatch(Organization.actions.setData(org.data))
      })()
    }
    if (status === 'success') {
      message.destroy()
      message.success('🎉 Thank you for your order')
    } else if (status === 'error') {
      message.error('There has been an error with your payment')
    } else if (status === 'canceled') {
      message.warning("You've canceled your payment")
    }
  })

  useEffectOnce(() => {
    if (user.organizations.length > 0) {
      ;(async () => {
        const org = await axios.get(`/organizations/${user.organizations[0].id}`)
        dispatch(Organization.actions.setData(org.data))
      })()
    }
  })

  const clickOnMontly = () => {
    setMonthlySelected(true)
  }

  const clickOnYearly = () => {
    setMonthlySelected(false)
  }

  const notSelected = ' border border-transparent text-gray-700 focus:ring-secondaryBlack'
  const selected =
    'bg-white border-gray-200  shadow-sm  text-gray-900  focus:ring-secondaryBlack'
  return (
    <div className="">
      <div className="max-w-7xl mx-auto">
        <div className="sm:flex sm:flex-col sm:items-center">
          <h2 className="mt-5 text-xl sm:text-center text-gray-500 ">
            Whether your engineering team is large or small, we’re here to help you grow.
          </h2>
          <div className="flex justify-center">
            <div className="inline-flex self-center mt-6 bg-gray-100 rounded-lg p-0.5 sm:mt-8 text-base font-sans cursor-help cursor ">
              <button
                onClick={() => clickOnMontly()}
                className={classNames(
                  'font-sans relative rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:z-10 w-auto ',
                  monthlySelected && selected,
                  !monthlySelected && notSelected,
                  'px-2',
                )}
              >
                Monthly billing
              </button>
              <button
                onClick={() => clickOnYearly()}
                className={classNames(
                  'font-sans relative rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:z-10 w-auto',
                  !monthlySelected && selected,
                  monthlySelected && notSelected,
                  'px-2',
                )}
              >
                Yearly billing
              </button>
            </div>
          </div>
        </div>
        <>
          <div className=" mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2  sm:gap-6 lg:max-w-4xl lg:mx-auto xl:grid  xl:max-w-none xl:grid-cols-4 ">
            {tiers.map((tier) => (
              <div
                key={tier.name}
                className="border border-gray-200 rounded-lg shadow-md divide-y divide-gray-200 col-span-1"
              >
                <div className="p-6">
                  <h2 className="text-lg leading-6 font-sans  text-gray-900 font-bold">
                    {tier.name}
                  </h2>
                  <p className="mt-4 text-base text-gray-500">{tier.description}</p>
                  <p className="mt-8">
                    <span className="text-4xl font-bold text-primary">
                      {tier.priceMonthly !== 'Contact us' ? '$' : ''}
                      {monthlySelected ? tier.priceMonthly : tier.priceYearly}
                    </span>{' '}
                    <span className="text-base font-medium text-gray-500">
                      {tier.priceMonthly !== 'Contact us'
                        ? monthlySelected
                          ? '/seat/mo'
                          : '/seat/mo'
                        : ''}
                    </span>
                  </p>
                  <ButtonBilling
                    tier={tier}
                    organization={organization}
                    players={players}
                    monthlySelected={monthlySelected}
                  />
                </div>
                <div className="pt-6 pb-8 px-6">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                    What's included
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {tier.includedFeatures.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon
                          className="flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm  font-sans text-gray-500">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </>
        <div className="sm:flex sm:flex-col sm:items-center">
          <EndButton
            isPaying={
              organization.plan === 'professional' || organization.plan === 'business'
            }
            axios={axios}
          />
        </div>
      </div>
    </div>
  )
}
