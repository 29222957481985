import { Avatar, Tooltip } from 'antd'

export default function GitHubUsers({ githubUsers }) {
  return (
    <Avatar.Group maxCount={3}>
      {githubUsers?.map((engineer) => (
        <Tooltip title={engineer?.providerLogin}>
          <Avatar src={engineer?.avatarUrl}></Avatar>
        </Tooltip>
      ))}
    </Avatar.Group>
  )
}
