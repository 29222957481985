import React from 'react'
import { URLBACK } from '../../assets/urls'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useEffectOnce, useLocation } from 'react-use'
import { useDispatch } from 'react-redux'
import { updateUser, User } from '../../reducers/userReducer'
import LoadingPage from '../../pages/Loading'

export default function GithubAuth() {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const loggingUser = async () => {
    try {
      const { data, status } = await Axios.get(
        `${URLBACK}auth/github/callback${location.search}`,
      )
      const { user, jwt } = data

      const { data: isAdminUser } = await Axios.get(
        `${URLBACK}organizations/isAdminUser/${user.username}`,
      )

      if (status !== 200) {
        console.log('Something wrong just happen. Error:', status, data)
        return
      }
      dispatch(updateUser({ ...user, jwt, isAdminUser }))
      window.$crisp.push(['set', 'user:email', user.email])

      if (isAdminUser) {
        history.push('/home?github=true')
      } else if (user?.role?.type === 'engineer') {
        history.push(`/engineer/settings/standups`)
      } else {
        history.push(`/redirection-admin`)
      }
    } catch (e) {
      console.error({ e })
      dispatch(User.actions.setError(e.message))
    }
  }

  useEffectOnce(() => {
    loggingUser().then()
  })

  return <LoadingPage />
}
