import { ManageTeamMembers } from '../ManageTeamMembers'

export default function OnboardNewMembers({ organization, isSelectMembersReady }) {
  return (
    <div className="">
      <ManageTeamMembers
        organization={organization}
        isSelectMembersReady={isSelectMembersReady}
        teamView={true}
      />
    </div>
  )
}
