import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import RoleSelect from './roleSelect'
import AdminSlackSelect from './adminSlackSelect'
import { useAxiosWithHeader } from '../../utils'
import { URLBACK } from '../../assets/urls'
import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { message } from 'antd'

export default function InviteAdminModal({ open, setOpen }) {
  const cancelButtonRef = useRef(null)
  const axios = useAxiosWithHeader()
  const organization = useSelector(Organization.selectors.getOrganization)
  const { slackMembers } = organization
  const [newUser, setNewUser] = useState(slackMembers[0])
  const [type, setType] = useState({ id: 1, name: 'Admin' })
  const [disabled, setDisabled] = useState(false)

  async function handleInviteAdmin() {
    setDisabled(true)
    try {
      const res = await axios.get(
        `${URLBACK}organizations/inviteAdminOnAxolo?userSlackId=${newUser.id}&type=${type.name}`,
      )
      if (res.status !== 200) {
        message.error('Error inviting an admin. Please try again or contact support')
        console.log('Error inviting an admin: ')
      } else {
        message.success(`${newUser.real_name} was added as an admin.`)
        setOpen(false)
      }
    } catch (e) {
      message.error('Error inviting an admin. Please try again or contact support')
      console.log('Error inviting an admin: ', e)
    }
    setDisabled(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-left divide-y-2">
                    <div className="">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Invite a user
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Give Axolo administrator access to someone in your team
                        </p>
                      </div>
                    </div>
                    <div className="py-6 border-b-2">
                      <p className="text-sm text-gray-500">
                        Select the role and Slack profile of the new user
                      </p>
                      <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <AdminSlackSelect newUser={newUser} setNewUser={setNewUser} />
                        <RoleSelect type={type} setType={setType} />
                      </div>
                    </div>
                    <div />
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    disabled={disabled}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-hoverPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:opacity-25"
                    onClick={() => handleInviteAdmin()}
                  >
                    Invite
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50  sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
