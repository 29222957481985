import SkeletonLoading from '../Analytics/utils/skeletonLoader'

export const LoadingLabels = () => {
  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      <tr key="loading1">
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <SkeletonLoading count={1} specificCss={'w-full px-8 h-4'} />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <SkeletonLoading count={1} specificCss={'w-full px-8 h-4'} />
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 ">
          <SkeletonLoading count={1} specificCss={'w-full px-8 h-4'} />
        </td>
      </tr>
      <tr key="loading2">
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <SkeletonLoading count={1} specificCss={'w-full px-8 h-4'} />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <SkeletonLoading count={1} specificCss={'w-full px-8 h-4'} />
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 ">
          <SkeletonLoading count={1} specificCss={'w-full px-8 h-4'} />
        </td>
      </tr>
    </tbody>
  )
}
