import { Button, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

export const SettingsNotOnboarded = () => {
  const history = useHistory()
  return (
    <div className="flex justify-between mb-8">
      <Typography className="">
        Complete your onboarding before updating your company's settings
      </Typography>
      <div onClick={() => history.push('/home')}>
        <Button className="bg-primary text-textWhite rounded-sm">
          Complete my onboarding
        </Button>
      </div>
    </div>
  )
}
