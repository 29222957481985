import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Engineer } from '../../reducers/engineerReducer'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import { LoadingTeamChannelData, MasterTableContainer } from './teamChannelTableHelper'
import { cloneDeep, sortBy, partition } from 'lodash'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { Popover, Select } from 'antd'
import TeamChannelInputTable from '../TeamChannels/inputTable'
import { TeamChannelTableData } from './channelData'
import { InfoCircleTwoTone } from '@ant-design/icons'
import PlayersNotInTeamChannels from '../TeamChannels/PlayersNotInTeamChannels'
import { WarningTwoTone } from '@ant-design/icons'

export const allText = 'All Axolo onboarded users'

const { Option } = Select

export default function TeamChannelTable({ loadingTeamChannels, isAdmin = false }) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { teams = [], setting } = organization
  const { channels = [] } = setting
  const engineer = useSelector(Engineer.selectors.getEngineer)
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [newChannelState, setNewChannelState] = useState(undefined)

  let myChannels, teamChannelCreatedByOthers
  if (isAdmin) {
    myChannels = channels
    teamChannelCreatedByOthers = []
  } else {
    ;[myChannels, teamChannelCreatedByOthers] = partition(channels, (el) => {
      return el.creator?.providerLogin === engineer.providerLogin
    })
  }

  const [teamChannelCreatedByMe, setTeamChannelCreatedByMe] = useState(myChannels)

  useEffect(() => {
    if (isAdmin) {
      setTeamChannelCreatedByMe(channels)
    } else {
      setTeamChannelCreatedByMe(
        channels.filter((channel) => channel?.creator?.id === engineer?.id),
      )
    }
  }, [setting.channels])

  const onDelete = async ({ channelTitle, desactivate = false }) => {
    try {
      const hide = messageInteraction({
        content: '',
        type: 'loading',
        duration: 0,
      })
      let newSettings = cloneDeep(setting)
      newSettings.channels = newSettings.channels.filter(
        (channel) => channel.title !== channelTitle,
      )
      const updatedSettingsResp = await axios.put(`/settings/${setting._id}`, {
        channels: newSettings.channels,
        updatingTeamChannel: true,
        desactivate,
      })
      hide()
      if (updatedSettingsResp.status === 200) {
        if (
          typeof updatedSettingsResp.data === 'string' &&
          updatedSettingsResp.data.includes('Error')
        ) {
          messageInteraction({
            content: updatedSettingsResp.data,
            type: 'error',
          })
        } else {
          messageInteraction({
            content: 'Your channel has been archived.',
            type: 'warning',
          })
          dispatch(Organization.actions.setData({ setting: updatedSettingsResp.data }))
        }
      }
    } catch (error) {
      console.log('Could not archive channel, error:', error)
      messageInteraction({
        content: "Couldn't archive your channel, try again or contact support",
        type: 'error',
      })
    }
  }

  const handleSelectTeamsOnExistingChannel = async (teams, channel) => {
    const newChannel = { ...channel }
    newChannel.teams = teams.map((team) => team.key)
    setNewChannelState(newChannel)
    const newChannels = cloneDeep(teamChannelCreatedByMe)
    const index = newChannels.findIndex((chan) => chan.id === newChannel.id)
    newChannels[index].teams = teams.map((team) => team.key)
    setTeamChannelCreatedByMe(newChannels)
  }

  const validateTeams = async () => {
    const hide = messageInteraction({
      content: '',
      type: 'loading',
      duration: 0,
    })

    if (!newChannelState) {
      return
    }
    const newChannel = newChannelState
    try {
      const updatedSettingsResp = await axios.put(`/settings/${setting._id}`, {
        channel: newChannel,
        updatingTeamsInSpecificChannel: true,
      })
      if (updatedSettingsResp.status === 200) {
        if (
          typeof updatedSettingsResp.data === 'string' &&
          updatedSettingsResp.data.includes('Error')
        ) {
          messageInteraction({
            content: updatedSettingsResp.data,
            type: 'error',
          })
        } else {
          messageInteraction({
            content: 'Your teams have been updated.',
            type: 'success',
          })
          setNewChannelState(undefined)
          dispatch(Organization.actions.setData({ setting: updatedSettingsResp.data }))
        }
      }
      if (updatedSettingsResp.status === 204) {
        messageInteraction({
          content: 'Need at least one team to update your channel.',
          type: 'warning',
        })
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
      if (error?.response?.status === 402) {
        messageInteraction({
          content: 'This feature is for Business plan only.',
          type: 'error',
        })
      } else {
        console.log("Couldn't modify teams", error)
        messageInteraction({
          content: "Couldn't modify teams, try again or contact support.",
          type: 'error',
        })
      }
    }
    hide()
  }

  const children = []
  const GeneralChannel = setting?.channels?.find((channel) =>
    channel.teams.includes(allText),
  )
  if (!GeneralChannel) {
    children.push(
      <Option key={allText} value={allText} id={allText}>
        {allText}
      </Option>,
    )
  }

  const teamsOrdered = sortBy(teams, function (t) {
    return t.name.toLowerCase()
  })
  for (let i = 0; i < teamsOrdered.length; i++) {
    const team = teamsOrdered[i]
    children.push(
      <Option key={team.id} value={team.name} id={team.id}>
        {team.name}
      </Option>,
    )
  }

  const TeamChannelHeader = ({ isMyChannels }) => {
    return (
      <>
        <h1 className="mt-2 text-xl font-semibold text-gray-900">
          {isMyChannels ? 'Your team channels' : "Other's team channels"}
        </h1>
        {isMyChannels && (
          <div className="">
            <div className="flex overflow-visible">
              <p>
                Team channels notify their team members whenever someone creates a new
                pull request.
                <span className="pl-3"></span>
              </p>
              <Popover
                content={
                  <div className="max-w-[650px]">
                    <p>
                      Axolo creates a notification in a team channel for every new pull
                      requests an then reacts automatically upon any pull request change.{' '}
                      <br className="pt-1" />
                      Is your pull request still WIP 🔧, awaiting a review 🙏 or merged
                      ✅?
                    </p>
                    <div className="flex justify-center">
                      <img
                        src="https://axolo.s3.eu-west-3.amazonaws.com/media/app/general_notification.png"
                        alt="Axolo general notification for pull request status"
                        className="max-w-[600px]"
                      />
                    </div>
                  </div>
                }
              >
                <InfoCircleTwoTone twoToneColor="#0049ff" />
              </Popover>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <div className=" flex flex-col overflow-visible">
      <div className="-my-2 -mx-4 overflow-visible  sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full overflow-visible pb-2 align-middle md:px-6 lg:px-8">
          {/* loading table */}
          {loadingTeamChannels && (
            <>
              <TeamChannelHeader isMyChannels={true} />
              <MasterTableContainer>
                <LoadingTeamChannelData />
              </MasterTableContainer>
            </>
          )}

          {/* Personal team channels or every team channel if admin */}
          {!loadingTeamChannels && (
            <>
              <TeamChannelHeader isMyChannels={true} />
              <MasterTableContainer>
                <TeamChannelInputTable
                  setLoading={setLoading}
                  loading={loading}
                  children={children}
                  old={false}
                />
                <TeamChannelTableData
                  handleSelectTeamsOnExistingChannel={handleSelectTeamsOnExistingChannel}
                  validateTeams={validateTeams}
                  newChannelState={newChannelState}
                  onDelete={onDelete}
                  channels={teamChannelCreatedByMe}
                  personal={true}
                />
              </MasterTableContainer>
              {isAdmin && <PlayersNotInTeamChannels />}
            </>
          )}

          <br className="pt-8" />
          {/* Other team channels */}
          {teamChannelCreatedByOthers?.length > 0 && (
            <>
              <TeamChannelHeader isMyChannels={false} />
              <MasterTableContainer>
                <TeamChannelTableData
                  channels={teamChannelCreatedByOthers}
                  personal={false}
                />
              </MasterTableContainer>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
