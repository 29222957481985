import { useAxiosWithHeader } from '../../utils'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { useState } from 'react'
import { URLBACK } from '../../assets/urls'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { listAllChannels } from '../../api/getOrgandSlackMembers'
import { useEffectOnce } from 'react-use'
import { Engineer } from '../../reducers/engineerReducer'
import TeamChannelTable from './teamChannelTable'

export default function EngineerTeamChannelContainer() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const engineer = useSelector(Engineer.selectors.getEngineer)
  const axios = useAxiosWithHeader()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  async function fetchTeams() {
    try {
      // should only be working for the engineer login, not admin login
      if (!(organization.teams?.length > 0)) {
        setLoading(true)
        const res = await axios.get(`${URLBACK}teams/fetchOrganizationTeams`)
        dispatch(Organization.actions.setData({ teams: res.data }))
      }
    } catch (e) {
      messageInteraction({
        content: 'Error fetching your GitHub teams.',
        type: 'error',
      })
      console.log('error in fetchTeams:', e)
    }
  }

  async function fetchSlackChannels() {
    try {
      // should only be working for the engineer login, not admin login
      if (!(organization.slackChannels?.length > 0)) {
        setLoading(true)
        await listAllChannels({ axios, dispatch })
      }
    } catch (e) {
      messageInteraction({
        content: 'Error fetching your Slack channels.',
        type: 'error',
      })
      console.log('error in fetchSlackChannels:', e)
    }
  }

  async function fetchOrganizationTeamChannels() {
    if (!(organization?.setting?.channels?.length > 0)) {
      try {
        setLoading(true)
        // This route is to update team channels from Slack
        const channelsFromSlackUpdated = await axios.get(
          `/settings/retrieveTeamChannels/${engineer.organization.setting}`,
        )
        if (channelsFromSlackUpdated.status === 200) {
          dispatch(
            Organization.actions.setData({ setting: channelsFromSlackUpdated.data }),
          )
        }
      } catch (e) {
        console.log('Error in retrieve team channels', e)
        messageInteraction({
          content:
            'Error fetching your team channels. Please try again or contact support',
          type: 'error',
        })
      }
    }
  }

  async function handleUseEffectOnce() {
    await Promise.allSettled([
      fetchOrganizationTeamChannels(),
      fetchTeams(),
      fetchSlackChannels(),
    ])
    setLoading(false)
  }

  useEffectOnce(() => {
    handleUseEffectOnce()
  })

  return (
    <div className="overflow-visible ">
      <TeamChannelTable
        personalTeamChannels={true}
        axios={axios}
        loadingTeamChannels={loading}
      />
    </div>
  )
}
