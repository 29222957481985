import { StandupTable } from './table-helper'
import { useAxiosWithHeader } from '../../utils'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { useEffect, useState } from 'react'
import { URLBACK } from '../../assets/urls'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { listAllChannels } from '../../api/getOrgandSlackMembers'
import { Standups } from '../../reducers/standupReducer'

export default function StandupSetting({ engineer, globalLoader }) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const axios = useAxiosWithHeader()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const myStandups = useSelector(Standups.selectors.getMyStandups)
  const otherStandups = useSelector(Standups.selectors.getOtherStandups)

  async function fetchOrganizationStandups() {
    if (!(myStandups?.length > 0) && !(otherStandups?.length > 0)) {
      try {
        setLoading(true)
        const res = await axios.get(
          `${URLBACK}settings/fetchOrganizationStandups?engId=${engineer.id}`,
        )
        if (res.data.length > 0) {
          const myStandups = res.data.filter(
            (standup) => standup.creator?.id === engineer.id,
          )
          const otherStandups = res.data.filter(
            (standup) => standup.creator?.id !== engineer.id,
          )
          dispatch(Standups.actions.setData({ myStandups, otherStandups }))
        }
      } catch (e) {
        messageInteraction({
          content: 'Error fetching your standups.',
          type: 'error',
        })
        console.log('error in fetchOrganizationStandups:', e)
      }
    }
  }
  async function fetchTeams() {
    try {
      // should only be working for the engineer login, not admin login
      if (!(organization.teams?.length > 0)) {
        setLoading(true)
        const res = await axios.get(`${URLBACK}teams/fetchOrganizationTeams`)
        dispatch(Organization.actions.setData({ teams: res.data }))
      }
    } catch (e) {
      messageInteraction({
        content: 'Error fetching your GitHub teams.',
        type: 'error',
      })
      console.log('error in fetchTeams:', e)
    }
  }

  async function fetchSlackChannels() {
    try {
      // should only be working for the engineer login, not admin login
      if (!(organization.slackChannels?.length > 0)) {
        setLoading(true)
        await listAllChannels({ axios, dispatch })
      }
    } catch (e) {
      messageInteraction({
        content: 'Error fetching your Slack channels.',
        type: 'error',
      })
      console.log('error in fetchSlackChannels:', e)
    }
  }

  async function handleUseEffectOnce() {
    await Promise.allSettled([
      fetchOrganizationStandups(),
      fetchTeams(),
      fetchSlackChannels(),
    ])
    setLoading(false)
  }

  useEffect(() => {
    if (engineer?.id) {
      handleUseEffectOnce()
    }
  }, [engineer?.id])

  // if (globalLoader) return 'bloop'
  return (
    <div className="">
      {/* personal standups */}
      <StandupTable
        personalStandup={true}
        engineer={engineer}
        axios={axios}
        loading={loading}
        standups={myStandups}
      />

      {/* other's standups */}
      <StandupTable personalStandup={false} standups={otherStandups} loading={loading} />
    </div>
  )
}
