import { useAxiosWithHeader } from '../../utils'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { useEffect } from 'react'
import initialQuery from '../../components/Analytics/utils/function/initialQuery'
import { Analytics } from '../../reducers/analyticsReducer'
import CompanyInfo from '../../components/Analytics/CompanyInfo/companyInfo'
import FeedbackButton from '../../components/Analytics/utils/feedbackButton'
import { loggingUser } from './login'
import { Route, useHistory } from 'react-router'
import ProcessingInstallation from '../../components/Analytics/ProcessingInstallation/ProcessingInstallation'
import MissingGithubAccessTokenModal from '../../components/Analytics/MissingGithubAccessTokenModal/MissingGithubAccessTokenModal'
import { User } from '../../reducers/userReducer'
import { Setting } from '../../reducers/settingReducer'
import InitialLoadingModal from '../../components/Analytics/InitialLoadingModal/InitialLoadingModal'
import EndOfAnalyticsTrialModal from '../../components/Analytics/EndOfAnalyticsTrialModal/EndOfAnalyticsTrialModal'
import { PlayerState } from '../../reducers/playersReducer'
import Dashboard from '../../components/Analytics/AnalyticsDashboard'
import TableContainer from '../../components/Analytics/TableContainer'
import DeploymentContainer from '../../components/Analytics/DeploymentGraph/DeploymentContainer'
import TablePR from '../../components/Analytics/TableContainer/TablePR'
import MergedPRGraphContainer from '../../components/Analytics/MergedPRGraph/MergedPRContainer'
import PullRequestLengthContainer from '../../components/Analytics/PullRequestLengthGraph/PullRequestLengthContainer'
import TableDeployments from '../../components/Analytics/TableContainer/TableDeployment'
import ContinuousCycleTimeContainer from '../../components/Analytics/ContinuousCycleTime/continuousCycleTimeContainer'
import TableCycleTimeEvolution from '../../components/Analytics/TableContainer/TableCycleTimeEvolution'
import CycleTimeStackedContainer from '../../components/Analytics/CycleTimeStacked/CycleTimeStackedContainer'
import ReviewTimeStackedContainer from '../../components/Analytics/ReviewTimeStacked/ReviewTimeStackedContainer'
import { Organization } from '../../reducers/organizationReducer'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function createPullRequestLength({ pullRequestLength }) {
  return pullRequestLength.map((stat) => stat.pullRequests).flat()
}

export default function AnalyticsPage() {
  const axios = useAxiosWithHeader()
  const axiosAnalytics = useAxiosWithHeader('analytics')
  const dispatch = useDispatch()
  const setting = useSelector(Setting.selectors.getSetting)
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  const {
    temporality,
    mergedPullRequests,
    deployments,
    pullRequestLength,
    continuousCycleTime,
    cycleTime,
    reviewTime,
  } = analytics
  const history = useHistory()
  const user = useSelector(User.selectors.selectUser)
  const players = useSelector(PlayerState.selectors.getPlayers)
  const sortedMergedPullRequests = [...mergedPullRequests]
  sortedMergedPullRequests.sort(function (a, b) {
    return new Date(b?.mergedAt) - new Date(a?.mergedAt)
  })
  const organization = useSelector(Organization.selectors.getOrganization)

  const activePlayers = players?.filter(
    (player) => player.communicationToolId?.length > 0,
  )?.length

  const HandleErrors = () => {
    if (user.initialLoading) return <InitialLoadingModal />
    if (!user.hasGithubAccessToken) return <MissingGithubAccessTokenModal />
    if (!analytics.onboardingCompleted) {
      return <ProcessingInstallation />
    }
    if (
      (!analytics.endOfTrial ||
        moment(analytics.endOfTrial, 'YYYY-MM-DD').isBefore(moment())) &&
      organization.plan !== 'business'
    ) {
      return <EndOfAnalyticsTrialModal axios={axios} activePlayers={activePlayers} />
    }
    return null
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await loggingUser({ dispatch, axios, history, user })
    }
    if (user.initialLoading) {
      // call the function
      fetchData()
        // make sure to catch any error
        .catch(console.error)
    }
  }, [user])

  useEffect(() => {
    if (!user.initialLoading && setting.loadingInitialQuery) {
      console.log('here')
      initialQuery({
        dispatch,
        axios: axiosAnalytics,
        organization: analytics,
      })
    }
  }, [user.initialLoading, analytics.id])

  const isError =
    !analytics.onboardingCompleted ||
    !user.hasGithubAccessToken ||
    ((!analytics.endOfTrial ||
      moment(analytics.endOfTrial, 'YYYY-MM-DD').isBefore(moment())) &&
      organization.plan !== 'business')
  return (
    <div className={classNames(isError && 'fixed h-[550px]')}>
      <HandleErrors />

      <CompanyInfo />

      {/* ROUTING */}

      <Route exact path="/analytics/dashboard">
        <Dashboard temporality={temporality}></Dashboard>
      </Route>

      <Route exact path="/analytics/merged-pull-requests">
        <TableContainer
          title={'Merged Pull Requests Graph'}
          description={`Number of merged pull requests per ${temporality}`}
        >
          <MergedPRGraphContainer
            fields={'*'}
            pullRequestRelations={'creator,repository,reviewers'}
            documentationLink="https://axolo.co/docs/analytics/graph-definition#merged-pull-requests"
          />
          <TablePR
            title={'Merged Pull Requests Table'}
            pullRequests={sortedMergedPullRequests}
          />
        </TableContainer>
      </Route>

      <Route exact path="/analytics/pull-requests-length">
        <TableContainer
          title={'Pull Requests Length Graph'}
          description={`Evolution of the size of your PR per ${temporality}`}
        >
          <PullRequestLengthContainer
            fields={'*'}
            pullRequestRelations={'creator,repository,reviewers'}
            documentationLink="https://axolo.co/docs/analytics/graph-definition#pull-request-length"
          />
          <TablePR
            title={'Pull Requests Length Table'}
            pullRequests={createPullRequestLength({ pullRequestLength })}
          />
        </TableContainer>
      </Route>

      <Route exact path="/analytics/deployments">
        <TableContainer
          title={'Deployments Graph'}
          description={`Number of deployments per ${temporality}`}
        >
          <DeploymentContainer
            fields={'*'}
            pullRequestRelations={'creator,repository,reviewers'}
            documentationLink="https://axolo.co/docs/analytics/graph-definition#merged-pull-requests"
          />
          <TableDeployments title={'Deployments Table'} deployments={deployments} />
        </TableContainer>
      </Route>

      <Route exact path="/analytics/cycle-time-evolution">
        <TableContainer
          title={'Cycle time evolution'}
          description={`Evolution of your cycle time per ${temporality}`}
        >
          <ContinuousCycleTimeContainer
            pullRequestRelations={
              'creator,repository,reviewers,events,review_requests,pull_request_reviews'
            }
            documentationLink="https://axolo.co/docs/analytics/graph-definition#cycle-time-evolution"
            tableGraphLink="/analytics/cycle-time-evolution"
          />
          <TableCycleTimeEvolution
            title={'Cycle time evolution table'}
            description={`Evolution of your cycle time per ${temporality}`}
            continuousCycleTime={continuousCycleTime}
          ></TableCycleTimeEvolution>
        </TableContainer>
      </Route>

      <Route exact path="/analytics/cycle-time">
        <TableContainer title="Cycle time" description="Repartition of your cycle time">
          <CycleTimeStackedContainer
            pullRequestRelations={
              'creator,repository,reviewers,events,review_requests,pull_request_reviews'
            }
            documentationLink="https://axolo.co/docs/analytics/glossary#development-time"
            tableGraphLink="/analytics/cycle-time"
            marginTime
          />
          <TablePR
            title="Cycle time table"
            pullRequests={cycleTime?.stat?.pullRequests}
            showReviewTime
            showDevTime
            // showReviewRequests
            // showPrReviews
          />
        </TableContainer>
      </Route>

      <Route exact path="/analytics/review-time">
        <TableContainer title="Review time" description="Composition of your review time">
          <ReviewTimeStackedContainer
            pullRequestRelations={
              'creator,repository,reviewers,events,review_requests,pull_request_reviews'
            }
            documentationLink="https://axolo.co/docs/analytics/glossary#development-time"
            tableGraphLink="/analytics/cycle-time"
            marginTime
          />
          <TablePR
            title="Review time table"
            titleWidth="250"
            pullRequests={reviewTime?.stat?.pullRequests}
            showResponseTime
            showReworkTime
            showCompletionTime
          />
        </TableContainer>
      </Route>

      {/* END ROUTING */}

      <div className="-mb-4 flex justify-center pt-8">
        <FeedbackButton text="How can we improve this screen?" />
      </div>
    </div>
  )
}
