import React, { useState } from 'react'
import { FilterTwoTone } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import Avatar from 'antd/lib/avatar/avatar'
import { Table, Tag, message, Spin, Tooltip } from 'antd'
import { Organization } from '../../reducers/organizationReducer'
import { colors } from '../Colors'
import { useAxiosWithHeader } from '../../utils'
import { CheckIcon, ExclamationIcon } from '@heroicons/react/solid'
import { LoadingOutlined } from '@ant-design/icons'
import { cloneDeep } from 'lodash'
import { URLBACK } from '../../assets/urls'
import moment from 'moment'
import { PlayerState } from '../../reducers/playersReducer'
import { setPlayer } from '../../reducers/playersReducer'

const AllSetButton = () => {
  return (
    <button
      disabled={true}
      className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-normal rounded-md shadow-sm text-gray-400 bg-gray-200  cursor-not-allowed"
    >
      All set
    </button>
  )
}

export default function TeamTable() {
  let players = useSelector(PlayerState.selectors.getPlayers)
  const onboardedPlayers = players.filter((eng) => eng.communicationToolId?.length > 0)
  const axios = useAxiosWithHeader()
  const organization = useSelector(Organization.selectors.getOrganization)
  const teams = organization.teams
  const dispatch = useDispatch()

  const RequestAuthenticationButton = ({ engineer }) => {
    const [loading, setLoading] = useState(false)

    async function handleClick() {
      try {
        setLoading(true)
        await axios.get(`${URLBACK}player/notifSignInWithSlack?playerId=${engineer.id}`)
        const index = onboardedPlayers.indexOf(engineer)
        const newData = cloneDeep(onboardedPlayers)
        newData[index].requestToAuthenticateSentAt = moment()
        dispatch(setPlayer(newData[index]))
        message.success(`Autentication request sent to ${engineer.providerLogin}`)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        message.error('Unknown error. Please try again or contact support.')
      }
    }
    if (
      engineer.requestToAuthenticateSentAt &&
      moment(engineer.requestToAuthenticateSentAt).isSame(moment(), 'day')
    ) {
      return (
        <button className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-normal rounded-md shadow-sm text-gray-400 bg-gray-200  cursor-not-allowed">
          Authentication requested today
        </button>
      )
    }
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    return (
      <button
        onClick={() => handleClick()}
        className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-normal rounded-md shadow-sm text-white bg-primary hover:bg-hoverPrimary "
      >
        {loading ? <Spin indicator={antIcon} style={{ marginRight: '10px' }} /> : null}
        Request to complete authentication
      </button>
    )
  }

  const AskEveryone = () => {
    const [loading, setLoading] = useState(false)

    async function handleClick() {
      try {
        setLoading(true)
        await axios.get(`${URLBACK}player/notifSignInWithSlack?orgId=${organization.id}`)
        message.success(`Autentication request sent to everyone`)
        const newData = cloneDeep(onboardedPlayers)
        for (let i = 0; i < newData.length; i += 1) {
          const newData = cloneDeep(onboardedPlayers)
          newData[i].requestToAuthenticateSentAt = moment()
          dispatch(setPlayer(newData[i]))
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        message.error('Unknown error. Please try again or contact support.')
      }
    }

    const nonAuthedEngineers = onboardedPlayers.filter(
      (eng) => !eng.slackAuthed || !eng.githubAuthed,
    )
    let didWeAlreadyAskedEveryone = true
    for (const eng of nonAuthedEngineers) {
      if (
        !eng.requestToAuthenticateSentAt ||
        !moment(eng?.requestToAuthenticateSentAt).isSame(moment(), 'day')
      )
        didWeAlreadyAskedEveryone = false
    }
    if (onboardedPlayers?.length === 0) {
      return (
        <div className="">
          <button
            disabled={true}
            className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-normal rounded-md shadow-sm text-gray-400 bg-gray-200  cursor-not-allowed right-0"
          >
            No onboarded engineers
          </button>
        </div>
      )
    }
    if (didWeAlreadyAskedEveryone)
      return (
        <div className="">
          <button
            disabled={true}
            className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-normal rounded-md shadow-sm text-gray-400 bg-gray-200  cursor-not-allowed right-0"
          >
            Already asked everyone today to authenticate
          </button>
        </div>
      )
    return (
      <div className="">
        <button
          onClick={() => handleClick()}
          className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-normal rounded-md shadow-sm text-white bg-primary hover:bg-hoverPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hoverPrimary right-0"
        >
          Ask everyone to complete authentication
        </button>
      </div>
    )
  }

  const teamColor = {}
  teams?.forEach((team) => {
    // To define the color of a team I'm taking the char code of the first letter of their team name.
    let colorNumber = Math.abs(team.name[0].toLowerCase().charCodeAt(0) - 97)
    if (colorNumber > colors.length) {
      colorNumber = colorNumber % colors.length
    }
    teamColor[team.name] = colors[colorNumber]
  })

  const teamFilter = teams?.map((team) => ({
    text: team.name,
    value: team.name,
  }))

  let columns = [
    {
      title: '',
      dataIndex: 'avatarUrl',
      key: 'avatarUrl',
      render: (avatarUrl) => <Avatar src={avatarUrl}></Avatar>,
    },
    {
      title: 'GitHub Login',
      dataIndex: 'providerLogin',
      key: 'providerLogin',
      sorter: (a, b) => a.providerLogin.length - b.providerLogin.length,
      sortDirections: ['descend'],
    },
    {
      title: '',
      dataIndex: 'slackAvatar',
      key: 'slackAvatar',
      render: (slackAvatar) => <Avatar src={slackAvatar}></Avatar>,
    },
    {
      title: 'Slack name',
      dataIndex: 'userName',
      key: 'userName',
      sorter: (a, b) => a.userName.length - b.userName.length,
      sortDirections: ['descend'],
    },
    {
      title: 'Teams',
      dataIndex: 'teams',
      key: 'teams',
      render: (playerTeams) => (
        <>
          {playerTeams.map((team) => {
            return (
              <Tag
                style={{ marginBottom: '5px' }}
                color={teamColor[team.name]}
                key={team.slug}
              >
                {team.name.length > 33 ? team.name.slice(0, 30) + '...' : team.name}
              </Tag>
            )
          })}
        </>
      ),
      filters: teamFilter,
      filterIcon: (filtered) =>
        filtered ? (
          <FilterTwoTone twoToneColor="#0049ff" style={{ fontSize: '16px' }} />
        ) : (
          <FilterTwoTone twoToneColor="#718096" style={{ fontSize: '16px' }} />
        ),
      onFilter: (teams, record) => {
        return record.teams.map((team) => team.name === teams).includes(true)
      },
    },
    {
      title: 'GitHub Auth',
      dataIndex: 'githubAuth',
      key: 'githubAuth',
      render: (item, raw) => (
        <div className="flex justify-center">
          {raw.githubAuthed ? (
            <CheckIcon
              className="flex-shrink-0 h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          ) : (
            <ExclamationIcon
              className="flex-shrink-0 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
        </div>
      ),
    },
    {
      title: 'GitLab Auth',
      dataIndex: 'gitlabAuth',
      key: 'gitlabAuthed',
      render: (item, raw) => (
        <div className="flex justify-center">
          {raw.gitlabAuthed ? (
            <CheckIcon
              className="flex-shrink-0 h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          ) : (
            <ExclamationIcon
              className="flex-shrink-0 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
        </div>
      ),
    },
    {
      title: 'Slack Auth',
      dataIndex: 'slackAuth',
      key: 'slackAuth',
      render: (item, raw) => {
        let icon = (
          <div className="flex justify-center">
            <ExclamationIcon
              className="flex-shrink-0 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )
        if (raw?.slackUpToDate?.notUptoDateScopes?.length > 0) {
          icon = (
            <Tooltip
              title={`Missing scopes ${raw?.slackUpToDate?.notUptoDateScopes.join(',')}`}
            >
              <div className="flex justify-center">
                <ExclamationIcon
                  className="flex-shrink-0 h-5 w-5 text-yellow-500"
                  aria-hidden="true"
                />
              </div>
            </Tooltip>
          )
        }
        return raw?.slackUpToDate?.isUpToDate ? (
          <div className="flex justify-center">
            <CheckIcon
              className="flex-shrink-0 h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          </div>
        ) : (
          icon
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (item, raw) => (
        <div className="">
          {raw.githubAuthed && raw?.slackUpToDate?.isUpToDate ? (
            <AllSetButton />
          ) : (
            <RequestAuthenticationButton engineer={raw} />
          )}
        </div>
      ),
    },
  ]
  columns = columns.filter((column) =>
    organization.provider === 'github'
      ? column.title !== 'GitLab Auth'
      : column.title !== 'GitHub Auth',
  )
  return (
    <div className="">
      <h2 className="mb-8 text-lg">Engineers onboarded on Axolo</h2>
      <div className="mb-4 flex justify-end">
        {/* <FilterMenu  // handleReset={handleReset} onSearch={(e) => onSearch(e)}
          playersLength={onboardedPlayers?.length} /> */}
        <AskEveryone />
      </div>
      <Table
        // locale={locale}
        columns={columns}
        dataSource={onboardedPlayers}
        pagination={{ pageSize: 50 }}
        rowKey="id"
      />
    </div>
  )
}
