import messageInteraction from '../Analytics/utils/messageInteraction'
import { allText } from '../EngineerTeamChannelContainer/teamChannelTable'

export const findTeamNameFromId = ({ id, teams }) => {
  if (id === allText) {
    return allText
  }
  const team = teams.find((team) => team.id === id)
  return team?.name
}

export function handleErrorsInputTeamChannel({ state, setting }) {
  const missingInformation = []
  if (!(state.title?.length > 0)) {
    missingInformation.push('channel title')
  }
  if (!(state.teams?.length > 0)) {
    missingInformation.push('team')
  }

  if (missingInformation.length > 0) {
    const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' })
    const missingText = formatter.format(missingInformation)
    messageInteraction({
      content: `Missing ${missingText} information.`,
      type: 'error',
    })
    return true
  }
  const allTitles = setting.channels?.map(({ title }) => title)
  if (state.title.length > 79) {
    messageInteraction({
      content: 'Channel title cannot be longer than 80 chars.',
      type: 'error',
    })
    return true
  }
  if (allTitles?.includes(state.title)) {
    messageInteraction({
      content: 'Channel title already taken.',
      type: 'error',
    })
    return true
  }
  return false
}
