import React from 'react'
import { useEffectOnce } from 'react-use'
import { AppContent } from '../../components/utils/utils'
import { Organization } from '../../reducers/organizationReducer'
import getOrgandSlackMembers from '../../api/getOrgandSlackMembers'
import { useDispatch, useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { useAxiosWithHeader, useQuery } from '../../utils'
import { setIsOnboarded } from '../../reducers/playersReducer'
import TeamTable from '../../components/TeamTable'
import OnboardNewMembers from '../../components/OnboardNewMemberBanner'

export default function TeamPage() {
  const user = useSelector(User.selectors.selectUser)
  const organization = useSelector(Organization.selectors.getOrganization)
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  let query = useQuery()

  const slackAuth =
    user?.credential?.teamId.length > 0 ||
    user.slackAuth ||
    organization?.credentials?.find((credential) => credential.tool === 'slack') !==
      undefined

  useEffectOnce(() => {
    ;(async () => {
      if (user) {
        // need a time out so we can first get the user in due time
        await setTimeout(() => {
          getOrgandSlackMembers({
            organization,
            slackAuth,
            dispatch,
            user,
            axios,
            setIsOnboarded,
          })
        }, 1000)
      }
      if (!organization.slackMembers) {
        getOrgandSlackMembers({
          organization,
          slackAuth,
          dispatch,
          user,
          axios,
          setIsOnboarded,
        })
      }
    })()
  }, [axios, dispatch, query, user, getOrgandSlackMembers])

  return (
    <div>
      <div className="">
        <AppContent>
          <OnboardNewMembers
            organization={organization}
            isSelectMembersReady={organization.isSelectMembersReady}
          />
          <TeamTable />
        </AppContent>
      </div>
    </div>
  )
}
