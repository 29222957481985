import { InfoCircleTwoTone } from '@ant-design/icons'
import { Popover, Select, Switch, Tooltip } from 'antd'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { globalUpdateCompanySetting, useAxiosWithHeader } from '../../utils'
import { AxoloTimePicker } from '../utils/axolo-timepicker'

const { Option } = Select

export default function ReminderSetup({ isAdmin }) {
  const format = 'HH:mm'
  const axios = useAxiosWithHeader()
  const organization = useSelector(Organization.selectors.getOrganization)
  const { setting } = organization
  const [reminderTime, setreminderTime] = useState(
    moment(setting?.reminderTime, 'HH:mm:ss.SS'),
  )
  const [reminderInactivity, setreminderInactivity] = useState(setting.reminderInactivity)
  const dispatch = useDispatch()

  async function editReminders(reminderTime, reminderInactivity) {
    axios.put(`/settings/${setting._id}`, {
      reminderTime,
      reminderInactivity,
    })
    let newSettings = cloneDeep(setting)
    newSettings.reminderTime = reminderTime.toISOString()
    newSettings.reminderInactivity = parseInt(reminderInactivity)
    dispatch(Organization.actions.setData({ setting: newSettings }))
  }

  function onChangeTimePicker(time, timeString) {
    // Time is a moment object and timestring is formated
    const localTime = moment.tz(timeString, 'HH:mm', organization.timeZone)
    const utcTime = localTime.utc()
    setreminderTime(utcTime)
    editReminders(utcTime, reminderInactivity)
  }

  function onChangeReminderInactivity(hours) {
    setreminderInactivity(hours)
    editReminders(reminderTime, hours)
  }

  return (
    <>
      <div className="">
        <h1 className=" text-xl font-semibold text-gray-900">Reminder set up</h1>
        <div className="flex justify-between">
          <div>
            <p className=" flex">
              Send a reminder to stale pull requests
              <span className="flex self-center pl-3">
                <Popover
                  content={
                    <p>
                      Pull requests are stale if they do not receive a review in the last
                      X hours.
                    </p>
                  }
                >
                  <InfoCircleTwoTone twoToneColor="#0049ff" />
                </Popover>
              </span>
            </p>
          </div>
          <div className="flex w-1/2 flex-col space-y-2">
            <div className="flex justify-end">
              {isAdmin && (
                <Switch
                  style={{ backgroundColor: '#0049ff' }}
                  checked={setting?.reminders}
                  onClick={() =>
                    globalUpdateCompanySetting({
                      key: 'reminders',
                      value: !setting.reminders,
                      setting,
                      dispatch,
                      axios,
                    })
                  }
                />
              )}
              {!isAdmin && (
                <Tooltip title="Ask your admin to change your reminder setting.">
                  <Switch
                    style={{ backgroundColor: '#0049ff' }}
                    checked={setting?.reminders}
                    disabled={true}
                  />
                </Tooltip>
              )}
            </div>
            <div className="flex justify-between space-x-2">
              <p className="flex items-center">
                Every day at <br></br> (except weekends)
              </p>
              <div className="mt-5 flex w-2/3 flex-row space-x-2">
                <AxoloTimePicker
                  axios={axios}
                  format={format}
                  disabled={!isAdmin || !setting?.reminders}
                  defaultValue={moment(setting?.reminderTime).tz(organization.timeZone)}
                  onChangeTimePicker={onChangeTimePicker}
                  height={32}
                  width={200}
                />
              </div>
            </div>
            <div className="flex justify-between space-x-2">
              <p className="flex items-center"> For PR that did not </p>
              <div className="w-2/3">
                <Select
                  defaultValue={setting?.reminderInactivity?.toString()}
                  style={{ width: '100%' }}
                  onChange={onChangeReminderInactivity}
                  disabled={!isAdmin || !setting?.reminders}
                >
                  <Option value="3">receive any review for more than 3 hours</Option>
                  <Option value="6">receive any review for more than 6 hours</Option>
                  <Option value="12">receive any review for more than 12 hours</Option>
                  <Option value="24">receive any review for more than 1 day</Option>
                  <Option value="36">receive any review for more than 1.5 day</Option>
                  <Option value="48">receive any review for more than 2 days</Option>
                  <Option value="72">receive any review for more than 3 days</Option>
                  <Option value="168">receive any review for more than 1 week</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
