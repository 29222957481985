import { WarningTwoTone } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'

export default function PlayersNotInTeamChannels() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { players, setting, teams } = organization
  const notPartInAnyChannels = () => {
    try {
      const activePlayers = players.filter((p) => p.communicationToolId?.length > 0)
      const activeTeams = setting.channels?.map((channel) => channel.teams).flat()
      if (activeTeams?.includes('All Axolo onboarded users')) {
        return []
      }
      const playerIdsInActiveTeams = teams
        .filter((team) => activeTeams?.includes(team.id))
        .map((team) => team.players)
        .flat()
      const activePlayersNotInActiveTeams = activePlayers.filter(
        (player) => !playerIdsInActiveTeams?.includes(player.id),
      )
      return activePlayersNotInActiveTeams
    } catch (error) {
      console.log('error', error)
      return []
    }
  }

  const playersNotInTeamChannels = notPartInAnyChannels() || []

  const playersList = playersNotInTeamChannels.map((player, index) => {
    const teams = player.teams.map((team) => team.name).join(', ')
    return (
      <span key={player.id} className="pr-1">
        <Tooltip
          title={`${player.providerLogin} is part of ${teams} team${
            teams?.length > 1 ? 's' : ''
          }`}
        >
          <u>{player.providerLogin}</u>
        </Tooltip>
        {index < playersNotInTeamChannels.length - 1 && ','}
      </span>
    )
  })

  if (playersList.length === 0) {
    return null
  }
  return (
    <p className="flex items-center pt-2">
      <WarningTwoTone twoToneColor="orange" className="mr-2" /> {playersList}{' '}
      {playersList.length > 1 ? 'are' : 'is'} not in any team channel.
    </p>
  )
}
