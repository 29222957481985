import { Button, Spin } from 'antd'
import HomeFooter from '../FooterHomepage/footerHomePage'
import { User } from '../../reducers/userReducer'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import LoaderIcon from '../../components/LoaderIcon'

export default function LoadingPage({ footer = true, title }) {
  const user = useSelector(User.selectors.selectUser)
  const dispatch = useDispatch()
  return (
    <div className=" bg-white h-screen ">
      <div className=" h-[88vh] flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-3xl">
          <img
            className="mx-auto h-12 w-auto mb-30"
            src="/logo_axolo.png"
            alt="Logo Axolo"
          />
          {user.error ? (
            <div className="flex content-center text-center flex-col mt-30">
              <br></br>
              <h1>There seems to be an error logging you in: "{user.error}"</h1>
              <br></br>
              <Link onClick={() => dispatch(User.actions.logout({}))} to={`/`}>
                <Button
                  type="primary"
                  className="bg-primary hover:bg-indigo-700 text-textWhite hover:text-textWhite border-none"
                >
                  Go to the Home page to login again
                </Button>
              </Link>
            </div>
          ) : (
            <>
              <div className="">
                <h2 className="mt-6 text-center text-3xl font-bold text-ourBlack">
                  Loading
                </h2>
                <LoaderIcon title={title} />
              </div>
            </>
          )}
        </div>
      </div>
      {footer && <HomeFooter />}
    </div>
  )
}
