import React from 'react'
import { Avatar, Button, Table, message, Tooltip } from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import { User } from '../../reducers/userReducer'
import { useEffectOnce } from 'react-use'
import { FilterTwoTone } from '@ant-design/icons'
import { useHistory } from 'react-router'
import { ChannelInformation } from '../../components/ChannelLink'

function createRepoArrayFromPullRequests({ pullRequests }) {
  const repositories = []
  for (let i = 0; i < pullRequests?.length; i += 1) {
    repositories.push(pullRequests[i].repository)
  }
  return _.uniqBy(repositories, 'id')
}

function ButtonWithLoader(CreateChannelsAction, id, disable) {
  async function openSlackChannel() {
    const isItCreated = await CreateChannelsAction(id)

    if (isItCreated) {
      message.success('Your channel has been created.')
    }
  }
  if (disable) {
    return (
      <Tooltip title={"Can't create a channel if the creator is not onboarded on Axolo"}>
        <Button disabled={disable}>Open Channel</Button>
      </Tooltip>
    )
  }
  return (
    <Button disabled={disable} onClick={() => openSlackChannel()}>
      Open Channel
    </Button>
  )
}

const CreateChannels = ({ pullRequests, CreateChannelsAction }) => {
  const user = useSelector(User.selectors.selectUser)
  const repositories = createRepoArrayFromPullRequests({ pullRequests })
  const repoFilter = repositories?.map((repo) => ({
    text: repo.name,
    value: repo.name,
  }))

  // todo recreate a filter on repo
  const columns = [
    {
      width: 150,
      title: 'Pull request title',
      dataIndex: 'title',
      key: 'id',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      width: 150,
      title: 'Creator',
      dataIndex: 'creator',
      render: (record) => {
        if (record) {
          return (
            <Tooltip title={record?.providerLogin}>
              <Avatar src={record.avatarUrl}>{record.providerLogin}</Avatar>
            </Tooltip>
          )
        }
      },
    },
    {
      width: 150,
      title: 'Assignees',
      dataIndex: 'assignees',
      render: (records) => {
        return (
          <Avatar.Group>
            {records?.map((record) => {
              return (
                <Tooltip key={record.id} title={record?.providerLogin}>
                  <Avatar src={record.avatarUrl}>{record.providerLogin}</Avatar>
                </Tooltip>
              )
            })}
          </Avatar.Group>
        )
      },
    },
    {
      width: 150,
      title: 'Reviewers',
      dataIndex: 'requestedReviewers',
      render: (records) => {
        return (
          <Avatar.Group>
            {records?.map((record) => {
              return (
                <Tooltip key={record.id} title={record?.providerLogin}>
                  <Avatar src={record.avatarUrl}>{record.providerLogin}</Avatar>
                </Tooltip>
              )
            })}
          </Avatar.Group>
        )
      },
    },
    {
      width: 100,
      title: 'Repository',
      dataIndex: 'repository',
      key: 'id',
      render: (record) => {
        return record?.name
      },
      filters: repoFilter,
      filterSearch: true,
      filterIcon: (filtered) =>
        filtered ? (
          <FilterTwoTone twoToneColor="#0049ff" style={{ fontSize: '16px' }} />
        ) : (
          <FilterTwoTone twoToneColor="#718096" style={{ fontSize: '16px' }} />
        ),
      onFilter: (repository, record) => {
        return record.repository.name === repository
      },
    },
    {
      width: 150,
      title: 'Action',
      // dataIndex: 'id',
      key: 'id',
      render: (item, record) => {
        const isOnboardedCreator = item?.creator?.playing
        if (record?.slackChannelId) {
          const channel = {
            channelId: record?.slackChannelId,
            channelName: record?.slackChannelName,
          }
          return (
            <>
              ✅ Pull request opened:
              <br></br>
              <ChannelInformation channel={channel} slackCred={user.credential} />
            </>
          )
        } else {
          return ButtonWithLoader(CreateChannelsAction, record.id, !isOnboardedCreator)
        }
      },
    },
  ]
  return (
    <div className="">
      <p className="pb-2">
        {' '}
        All your open pull requests with and without a corresponding Slack channel are
        listed below:
      </p>
      <Table dataSource={pullRequests} columns={columns} rowKey={(record) => record.id} />
    </div>
  )
}

export default function OpenChannels({ finishButton }) {
  const axios = useAxiosWithHeader()
  const history = useHistory()
  const organization = useSelector(Organization.selectors.getOrganization)
  let { pullRequests } = organization
  const user = useSelector(User.selectors.selectUser)
  const dispatch = useDispatch()

  async function updateOrganizationPullRequests() {
    try {
      const info = await axios.get(
        `/organizations/p/onboarding/${user.organizations[0]?.id || organization?.id}`,
      )
      const { pullRequests } = info.data
      dispatch(Organization.actions.setData({ pullRequests }))
    } catch (e) {
      console.log('Error:', e)
    }
  }

  useEffectOnce(() => {
    updateOrganizationPullRequests().then()
  })

  async function CreateChannelsAction(id) {
    const prToCreate = [id]
    const hide = message.loading('Creating channel... It can take up to a minute..', 0)
    try {
      const resp = await axios.post('/organizations/channels', prToCreate)
      if (resp.data === 'restricted_action')
        message.error(
          "Slack 'restriced_action' detected. We sent you a dm in Slack to continue.",
          5,
        )
      else if (resp.data === 'Error: No onboarded members') {
        message.error(
          'No onboarded members are part of this pull request, make sure at least one member of this PR is onboarded',
          5,
        )
      } else if (resp.data === true) {
        // If true then we need to update the corresponding pull request on the front
        await setTimeout(() => {}, 2000)
        hide()
        await updateOrganizationPullRequests()
        return true
      } else {
        hide()
        message.error('Error creating your channels, please try again or contact support')
        return false
      }
      hide()
    } catch (error) {
      hide()
      message.error('Error creating your channels, please try again or contact support')
      return false
    }
  }

  return (
    <>
      <div className="mb-4 block items-end lg:flex">
        <h2 className="!mb-0 text-lg">Channels</h2>
        <h3 className="!mb-0 font-normal text-gray-400 lg:ml-8">
          Manually open Pull Request Channels in Slack
        </h3>
      </div>

      <CreateChannels
        pullRequests={pullRequests}
        CreateChannelsAction={CreateChannelsAction}
      />
      {finishButton && (
        <div className="flex justify-end">
          <button
            className="m-1 rounded-sm bg-primary px-2 py-1 text-textWhite hover:bg-hoverPrimary hover:text-textWhite disabled:cursor-not-allowed disabled:opacity-50"
            onClick={() => history.push('/settings/pull-request-channel')}
          >
            Finish & access my settings
          </button>
        </div>
      )}
      {/* <div className="mt-12 flex justify-between items-center"></div> */}
    </>
  )
}
