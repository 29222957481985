import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from 'antd/lib/avatar/avatar'
import { Table, Input, Select, Tag, message, Button } from 'antd'
import { map } from 'lodash'
import { User } from '../../reducers/userReducer'
import { PlayerState, setIsOnboarded } from '../../reducers/playersReducer'
import { Organization } from '../../reducers/organizationReducer'
import { URLBACK } from '../../assets/urls'
import { setPlayer } from '../../reducers/playersReducer'
import { useAxiosWithHeader } from '../../utils'
import { FilterTwoTone } from '@ant-design/icons'
import 'styled-components/macro'
import { colors } from '../Colors'
import getOrgandSlackMembers from '../../api/getOrgandSlackMembers'
import { useEffectOnce } from 'react-use'

const { Option } = Select
const Search = Input.Search

const FilterMenu = ({ handleReset, onSearch, playersLength }) => {
  const [searchText, setSearchText] = useState('')

  const onChange = (e) => {
    if (playersLength > 250) {
      setSearchText(e.target.value)
    } else {
      setSearchText(e.target.value)
      onSearch(e)
    }
  }

  return (
    <div className="table-operations mb-4 flex items-center">
      <Search
        size="large"
        allowClear
        enterButton={playersLength > 150}
        onChange={(e) => {
          onChange(e)
        }}
        placeholder="Search login or team"
        value={searchText}
        onPressEnter={(e) => {
          onSearch(e)
          setSearchText(e.target.value)
        }}
        onSearch={(e) => {
          setSearchText(e)
          onSearch(e)
          setSearchText(e)
        }}
        className="max-w-[400px]"
      />
    </div>
  )
}

export const PlayersList = ({ slackMembers, teams, plan }) => {
  const dispatch = useDispatch()
  const players = useSelector(PlayerState.selectors.getPlayers)
  const axios = useAxiosWithHeader()
  const [data, setData] = useState(players)

  // useEffect ne s'exécute que si players a changé
  useEffect(() => {
    setData(players)
  }, [players])

  const playersCommunicationToolIds = players?.map((player) => player.communicationToolId)
  const playersLength = players.length
  const slackMemberFiltered = slackMembers?.filter((member) => {
    const slackCommunicationToolId = member?.communicationToolId
      ? member.communicationToolId
      : member.id
    return !playersCommunicationToolIds?.includes(slackCommunicationToolId)
  })
  const activePlayers = players?.filter(
    (player) => player.communicationToolId?.length > 0,
  )?.length

  const user = useSelector(User.selectors.selectUser)
  const organization = useSelector(Organization.selectors.getOrganization)
  const slackAuth =
    user?.credential?.teamId.length > 0 ||
    user.slackAuth ||
    organization?.credentials?.find((credential) => credential.tool === 'slack') !==
      undefined

  const locale = {
    // This is use to overwrite the NO DATA in the AntD Table
    emptyText: (
      <div>
        <p>No Data</p>
        <Button
          onClick={() => {
            getOrgandSlackMembers({
              organization,
              slackAuth,
              dispatch,
              user,
              axios,
              setIsOnboarded,
              requestRefresh: true,
            })
          }}
        >
          Refresh
        </Button>
      </div>
    ),
  }

  async function onChange(value, option, player) {
    const slackId = value || ''
    const slackUsername = option?.children[1] || ''

    try {
      const resp = await axios.put(`${URLBACK}players/${player.id}`, {
        playing: true,
        communicationToolId: slackId,
        userName: slackUsername,
      })
      dispatch(setPlayer(resp.data))
      message.destroy()
      message.success('Member updated')
      if (slackId.length > 0 && plan === 'starter' && activePlayers === 4) {
        message.warning('Switch to a paid plan to add more than 5 engineers in Axolo')
      }
    } catch (error) {
      console.error({ error })
      message.error('Error updating member', player.providerLogin)
    }
  }

  const onSearch = (e) => {
    if (typeof e !== 'string' && (!e?.target?.value || e?.target?.value === '')) {
      handleReset()
      return
    }
    const value = typeof e === 'string' ? e : e?.target?.value
    const reg = new RegExp(value, 'gi')

    const filteredData = map(players, (record) => {
      const loginMatch = record.providerLogin.match(reg)
      const githubTeamString = record.teams.map((team) => team.slug).join(',')
      const githubTeamMatch = githubTeamString.match(reg)
      if (!loginMatch && !githubTeamMatch) {
        return null
      }
      return record
    }).filter((record) => !!record)
    setData(filteredData)
  }

  const handleReset = () => {
    setData(players)
  }

  const teamColor = {}
  teams?.forEach((team) => {
    // To define the color of a team I'm taking the char code of the first letter of their team name.
    let colorNumber = Math.abs(team.name[0].toLowerCase().charCodeAt(0) - 97)
    if (colorNumber > colors.length) {
      colorNumber = colorNumber % colors.length
    }
    teamColor[team.name] = colors[colorNumber]
  })

  const teamFilter = teams?.map((team) => ({
    text: team.name,
    value: team.name,
  }))

  const columns = [
    {
      title: '',
      dataIndex: 'avatarUrl',
      key: 'avatarUrl',
      render: (avatarUrl) => <Avatar src={avatarUrl}></Avatar>,
    },
    {
      title: 'Login',
      dataIndex: 'providerLogin',
      key: 'providerLogin',
      sorter: (a, b) => a.providerLogin.length - b.providerLogin.length,
      sortDirections: ['descend'],
    },
    {
      title: 'Team',
      dataIndex: 'teams',
      key: 'teams',
      render: (playerTeams) => (
        <>
          {playerTeams?.map((team) => {
            return (
              <Tag
                style={{ marginBottom: '5px' }}
                color={teamColor[team.name]}
                key={team.slug}
              >
                {team.name.length > 25 ? team.name.slice(0, 25) + '...' : team.name}
              </Tag>
            )
          })}
        </>
      ),
      filters: teamFilter,
      filterSearch: true,
      filterIcon: (filtered) =>
        filtered ? (
          <FilterTwoTone twoToneColor="#0049ff" style={{ fontSize: '16px' }} />
        ) : (
          <FilterTwoTone twoToneColor="#718096" style={{ fontSize: '16px' }} />
        ),
      onFilter: (teams, record) => {
        return record.teams.map((team) => team.name === teams).includes(true)
      },
    },
    {
      title: 'Slack Handle',
      dataIndex: 'slackHandle',
      key: 'slackHandle',
      render: (item, raw) => {
        const foundMember = slackMembers?.find(
          (member) => member.id === raw.communicationToolId,
        )
        const shouldDisable =
          activePlayers > 4 && (plan === 'starter' || plan === 'blocked')
            ? !foundMember
            : false
        let errorMsg
        if (plan === 'starter') {
          errorMsg =
            'Starter plan can only have 5 members maximum. Please switch to a paid plan to add more engineers.'
        } else if (plan === 'blocked') {
          errorMsg =
            'Your team is blocked, switch to a paid plan or have 5 engineers maximum on Axolo'
        }
        // This can happen when a user was onboarded on axolo but later got deleted from Slack
        let defaultValue = ''
        if (!foundMember && (raw.communicationToolId || raw.userName)) {
          defaultValue = raw.userName || raw.communicationToolId || ''
        }
        return (
          <Select
            showSearch
            key={raw.id}
            style={{ width: 280 }}
            placeholder="Select"
            optionFilterProp="children"
            disabled={shouldDisable}
            onClick={() => shouldDisable && message.error(errorMsg)}
            defaultValue={
              foundMember
                ? `${foundMember.name} ${
                    foundMember.name === foundMember.real_name
                      ? ''
                      : `- ${foundMember.real_name}`
                  }`
                : defaultValue
            }
            allowClear
            onChange={(value, option) => onChange(value, option, raw)}
          >
            {slackMemberFiltered?.map((member) => (
              // Member.communicationToolId happens when we fetch slack list from api.axolo.co rather than slack directly
              <Option
                key={member.id}
                value={
                  member?.communicationToolId?.length > 0
                    ? member.communicationToolId
                    : member.id
                }
              >
                <Avatar
                  size={30}
                  src={member?.profile?.image_24 || member?.image_24}
                  className="!mr-2"
                />
                {member.name}{' '}
                {member.name === member.real_name ? '' : `- ${member.real_name}`}
              </Option>
            ))}
          </Select>
        )
      },
    },
  ]

  return (
    <>
      <p className="">Map GitHub logins with Slack accounts to onboard new engineers.</p>
      <FilterMenu
        handleReset={handleReset}
        onSearch={(e) => onSearch(e)}
        playersLength={playersLength}
      />
      <Table
        locale={locale}
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 50 }}
        rowKey="id"
      />
    </>
  )
}
