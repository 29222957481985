import { useDispatch, useSelector } from 'react-redux'
import { selectJWT, selectIsAdminUser, User } from './reducers/userReducer'
import { useLocation } from 'react-router'
import Axios from 'axios'
import { URLBACK } from './assets/urls'
import messageInteraction from './components/Analytics/utils/messageInteraction'
import { Organization } from './reducers/organizationReducer'

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export function useIsLogged() {
  const jwt = useSelector(selectJWT)
  return jwt && jwt.length > 0
}

export function useIsAdminUser() {
  const isAdminUser = useSelector(selectIsAdminUser)
  return isAdminUser
}

export function useIsEngineer() {
  const user = useSelector(User.selectors.selectUser)
  return user?.role?.type === 'engineer'
}

export function createAxios(jwt) {
  return Axios.create({
    baseURL: URLBACK,
    headers: { Authorization: `Bearer ${jwt}` },
  })
}

export const useAxiosWithHeader = (app = 'axolo') => {
  const jwt = useSelector(User.selectors.selectJWT)
  const analyticsJwt = useSelector(User.selectors.selectAnalyticsJWT)
  if (app === 'analytics') return createAxios(analyticsJwt)
  else return createAxios(jwt)
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export async function globalUpdateCompanySetting({
  key,
  value,
  action = {},
  setting,
  axios,
  dispatch,
}) {
  const hide = messageInteraction({ content: 'Loading...', type: 'loading', duration: 0 })
  try {
    const resp = await axios.put(`/settings/${setting.id}`, {
      [key]: value,
      action,
    })
    hide()
    const newSetting = resp.data
    dispatch(Organization.actions.setData({ setting: newSetting }))
    return resp.data
  } catch (e) {
    hide()
    console.log('Error updating settings', e.message, e)
    messageInteraction({
      type: 'error',
      content: 'Error updating your setting. Try again or contact support.',
    })
    return e
  }
}
