import { Settings } from '../../components/Settings'
import React from 'react'
import { useEffectOnce } from 'react-use'
import { AppContent } from '../../components/utils/utils'
import { User } from '../../reducers/userReducer'
import { Organization } from '../../reducers/organizationReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useAxiosWithHeader, useQuery } from '../../utils'
import { SettingsNotOnboarded } from '../../components/SettingsNotOnboarded'
import { setIsOnboarded, PlayerState } from '../../reducers/playersReducer'
import getOrgandSlackMembers from '../../api/getOrgandSlackMembers'

function PullRequestChannelSettings() {
  const user = useSelector(User.selectors.selectUser)
  const organization = useSelector(Organization.selectors.getOrganization)
  const isOnboarded = useSelector(PlayerState.selectors.isOnboarded)
  const axios = useAxiosWithHeader()
  let query = useQuery()
  const dispatch = useDispatch()

  const slackAuth =
    user?.credential?.teamId.length > 0 ||
    user.slackAuth ||
    organization?.credentials?.find((credential) => credential.tool === 'slack') !==
      undefined

  useEffectOnce(() => {
    ;(async () => {
      if (user) {
        // need a time out so we can first get the user in due time
        await setTimeout(() => {
          getOrgandSlackMembers({
            organization,
            slackAuth,
            dispatch,
            user,
            axios,
            setIsOnboarded,
          })
        }, 1000)
      }
      if (!organization.slackMembers) {
        getOrgandSlackMembers({
          organization,
          slackAuth,
          dispatch,
          user,
          axios,
          setIsOnboarded,
        })
      }
    })()
  }, [axios, dispatch, query, user, getOrgandSlackMembers])
  // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="">
        <AppContent className="mt-4 flex">
          {!isOnboarded && <SettingsNotOnboarded />}
          {isOnboarded && (
            <Settings isSelectMembersReady={organization.isSelectMembersReady} />
          )}
        </AppContent>
      </div>
    </div>
  )
}

export default PullRequestChannelSettings
