import ReminderMessagePersonalization from '../../components/ReminderMessagePersonalization'
import ReminderSetup from '../../components/ReminderSetup'

export default function ReminderPage() {
  return (
    <div className="">
      <ReminderSetup isAdmin={true} />

      <ReminderMessagePersonalization isAdmin={true} />
    </div>
  )
}
