import React from 'react'

import { Link } from 'react-router-dom'
import { RandomAxoloAvatar } from '../../components/RandomAxoloAvatar'
import { useDispatch } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { Button } from 'antd'

export const Error = () => {
  const dispatch = useDispatch()
  return (
    <div className="h-screen w-screen flex justify-center items-center flex-col">
      <h1 className="text-3xl text-textWhite">404</h1>
      <RandomAxoloAvatar></RandomAxoloAvatar>
      <h1 className="pb-10 text-xl text-textWhite">
        This is not the web page you are looking for.
      </h1>
      <Link onClick={() => dispatch(User.actions.logout({}))} to={`/`}>
        <Button
          type="primary"
          className="bg-primary hover:bg-indigo-700 text-textWhite hover:text-textWhite border-none"
        >
          Go to the Home page to login again
        </Button>
      </Link>
    </div>
  )
}
