import React from 'react'
import { Picker } from 'emoji-mart'
import { Component } from 'react'
import onClickOutside from 'react-onclickoutside'

class EmojiMenu extends Component {
  handleClickOutside = (evt) => {
    this.props.closeEmojiSelect()
  }
  render() {
    const { handleEmojiSelect, pickerName, emojiList } = this.props
    return (
      <Picker
        set="apple"
        custom={emojiList}
        onSelect={(emoji) => {
          handleEmojiSelect(emoji)
        }}
        theme="light"
        color="#0049ff"
        showSkinTones={false}
        title={`Pick up ${pickerName}`}
        emoji="point_up"
      />
    )
  }
}
export default onClickOutside(EmojiMenu)
