import { ExternalLinkIcon } from '@heroicons/react/outline'

export const ChannelInformation = ({ channel, slackCred }) => {
  return (
    <a
      className="block overflow-hidden text-gray-800"
      target={'_blank'}
      rel="noreferrer nofollow"
      href={` https://slack.com/app_redirect?channel=${channel.channelId}&team=${slackCred.teamId}`}
    >
      <div className="flex flex-row items-center">
        <span className="block w-[250px] overflow-hidden text-ellipsis whitespace-nowrap">
          #{channel?.channelName}
        </span>
        <div>
          <ExternalLinkIcon className="ml-2 mt-1 w-4" />
        </div>
      </div>
    </a>
  )
}
