import _, { sortBy } from 'lodash'
import CreatableSelect from 'react-select/creatable'
import messageInteraction from '../Analytics/utils/messageInteraction'

export const InputNewLabel = ({
  organizationLabels,
  labelManagerSetting,
  newLabelSelected,
  handleNewLabelSelected,
}) => {
  const labelsOption = sortBy(
    organizationLabels
      ?.map((label) => {
        if (!Object.keys(labelManagerSetting).includes(label.name)) {
          const obj = {
            value: label.name,
            label: label.name,
            id: label.id,
          }
          return obj
        }
      })
      .filter((item) => item !== undefined),
    'value',
  )

  return (
    <div className="">
      <CreatableSelect
        createOptionPosition="first"
        isClearable
        onChange={handleNewLabelSelected}
        value={newLabelSelected}
        openMenuOnClick={false}
        options={labelsOption}
        noOptionsMessage={() => null}
        isValidNewOption={() => false}
        placeholder="Select a new label ..."
        promptTextCreator={() => false}
        formatCreateLabel={() => undefined}
        maxMenuHeight={175}
      />
    </div>
  )
}

export const AddNewLabel = ({
  newLabelSelected,
  setLabelManagerSetting,
  labelManagerSetting,
  updateCompanySetting,
  defaultNewSetting,
  setNewLabelSelected,
}) => {
  async function addNewLabel() {
    const newLabelManagerSetting = _.cloneDeep(labelManagerSetting)
    // deleting the new entry
    // https://stackoverflow.com/questions/34698905/how-can-i-clone-a-javascript-object-except-for-one-key
    if (!newLabelSelected) {
      messageInteraction({ content: 'Please select an existing label.', type: 'warning' })
      return
    }
    newLabelManagerSetting[newLabelSelected.label] = labelManagerSetting.new
    newLabelManagerSetting['new'] = defaultNewSetting
    setLabelManagerSetting(newLabelManagerSetting)
    const newLabelManagerSettingFiltered = Object.entries(newLabelManagerSetting)
      .filter((entry) => entry[0] !== 'new')
      .reduce(
        (acc, keyValueTuple) => ({ ...acc, [keyValueTuple[0]]: keyValueTuple[1] }),
        {},
      )
    await updateCompanySetting('labelManager', newLabelManagerSettingFiltered, {
      operation: 'add',
      label: newLabelSelected.label,
    })
    setNewLabelSelected(null)
  }
  return (
    <button
      type="button"
      onClick={() => addNewLabel()}
      className="inline-flex items-center px-4 py-2 border border-transparent  font-medium rounded-md shadow-sm text-white bg-primary hover:bg-hoverPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
    >
      New label
    </button>
  )
}

export async function handleDeleteLabel({
  label,
  labelManagerSetting,
  setLabelManagerSetting,
  updateCompanySetting,
}) {
  const newLabelManagerSetting = _.cloneDeep(labelManagerSetting)
  // deleting the new entry
  // https://stackoverflow.com/questions/34698905/how-can-i-clone-a-javascript-object-except-for-one-key
  let newLabelManagerSettingFiltered = Object.entries(newLabelManagerSetting)
    .filter((entry) => entry[0] !== label)
    .reduce(
      (acc, keyValueTuple) => ({ ...acc, [keyValueTuple[0]]: keyValueTuple[1] }),
      {},
    )
  setLabelManagerSetting(newLabelManagerSettingFiltered)
  newLabelManagerSettingFiltered = Object.entries(newLabelManagerSettingFiltered)
    .filter((entry) => entry[0] !== 'new')
    .reduce(
      (acc, keyValueTuple) => ({ ...acc, [keyValueTuple[0]]: keyValueTuple[1] }),
      {},
    )
  await updateCompanySetting('labelManager', newLabelManagerSettingFiltered, {
    operation: 'delete',
    label,
  })
}

async function handleCheckboxOnClick({
  checked,
  action,
  label,
  labelManagerSetting,
  setLabelManagerSetting,
  updateCompanySetting,
}) {
  const newLabelManagerSetting = _.cloneDeep(labelManagerSetting)
  newLabelManagerSetting[label][action] = !checked
  setLabelManagerSetting(newLabelManagerSetting)
  if (label === 'new') return
  // deleting the new entry
  // https://stackoverflow.com/questions/34698905/how-can-i-clone-a-javascript-object-except-for-one-key
  const newLabelManagerSettingWithoutNew = Object.entries(newLabelManagerSetting)
    .filter((entry) => entry[0] !== 'new')
    .reduce(
      (acc, keyValueTuple) => ({ ...acc, [keyValueTuple[0]]: keyValueTuple[1] }),
      {},
    )
  await updateCompanySetting('labelManager', newLabelManagerSettingWithoutNew, {
    operation: 'edit',
    label,
  })
  messageInteraction({ content: 'Label updated.', type: 'success' })
}

function convertLabelSettingToString(action) {
  if (action === 'open-channel') return 'opens a Slack channel'
  if (action === 'live-comment-from-slack') return 'sends comments from Slack to GitHub'
  if (action === 'notification-to-team-channels')
    return 'sends notification(s) in team channel(s)'
  return `Missing action ${action}`
}

export const BehaviorList = ({
  labelSetting,
  label,
  labelManagerSetting,
  setLabelManagerSetting,
  updateCompanySetting,
}) => {
  return (
    <div className="">
      {Object.keys(labelSetting).map((action) => (
        // https://tailwindui.com/components/application-ui/forms/checkboxes
        <div className="relative flex items-start" key={`${label}-${action}`}>
          <div className="flex items-center h-5">
            <input
              id={`${label}-${action}`}
              aria-describedby={`${label}-${action}-description`}
              name={`${label}-${action}`}
              type="checkbox"
              className="h-3.5 w-3.5 border-gray-300 rounded"
              checked={labelSetting[action]}
              onChange={() =>
                handleCheckboxOnClick({
                  checked: labelSetting[action],
                  action,
                  label,
                  labelManagerSetting,
                  setLabelManagerSetting,
                  updateCompanySetting,
                })
              }
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor={`${label}-${action}`} className=" ">
              {convertLabelSettingToString(action)}
            </label>
          </div>
        </div>
      ))}
    </div>
  )
}
