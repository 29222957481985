import userReducer from '../reducers/userReducer'
import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { organizationSlice } from '../reducers/organizationReducer'
import { playersSlice } from '../reducers/playersReducer'
import { analyticsSlice } from '../reducers/analyticsReducer'
import { settingSlice } from '../reducers/settingReducer'
import { standupsSlice } from '../reducers/standupReducer'
import { engineerSlice } from '../reducers/engineerReducer'

const reducers = combineReducers({
  user: userReducer,
  organization: organizationSlice.reducer,
  playerState: playersSlice.reducer,
  analytics: analyticsSlice.reducer,
  setting: settingSlice.reducer,
  standups: standupsSlice.reducer,
  engineer: engineerSlice.reducer,
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
})

export default store
