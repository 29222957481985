import React from 'react'
import { Organization } from '../../reducers/organizationReducer'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PlayersList } from '../../components/PlayersList'
import { PlayerState, setIsOnboarded } from '../../reducers/playersReducer'
import OnboardingSteps from '../../components/OnboardingSteps'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'

export default function OnboardingInviteMembers() {
  const players = useSelector(PlayerState.selectors.getPlayers)
  const organization = useSelector(Organization.selectors.getOrganization)
  const history = useHistory()
  const dispatch = useDispatch()
  let { slackMembers, plan } = organization

  const canContinue = players?.filter((player) => player.communicationToolId)?.length > 0

  return (
    <MainDivOnboarding>
      <OnboardingSteps step={3} />
      <>
        <br></br>
        <PlayersList plan={plan} slackMembers={slackMembers} teams={organization.teams} />
        <div className="flex justify-end">
          <button
            className="m-1 rounded-sm bg-primary px-2 py-1 text-textWhite hover:bg-hoverPrimary hover:text-textWhite disabled:cursor-not-allowed disabled:opacity-50"
            disabled={!canContinue}
            onClick={() => {
              dispatch(setIsOnboarded(true))
              history.push('/settings/pull-request-channel')
            }}
          >
            Finish & access my settings
          </button>
          {organization.pullRequests.length > 0 && (
            <button
              className="m-1 rounded-sm bg-primary px-2 py-1 text-textWhite hover:bg-hoverPrimary hover:text-textWhite disabled:cursor-not-allowed disabled:opacity-50"
              disabled={!canContinue}
              onClick={() => {
                dispatch(setIsOnboarded(true))
                history.push('/home/onboarding/open-channels')
              }}
            >
              Create my first channel
            </button>
          )}
        </div>
      </>
    </MainDivOnboarding>
  )
}
