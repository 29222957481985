import { User } from './userReducer'
import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

let initialState = {
  loading: true,
  error: false,
  myStandups: [],
  otherStandups: [],
}

export const standupsSlice = createSlice({
  name: 'standups',
  initialState,
  reducers: {
    setData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    addMyStandup: (state, action) => {
      return {
        ...state,
        myStandups: [...state.myStandups, action.payload],
      }
    },
    deleteStandup: (state, action) => {
      const { id } = action.payload
      let newMyStandups = cloneDeep(state.myStandups)
      newMyStandups = newMyStandups.filter((standup) => standup.id !== id)
      return {
        ...state,
        myStandups: newMyStandups,
      }
    },
    editStandup: (state, action) => {
      const standup = action.payload
      let newMyStandups = cloneDeep(state.myStandups)
      const index = newMyStandups.findIndex((s) => {
        return s.id === standup?.id
      })
      newMyStandups[index] = standup
      return {
        ...state,
        myStandups: newMyStandups,
      }
    },
    setError: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    setLoading: (state) => ({ ...state, loading: true }),
    unsetLoading: (state) => ({ ...state, loading: false }),
  },
  extraReducers: (builder) => {
    builder.addCase(User.actions.logout, (_) => initialState)
  },
})

export const { setError, setData, addMyStandup, deleteStandup, editStandup } =
  standupsSlice.actions

const getStandups = (state) => state.standups
const getMyStandups = (state) => state.standups.myStandups
const getOtherStandups = (state) => state.standups.otherStandups

export const Standups = {
  actions: standupsSlice.actions,
  selectors: { getStandups, getOtherStandups, getMyStandups },
}
