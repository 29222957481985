import React, { useState } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import Emoji from 'react-emoji-render';
import EmojiMenu from './emojiMenu';
import { Image, message } from 'antd';
import { useAxiosWithHeader } from '../../utils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const EmojiHolder = ({ disabled, orgIsPremium }) => {
  console.log(!disabled && 'items-center space-x-2 px-2 py-2  text-sm font-medium bg-white rounded-md hover:bg-gray-100 border-gray-200',
    (disabled || orgIsPremium) && 'items-center space-x-2 px-2  py-2  text-sm font-medium bg-gray-200 rounded-md hover:bg-gray-200 cursor-not-allowed')
  return (
    <button
      className={classNames(!disabled && 'items-center space-x-2 px-2 py-2  text-sm font-medium bg-white rounded-md hover:bg-gray-100 border-gray-200',
        (disabled || orgIsPremium) && 'items-center space-x-2 px-2 py-2  text-sm font-medium bg-gray-200 rounded-md hover:bg-gray-200 cursor-not-allowed')} />
  )
}
const EmojiPicker = ({
  picker,
  pickerName,
  setPickerSelected,
  pickerSelected,
  disabled,
  setting,
  emojiList,
  setIsModalPaywallVisible,
  orgIsPremium
}) => {
  const axios = useAxiosWithHeader();

  function handleOpenEmojiPicker() {
    setPickerSelected({
      ...pickerSelected,
      [pickerName]: {
        isSelected: true,
        emoji: pickerSelected[pickerName].emoji,
      },
    });
  }

  function closeEmojiSelect() {
    setPickerSelected({
      ...pickerSelected,
      [pickerName]: {
        isSelected: false,
        emoji: pickerSelected[pickerName].emoji,
      },
    });
  }

  async function handleEmojiSelect(emoji) {
    setPickerSelected({
      ...pickerSelected,
      [pickerName]: { isSelected: false, emoji: emoji.id },
    });
    const emojiName = emoji.id === '+1' ? 'thumbsup' : emoji.id;
    try {
      const res = await axios.get(
        `/organizations/updateSettings/${setting.organization.id || setting.organization}?setting=personalEmojiReaction&status=${pickerName}&emoji=${emojiName}`,
      );
      if (res?.status !== 200)
        message.error(
          'Error updating your personal emoji. Please refresh this page.',
        );
    } catch (error) {
      if (error?.response?.status === 402) {
        setIsModalPaywallVisible(true);
        return;
      }
    }
  }

  function onClickEmojiHolder() {
    if (!orgIsPremium) {
      setIsModalPaywallVisible(true)
    }
  }

  const isCustomEmoji = emojiList?.find((emoji) => emoji.name === picker.emoji);
  return (
    <div className="relative ">
      <div className="absolute bottom-12 right-0">
        {picker.isSelected && (
          <EmojiMenu
            emojiList={emojiList}
            pickerName={pickerName}
            handleEmojiSelect={handleEmojiSelect}
            closeEmojiSelect={closeEmojiSelect}
          />
        )}
      </div>
      <div className="text-center" onClick={onClickEmojiHolder}>
        <p className="">{pickerName}</p>
        <div className="mt-1">
          <button
            onClick={() => !disabled && handleOpenEmojiPicker()}
            className={classNames(!disabled && 'items-center space-x-2 px-2 py-2  text-sm font-medium bg-white rounded-md hover:bg-gray-100 border border-gray-200 shadow-sm',
              (disabled || !orgIsPremium) && 'items-center space-x-2 px-2 py-2  text-sm font-medium bg-gray-200 rounded-md hover:bg-gray-300 cursor-not-allowed')}
          >
            {isCustomEmoji ? (
              <Image src={isCustomEmoji.imageUrl} width={24} style={{ width: 24 }} preview={false} />
            ) : (

              <Emoji
                className="text-lg" text={`${picker.emoji === 'large_green_circle' ? '🟢' : `:${picker.emoji}:`}`} />

            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default function EmojiPickers({ setting, emojiList, setIsModalPaywallVisible, orgIsPremium }) {
  const [pickerSelected, setPickerSelected] = useState({
    draft: { isSelected: false, emoji: setting?.draftEmoji },
    wip: { isSelected: false, emoji: setting?.wipEmoji },
    reviewable: { isSelected: false, emoji: setting?.reviewableEmoji },
    approved: { isSelected: false, emoji: setting?.approvedEmoji },
    mergeable: { isSelected: false, emoji: setting?.mergeableEmoji },
    closed: { isSelected: false, emoji: setting?.closedEmoji },
    merged: { isSelected: false, emoji: setting?.mergedEmoji },
  });

  return (
    <div className="grid grid-cols-4 gap-x-20 gap-y-8">
      {Object.keys(pickerSelected).map((pickerName) => (
        <EmojiPicker
          orgIsPremium={orgIsPremium}
          setIsModalPaywallVisible={setIsModalPaywallVisible}
          emojiList={emojiList}
          pickerName={pickerName}
          picker={pickerSelected[pickerName]}
          pickerSelected={pickerSelected}
          setPickerSelected={setPickerSelected}
          disabled={setting.channels === undefined || setting?.channels?.length === 0}
          setting={setting}
          key={pickerName}
        />
      ))}
    </div>
  );
}
