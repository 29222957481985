import { StopOutlined } from '@ant-design/icons'
import { Avatar, Button, Popconfirm, Select, Tag, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import {
  AxoloTrashIcon,
  AxoloDeactivateIcon,
  AxoloArchiveIcon,
} from '../Settings/setting-helper'
import { findTeamNameFromId } from '../TeamChannels/helpers'
import {
  classActionColumn,
  classChannelColumn,
  classCreatorColumn,
  classTeamColumn,
} from './teamChannelTableHelper'

const { Option } = Select

export const TeamChannelTableData = ({
  handleSelectTeamsOnExistingChannel,
  newChannelState,
  validateTeams,
  onDelete,
  channels,
  personal,
}) => {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { teams = [] } = organization

  const TeamTags = ({ channel }) => {
    let disable
    const raw = channel
    const teamSelect = []
    const preselected = channel?.teams?.map((id) => {
      const teamName = findTeamNameFromId({ id, teams })
      return teamName
    })

    if (!personal || preselected.includes('All Axolo onboarded users')) {
      const teamNames = channel?.teams?.map((id) => (
        <Tag key={id} className="font-medium">
          {findTeamNameFromId({ id, teams })}
        </Tag>
      ))
      return teamNames
    }

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i]
      teamSelect.push(
        <Option key={team.id} value={team.name} id={team.id}>
          {team.name}
        </Option>,
      )
    }

    return (
      <div className="flex">
        <Select
          mode="multiple"
          allowClear
          disabled={disable}
          style={{ width: '100%' }}
          placeholder="Please select"
          defaultValue={preselected}
          labelInValue
          onChange={(e) => handleSelectTeamsOnExistingChannel(e, raw)}
        >
          {teamSelect}
        </Select>
        <Button
          className="ml-3"
          onClick={validateTeams}
          disabled={!newChannelState || newChannelState?.id !== raw?.id}
        >
          Validate
        </Button>
      </div>
    )
  }

  const CellAction = ({ channel }) => {
    if (!personal)
      return (
        <>
          <Tooltip title="You can't take actions on channels settings that are not yours.">
            <Button icon={<StopOutlined />} shape="circle" style={{ color: 'grey' }} />
          </Tooltip>
        </>
      )
    if (!channel.desactivate) {
      return (
        <>
          <Popconfirm
            placement="topRight"
            title="Do you want to archive this channel?"
            onConfirm={() => onDelete({ channelTitle: channel.title })}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<AxoloArchiveIcon />} shape="circle" type="danger" />
          </Popconfirm>
          <br></br>
          <Popconfirm
            placement="topRight"
            title="Do you want to deactivate Axolo on this channel?"
            onConfirm={() => onDelete({ channelTitle: channel.title, desactivate: true })}
            className="ml-3"
            okText="Yes"
            cancelText="No"
          >
            <Button
              icon={<AxoloDeactivateIcon />}
              shape="circle"
              style={{ background: '#faad14' }}
            />
          </Popconfirm>
        </>
      )
    } else {
      return (
        <>
          <Tooltip title="This channel has been desactivated, upgrade to business to reactivate it.">
            <Button icon={<StopOutlined />} shape="circle" style={{ color: 'grey' }} />
          </Tooltip>
        </>
      )
    }
  }

  return channels?.map((channel) => (
    <tr key={channel?.id}>
      <td className={classChannelColumn}>{channel?.title}</td>
      <td className={classTeamColumn}>
        <TeamTags channel={channel} personal={personal} />
      </td>
      <td className={classCreatorColumn}>
        <div className="flex items-center">
          <Avatar src={channel?.creator?.avatarUrl}></Avatar>
          <p className="mb-0 pl-2 text-gray-800">{channel?.creator?.providerLogin}</p>
        </div>
      </td>
      <td className={classActionColumn}>
        <span href="#" className="text-indigo-600 hover:text-indigo-900">
          <div className="flex justify-center ">
            <CellAction channel={channel} />
          </div>
        </span>
      </td>
    </tr>
  ))
}
