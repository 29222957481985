import React from 'react'
import { Popover, Select } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'
import Avatar from 'antd/lib/avatar/avatar'

const { Option } = Select

export default function WhiteListBot({ organization, setting, updateCompanySetting }) {
  const handleChange = (value) => {
    updateCompanySetting('botWhiteList', value, {}, true)
  }

  const children = setting?.botList?.map((bot) => {
    if (bot.login !== 'axolo-co[bot]') {
      return (
        <Option key={bot.id} value={bot.login}>
          <Avatar size={20} src={bot.avatar_url} className="!mr-2" />
          {bot.login.split('[bot]')[0]}
        </Option>
      )
    }
  })

  return (
    <div className="mt-8">
      <div className="flex justify-between flex-col">
        <div className="flex justify-between flex-row">
          <p className="flex mb-0">
            Enable bots to comment, review or create pull requests
            <span className="pl-3 flex pt-[4px]">
              <Popover
                content={
                  'This list is updated every time a bot comments, reviews, or creates a pull request. Log in again to see the updated list.'
                }
              >
                <InfoCircleTwoTone twoToneColor="#0049ff" />
              </Popover>
            </span>
          </p>
          <div style={{ minWidth: 550, maxWidth: 650 }}>
            <div className="flex">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={setting.botWhiteList || []}
                onChange={handleChange}
              >
                {children}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
