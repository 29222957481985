import { message } from 'antd'

export default function ButtonRequest({ axios, URL, text, adminName }) {
  const request = async () => {
    try {
      const res = await axios.get(URL)
      if (res.status === 200) message.success(`Message successfully sent to ${adminName}`)
    } catch (e) {
      message.error('An error occured, please try again or contact us.')
    }
  }

  return (
    <div className="">
      <span className="flex justify-center" onClick={() => request()}>
        <button
          type="button"
          className="block items-center px-4 py-2 my-2 border border-transparent shadow-sm text-sm font-medium
          rounded-md text-white bg-primary hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2
          
          focus:ring-indigo-500"
        >
          {text}
        </button>
      </span>
    </div>
  )
}
