import { GITHUB_APP_INSTALL_URL, URLBACK } from '../../assets/urls'

export function goGithubApp(userId, authorized) {
  authorized
    ? window.open(`${GITHUB_APP_INSTALL_URL}/installations/new?state=${userId}`, '_blank')
    : window.location.assign(
        `${GITHUB_APP_INSTALL_URL}/installations/new?state=${userId}`,
      )
}

export function goGitlabApp(userId, authorized) {
  authorized
    ? window.open(`${URLBACK}gitlab/identify?id=${userId}`, '_blank')
    : window.location.assign(`${URLBACK}gitlab/identify?id=${userId}`)
}
