import AdminList from '../../components/AdminList/adminList'

export default function Example() {
  return (
    <>
      <div>
        <div className="">
          <div className="mx-auto flex flex-col">
            <AdminList />
          </div>
        </div>
      </div>
    </>
  )
}
