import { Redirect, Route } from 'react-router-dom'
import React from 'react'
import { useIsLogged, useIsAdminUser, useIsEngineer } from '../../utils'
import AppLayout from '../Layout'

export const PrivateRoute = ({ children, ...rest }) => {
  const isLogged = useIsLogged()
  const isAdminUser = useIsAdminUser()
  const isEngineer = useIsEngineer()
  const isNotAdminNeitherEngineer = isEngineer || isAdminUser
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLogged ? (
          !isNotAdminNeitherEngineer ? (
            <>{children}</>
          ) : (
            <AppLayout>{children}</AppLayout>
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
