import React, { useState } from 'react'
import { Organization } from '../../reducers/organizationReducer'
import { useHistory } from 'react-router-dom'
import { useAxiosWithHeader } from '../../utils'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { PlayerState } from '../../reducers/playersReducer'
import OnboardingSteps from '../../components/OnboardingSteps'
import LoaderIcon from '../../components/LoaderIcon'
import { Typography } from 'antd'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import gitlab from '../../assets/images/gitlab-logo-500.png'
import { set } from 'lodash'

export default function GitLabGroups() {
  const axios = useAxiosWithHeader()
  const history = useHistory()
  const dispatch = useDispatch()

  const [nameSpaces, setNameSpaces] = useState(false)
  const [loader, setLoader] = useState('Getting your gitlab organizations..')

  useEffectOnce(() => {
    setLoader('Fetching GitLab organizations, please wait.')

    axios
      .get(`/gitlab/getNameSpaces`)
      .then((resp) => {
        setNameSpaces(resp.data)
        if (resp.data.length === 0) {
          setLoader(
            "Didn't find any organizations, ask your admin to install or contact us for support.",
          )
        }
        setLoader(false)
      })
      .catch((resp) => {
        if (resp.message.includes('409')) {
          setLoader(
            `Error: You are already assigned to an organization. Contact support or onboard your team.`,
          )
        } else {
          setLoader(`Error ${resp.message}`)
        }
      })
  })

  async function selectGroup(id) {
    setLoader(
      'Creating your organization, fetching players, repositories, and merge requests. This process can take up to 2 minutes.',
    )
    try {
      const groupResp = await axios.get(`/gitlab/onboardingGitlab/${id}`)
      const {
        organization,
        slackMembers,
        pullRequests,
        setting,
        paymentPlan,
        emojiList,
        teams,
      } = groupResp.data
      dispatch(
        Organization.actions.setData({
          ...organization,
          teams,
          slackMembers,
          pullRequests,
          setting,
          paymentPlan,
          emojiList,
        }),
      )
      dispatch(PlayerState.actions.setInitialPlayers({ players: organization.players }))
      history.push('/home/onboarding/members')
    } catch (error) {
      console.log('error fetching gitlab organization', error)
      setLoader('Error creating your organization, try again or contact support.')
    }
  }

  return (
    <MainDivOnboarding>
      <OnboardingSteps step={2} />
      <br></br>
      <div className="flex justify-center m-10 flex-col text-center">
        <Typography.Title level={4}>Choose a GitLab account</Typography.Title>
      </div>
      {nameSpaces.length === 0 && !loader && (
        <div className="flex flex-col">
          <p className="text-center text-slate-700 mt-5">
            It seems you are not the owner of any groups,
            <br></br>
            ask your admin to add you as an owner to your group to install Axolo
            <br></br>
            or ask your admin to Install Axolo.
            <br></br>
            <br></br>
            You can refresh this page to reload.
          </p>
        </div>
      )}
      {!nameSpaces || (loader && !loader?.includes('Error')) ? (
        <div className="flex justify-center flex-col">
          <LoaderIcon title={loader} spinning={!loader.includes('Error')} />
          {loader.includes('You are already assigned to an organization') && (
            <div className="flex justify-center">
              <br></br>
              <button
                className="bg-primary hover:bg-hoverPrimary text-textWhite hover:text-textWhite rounded-sm px-2 m-1 py-1 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() => {
                  history.push('/home/onboarding/members')
                }}
              >
                Onboard my team
              </button>
            </div>
          )}
        </div>
      ) : (
        <ul className="divide-y divide-gray-200">
          {nameSpaces.map((group) => {
            let avatar_url = group?.avatar_url?.includes('https://')
              ? group?.avatar_url
              : `https://gitlab.com${group?.avatar_url}`
            if (group.avatar_url === null) {
              avatar_url = gitlab
            }
            return (
              <li key={group.id}>
                <div
                  href={'#'}
                  onClick={() => selectGroup(group.id)}
                  className="block hover:bg-gray-50 cursor-pointer"
                >
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="flex-shrink-0">
                        {group?.avatar_url === undefined ? (
                          <img className="h-12 w-12 rounded-full" src={gitlab} alt="" />
                        ) : (
                          <img
                            className="h-12 w-12 rounded-full"
                            src={avatar_url}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="text-sm font-medium text-indigo-600 truncate">
                            {group.name}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <span className="truncate">{group.full_path}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </MainDivOnboarding>
  )
}
