import { User } from './userReducer';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment'

let initialState = {
  timeZoneOffset: 0,
  startPullRequestReadyForReview: 'firstReviewRequest',
  startDate: moment().subtract(16, 'weeks').startOf('week').format('DD-MM-YYYY'),
  endDate: moment().subtract(1, 'weeks').endOf('week').format('DD-MM-YYYY'),
  selectedTeams: [],
  selectedRepositories: [],
  loadingInitialQuery: true,
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setData: (state, action) => {
      return ({
        ...state,
        ...action.payload,
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(User.actions.logout, _ => initialState);
  },
});

export const { setData } = settingSlice.actions;

const getSetting = state => state.setting;

export const Setting = {
  actions: settingSlice.actions,
  selectors: { getSetting },
};

