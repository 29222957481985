export const userMenu = (orgName) => [
  { name: orgName, href: '#' },
  { name: 'Billing', route: 'billing' },
  { name: 'Users', route: 'users' },
  { name: 'Log out' },
]

export const navigation = [
  { name: 'Team', route: 'team' },
  { name: 'Open Channels', route: 'open' },
  { name: 'Settings', route: 'settings' },
  { name: 'Analytics', route: 'analytics' },
]

export const subNavigation = {
  team: [{ name: 'Team management', route: 'team-management' }],
  open: [{ name: 'Open Slack channels', route: 'channels' }],
  settings: [
    { name: 'General', route: 'pull-request-channel' },
    { name: 'Reminder', route: 'reminders' },
    { name: 'Team channel', route: 'team-channel' },
    { name: 'Stand-up', route: 'standups' },
    { name: 'Integrations', route: 'integration' },
  ],
  analytics: [{ name: 'Dashboard', route: 'dashboard' }],
  admin: [
    { name: 'Billing', route: 'billing' },
    { name: 'Users', route: 'users' },
  ],
}
