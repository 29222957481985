import React, { Component } from 'react'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { Select } from 'antd'
import { updateTimeZoneOffset } from '../Settings/organization-timezone'

const { Option, OptGroup } = Select

export default class TimeZone extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.timeZone,
    }
  }

  onChange(selected) {
    const { organization, axios } = this.props
    this.props.setTimeZone(selected)
    updateTimeZoneOffset(organization, axios, selected)
    this.setState({ value: selected })
  }

  renderOptions() {
    // const { shortTimezone } = this.props
    const timezones = moment.tz.names()
    let mappedValues = {}
    let regions = []

    timezones.map((timezone) => {
      const splitTimezone = timezone.split('/')
      const region = splitTimezone[0]
      if (!mappedValues[region]) {
        mappedValues[region] = []
        regions.push(region)
      }
      mappedValues[region].push(timezone)
      return null
    })

    // if (shortTimezone) {
    //   const short = _.uniq(timezones.map((timezone) => moment.tz(timezone).format('z')))
    //   return short.map((timezone) => (
    //     <Option key={timezone} value={timezone}>
    //       {timezone}
    //     </Option>
    //   ))
    // }

    return regions.map((region) => {
      const options = mappedValues[region].map((timezone) => {
        return (
          <Option key={timezone} value={timezone}>
            {timezone}
            {/* {true ? moment.tz(timezone).format('z') : timezone} */}
          </Option>
        )
      })
      return (
        <OptGroup key={region} title={<div style={{ fontSize: 30 }}>{region}</div>}>
          {options}
        </OptGroup>
      )
    })
  }

  render() {
    return (
      <Select
        onChange={(value) => this.onChange(value)}
        value={this.state.value}
        showSearch
        style={{ width: this.props.width }}
        defaultValue={momentTimezone.tz.guess()}
        disabled={this.state.disabled}
      >
        {this.renderOptions()}
      </Select>
    )
  }
}
