import React from 'react'
import { IntegrationButton } from '../IntegrationButton'
// import { SentryIntegration } from '../SentryIntegration'
import { URLBACK } from '../../assets/urls'

export const OtherIntegrations = ({ user, organization }) => {
  const JiraCred =
    organization?.credentials?.filter(({ tool }) => tool === 'jira').length > 0
  return (
    <div className="grid grid-cols-2 gap-4 mb-12 justify-items-center	">
      {/* <SentryIntegration /> */}
      <div
        className="block h-20 justify-center text-center "
        onClick={() => window.open(`${URLBACK}identify/jira?id=${user.id}`)}
      >
        <IntegrationButton
          imageSrc="https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/jira_mark.png"
          text={JiraCred ? 'Installed, click to update' : 'Integrate Jira'}
        />
      </div>
      <div
        className="block h-20 justify-center text-center "
        onClick={() => {
          window.$crisp.push(['do', 'chat:open'])
        }}
      >
        <IntegrationButton
          imageSrc="https://axolo.s3.eu-west-3.amazonaws.com/media/logo/axolo_face_square.png"
          text="Request custom"
        />
      </div>
    </div>
  )
}
