import { UserOutlined } from '@ant-design/icons'
import { Avatar, Button } from 'antd'
import moment from 'moment'
import { URLBACK } from '../../assets/urls'
import { deleteStandup } from '../../reducers/standupReducer'
import messageInteraction from '../Analytics/utils/messageInteraction'
import SkeletonLoading from '../Analytics/utils/skeletonLoader'
import { AxoloTrashIcon } from '../Settings/setting-helper'
import { AxoloDaySelector } from '../utils/day-selector'

export const LoadingStandupData = () => {
  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      <tr key="newStandup">
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <SkeletonLoading specificCss="" />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
          <div className="flex items-center">
            <Avatar
              icon={<UserOutlined />}
              className="!mr-2 !flex !items-center !justify-center"
            ></Avatar>
            {/* <p className="pl-2 mb-0 text-gray-800">{engineer?.providerLogin}</p> */}
            <SkeletonLoading specificCss="!w-20 " />
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {' '}
          <SkeletonLoading specificCss="" />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {' '}
          <SkeletonLoading specificCss="" />
        </td>
      </tr>
    </tbody>
  )
}
export const StandupTimeInformation = ({
  standup,
  handleClickOnDay,
  timeZoneOffset,
  timeZone,
  personalStandup,
}) => {
  const time = moment(standup.timeUtc, 'HH:mm:ss')
    .add(timeZoneOffset, 's')
    .format('h:mm A')
  const StandupTime = () => {
    return (
      <div className="">
        {time} {timeZone}
      </div>
    )
  }
  return (
    <div className="">
      <StandupTime />
      <AxoloDaySelector
        handleClickOnDay={handleClickOnDay}
        daySelected={standup}
        standup={standup}
        personalStandup={personalStandup}
      />
    </div>
  )
}
export const StandupTitle = ({ personalStandup }) => {
  let title = 'My Stand-ups'
  let subTitle = 'A list of all the stand-ups you have created'
  if (!personalStandup) {
    title = "Other's Stand-ups"
    subTitle = "The rest of your organization's stand-ups"
  }
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
        <p className="mt-2 text-sm text-gray-700">{subTitle}</p>
      </div>
    </div>
  )
}

export const AddStandupButton = ({ addNewStandup, disabled }) => {
  return (
    <div className="flex justify-center">
      <button
        type="button"
        disabled={disabled}
        onClick={() => addNewStandup()}
        className="inline-flex items-center rounded-md border border-transparent bg-primary  px-4 py-2 font-medium text-white shadow-sm hover:bg-hoverPrimary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
      >
        Add
      </button>
    </div>
  )
}

export const DeletedStandupButton = ({
  standupId,
  creatorId,
  engineerId,
  axios,
  myStandups,
  dispatch,
}) => {
  async function handleDeleteStandup() {
    const hide = messageInteraction({
      content: 'Loading...',
      type: 'loading',
      duration: 0,
    })
    try {
      const res = await axios.get(
        `${URLBACK}settings/deleteOneStandup?standupId=${standupId}&creatorId=${creatorId}&userId=${engineerId}`,
      )
      hide()
      if (res.status === 200) {
        dispatch(deleteStandup({ id: standupId }))
      } else {
        throw new Error(`Status is not 200, ${res.message}`)
      }
    } catch (e) {
      hide()
      messageInteraction({
        content: 'Error deleting one standup.',
        type: 'error',
      })
      console.log('error in handleDeleteStandup:', e)
    }
  }

  return (
    <Button
      icon={<AxoloTrashIcon />}
      shape="circle"
      type="danger"
      onClick={() => handleDeleteStandup({})}
    />
  )
}
