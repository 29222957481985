import React from 'react'

import { Twemoji } from 'react-emoji-render'

export const AuthError = () => {
  return (
    <div className="h-screen w-screen flex justify-center items-center flex-col text-center">
      <img
        className="absolute top-10  h-10 w-auto sm:h-20"
        src="/logo_axolo.png"
        alt="Logo Axolo"
      />
      <Twemoji text="❓" className="pb-10 text-5xl" />
      <p className="pb-10 text-xl text-textWhite">
        An unknown error occured.
        <br /> Please, contact us through our in-app messenger or directly from Slack.
      </p>
    </div>
  )
}
