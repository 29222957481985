import { TimePicker } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { User } from '../../reducers/userReducer'
import TimeZone from './timeZone'

export const AxoloTimePicker = ({
  axios,
  format,
  onChangeTimePicker,
  disabled,
  defaultValue,
  height,
  width,
  timeZoneWidth = 280,
  shortTimezone = false,
  value,
}) => {
  const organization = useSelector(Organization.selectors.getOrganization)
  const user = useSelector(User.selectors.selectUser)
  const dispatch = useDispatch()
  return (
    <>
      <TimePicker
        style={{ height, width }}
        defaultValue={defaultValue}
        value={value}
        allowClear={false}
        format={format}
        disabled={disabled}
        onChange={onChangeTimePicker}
      />
      <TimeZone
        timeZone={organization.timeZone}
        setTimeZone={(timeZone) => dispatch(Organization.actions.setTimeZone(timeZone))}
        axios={axios}
        organization={user.organizations[0]}
        width={timeZoneWidth}
        shortTimezone={shortTimezone}
        disabled={disabled}
      ></TimeZone>
    </>
  )
}
