import React, { useState } from 'react'
import { message } from 'antd'
import { Switch, Popover } from 'antd'
import 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { User } from '../../reducers/userReducer'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { useAxiosWithHeader } from '../../utils'
import { cloneDeep } from 'lodash'
import momentTimezone from 'moment-timezone'
import PaywallModal from '../PaywallModal'
import { updateTimeZoneOffset } from './organization-timezone'
import ChannelTitle from './channelTitles'
import { LockClosedIcon } from '@heroicons/react/solid'
import { LabelManager } from './labelManager'
import { MaxEngineersInTeamReviewToInviteSetting } from './max-team-reviewers'
import WhiteListBot from './whiteListBot'

const delay = 3
let timeout

export const H2Settings = ({ content }) => {
  return <h2 className="text-xl font-semibold text-gray-900 ">{content}</h2>
}

export const Settings = ({ isSelectMembersReady }) => {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { plan, setting } = organization
  const orgIsPremium = plan === 'professional' || plan === 'trial' || plan === 'business'
  const user = useSelector(User.selectors.selectUser)
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  const [isModalPaywallVisible, setIsModalPaywallVisible] = useState(false)

  if (organization.timeZone === null || organization.timeZone === undefined) {
    const timeZone = momentTimezone.tz.guess()
    updateTimeZoneOffset(organization, axios, timeZone)
    dispatch(Organization.actions.setTimeZone(momentTimezone.tz.guess()))
  }

  async function toggleAutoArchiveSlackChannel() {
    if (orgIsPremium) {
      try {
        axios.get(
          `/organizations/updateSettings/${user.organizations[0].id}?setting=autoArchiveSlackChannel`,
        )
      } catch (error) {
        if (error?.response?.status === 402) {
          setIsModalPaywallVisible(true)
          return
        }
      }
      let newSettings = cloneDeep(setting)
      newSettings.autoArchiveSlackChannel = !newSettings.autoArchiveSlackChannel
      dispatch(Organization.actions.setData({ setting: newSettings }))
    } else {
      setIsModalPaywallVisible(true)
    }
    window.analytics.track('toggleAutoArchiveSlackChannel')
  }

  async function toggleNotificationForCheckRuns() {
    try {
      axios.get(
        `/organizations/updateSettings/${user.organizations[0].id}?setting=sendNotificationForCheckRuns`,
      )
    } catch (error) {
      if (error?.response?.status === 402) {
        setIsModalPaywallVisible(true)
        return
      }
    }
    let newSettings = cloneDeep(setting)
    newSettings.sendNotificationForCheckRuns = !newSettings.sendNotificationForCheckRuns
    dispatch(Organization.actions.setData({ setting: newSettings }))
  }

  async function updateCompanySetting(key, value, action = {}, HasTimeout = false) {
    const hide = message.loading('Loading...', 0)
    try {
      const resp = await axios.put(`/settings/${setting._id}`, {
        [key]: value,
        action,
      })
      const newSetting = resp.data
      if (HasTimeout) {
        if (timeout !== null) {
          clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
          dispatch(Organization.actions.setData({ setting: newSetting }))
          message.success(`Your setting has been updated.`)
        }, delay * 1000)
      } else {
        dispatch(Organization.actions.setData({ setting: newSetting }))
      }
      hide()
      return 200
    } catch (error) {
      message.warning('Error updating your setting. Try again or contact support.')
      hide()
      return error
    }
  }

  const SpecialWorkflow = () => {
    return (
      <>
        <H2Settings content="Special Workflows"></H2Settings>
        <div className="ml-10">
          <div
            className="flex justify-between"
            onClick={() => {
              if (!orgIsPremium) {
                setIsModalPaywallVisible(true)
              }
            }}
          >
            <p className="">
              As a reviewer, leave a PR channel automatically when I approve a pull
              request
            </p>
            <Switch
              style={{ backgroundColor: '#0049ff' }}
              disabled={!orgIsPremium}
              checked={setting?.leaveWhenApproved}
              onClick={() => {
                if (orgIsPremium) {
                  updateCompanySetting('leaveWhenApproved', !setting?.leaveWhenApproved)
                }
              }}
            />
          </div>
        </div>
      </>
    )
  }
  const AutoArchiveSlackChannel = ({ organization, orgIsPremium }) => (
    <>
      <H2Settings content="General"></H2Settings>
      <div className="ml-10">
        <div className="mb-8 flex justify-between">
          <p className="flex">
            Auto archive Slack channel if the associated pull request is merged or closed
            <span className="flex self-center pl-3">
              <Popover
                content={
                  <p>
                    If 'Auto Channel Archiver' is on, every pull request that your team
                    will close or merge <br />
                    will automatically archive the corresponding channel in Slack.
                  </p>
                }
                title="Auto Channel Archiver"
              >
                <InfoCircleTwoTone twoToneColor="#0049ff" />
              </Popover>
            </span>
          </p>
          <div onClick={() => toggleAutoArchiveSlackChannel(organization)}>
            <Switch
              style={{ backgroundColor: '#0049ff' }}
              disabled={!orgIsPremium}
              checked={setting?.autoArchiveSlackChannel}
            />
          </div>
        </div>
      </div>

      <div className="ml-10 mb-8">
        {organization.provider === 'github' && (
          <div className="flex justify-between">
            <p className="flex">
              Receive a summary when all checks are completed in a pull request channel
              <span className="flex self-center pl-3">
                <Popover
                  content={
                    <img
                      className="h-48"
                      src="https://axolo.s3.eu-west-3.amazonaws.com/communication/docs/all_checks.png"
                      alt="Axolo checks summary"
                    />
                  }
                  title="Checks summary"
                >
                  <InfoCircleTwoTone twoToneColor="#0049ff" />
                </Popover>
              </span>
            </p>
            <Switch
              style={{ backgroundColor: '#0049ff' }}
              checked={setting?.sendNotificationForCheckRuns}
              onClick={() => toggleNotificationForCheckRuns(organization)}
            />
          </div>
        )}
        {organization.provider === 'github' && (
          <MaxEngineersInTeamReviewToInviteSetting
            updateCompanySetting={updateCompanySetting}
          />
        )}
        <div
          className="mt-8 flex justify-between"
          onClick={() => {
            if (!orgIsPremium) {
              setIsModalPaywallVisible(true)
            }
          }}
        >
          <p className="flex ">
            <span className="mb-0 flex">
              Create <LockClosedIcon className="ml-2 mr-1 w-4" />
              private pull requests channels instead of public
            </span>
            <span className="flex self-center pl-3">
              <Popover
                content={
                  <ul className="w-[550px] list-inside list-disc">
                    <span className="font-medium">Pros of private channels:</span>{' '}
                    <li>No pull request channels cluttering your history.</li>
                    <li className="mb-2">
                      Pull requests stay private between creator, assignees and reviewers.
                    </li>
                    <span className="font-medium ">Cons of private channels:</span>{' '}
                    <li>
                      You cannot access Slack channels once merged (but everything stays
                      in GitHub).
                    </li>
                    <li>
                      The rest of the team cannot jump into the conversation without being
                      invited.{' '}
                    </li>
                  </ul>
                }
                title="Private pull request channels"
              >
                <InfoCircleTwoTone twoToneColor="#0049ff" />
              </Popover>
            </span>
          </p>
          <Switch
            style={{ backgroundColor: '#0049ff' }}
            checked={setting?.privateChannelForPr}
            disabled={!orgIsPremium}
            onClick={() => {
              if (orgIsPremium) {
                updateCompanySetting('privateChannelForPr', !setting?.privateChannelForPr)
              }
            }}
          />
        </div>
        <ChannelTitle organization={organization} setting={setting} />
        {organization.provider === 'github' && (
          <WhiteListBot
            organization={organization}
            setting={setting}
            updateCompanySetting={updateCompanySetting}
          />
        )}
      </div>
    </>
  )

  return (
    <div className="">
      <AutoArchiveSlackChannel organization={organization} orgIsPremium={orgIsPremium} />
      {organization.provider === 'github' && (
        <>
          <SpecialWorkflow />
          <LabelManager
            setIsModalPaywallVisible={setIsModalPaywallVisible}
            updateCompanySetting={updateCompanySetting}
          />
        </>
      )}
      <br />
      {isModalPaywallVisible ? (
        <PaywallModal
          isModalPaywallVisible={isModalPaywallVisible}
          setIsModalPaywallVisible={setIsModalPaywallVisible}
        />
      ) : null}
    </div>
  )
}
