import React from 'react'
import { IntegrationButton } from '../../components/IntegrationButton'
import OnboardingSteps from '../../components/OnboardingSteps'
import gitlab from '../../assets/images/gitlab-logo-500.png'
import { goGithubApp, goGitlabApp } from '../../components/Helpers'
import { useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { isEqual } from 'lodash'
import { Typography } from 'antd'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'

export default function WaitingApprovalGitHub() {
  const user = useSelector(User.selectors.selectUser, isEqual)
  return (
    <MainDivOnboarding>
      <OnboardingSteps />
      <br></br>
      <div className="flex justify-center">
        <Typography.Title level={4}>
          Choose to install Axolo on GitHub or on GitLab:
        </Typography.Title>
      </div>
      <div className="mb-12 grid grid-cols-2 gap-2">
        <div
          className="block h-20 justify-center justify-self-center text-center "
          onClick={() => goGithubApp(user.id, user.providerAuth)}
        >
          <IntegrationButton
            imageSrc="https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/github_logo.png"
            text="Install GitHub (organization only)"
          />
        </div>
        <div
          className="block h-20 justify-center justify-self-center text-center "
          onClick={() => goGitlabApp(user.id, user.providerAuth)}
        >
          <IntegrationButton imageSrc={gitlab} text="Install GitLab" />
        </div>
      </div>
    </MainDivOnboarding>
  )
}
