import { goGithubApp, goGitlabApp } from '../Helpers'
import gitlab from '../../assets/images/gitlab-logo-500.png'
import { URLBACK } from '../../assets/urls'
import { IntegrationButton } from '../IntegrationButton'

export const MainIntegrations = ({ user, organization }) => {
  const { provider = 'github' } = organization
  return (
    <div className="mb-12 grid grid-cols-2 gap-4">
      {provider === 'github' ? (
        <div
          className="block h-20 justify-center justify-self-center text-center "
          onClick={() => goGithubApp(user.id, user.providerAuth)}
        >
          <IntegrationButton
            imageSrc="https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/github_logo.png"
            text="Update GitHub app"
          />
        </div>
      ) : null
      // <div
      //   className="block h-20 justify-center text-center justify-self-center "
      //   onClick={() => goGitlabApp(user.id, user.providerAuth)}
      // >
      //   <IntegrationButton imageSrc={gitlab} text="Update GitLab" />
      // </div>
      }
      <div
        className="block h-20 justify-center justify-self-center text-center "
        onClick={() => window.open(`${URLBACK}identify/slack?id=${user.id}`, '_blank')}
      >
        <IntegrationButton
          imageSrc="https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/slack_mark.png"
          text="Update Slack app"
        />
      </div>
    </div>
  )
}
