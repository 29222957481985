import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export default function LoaderIcon({ size, title, spinning }) {
  const icon = <LoadingOutlined style={{ fontSize: size }} spin />

  return (
    <div className="flex flex-col">
      <Spin indicator={icon} spinning={spinning} />
      <p className="text-center text-slate-700 mt-5">{title}</p>
    </div>
  )
}
