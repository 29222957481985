import { Select } from 'antd'
import { AxoloTimePicker } from '../utils/axolo-timepicker'
import { AxoloDaySelector } from '../utils/day-selector'

const { Option } = Select

export const SelectTeamStandup = ({ teams, setTeamStandupSelect, teamStandupSelect }) => {
  const teamSelect = []
  teamSelect.push(
    <Option
      key={teams?.[0]?.organization || '1'}
      value="organization-wide"
      id={teams?.[0]?.organization}
    >
      organization-wide
    </Option>,
  )
  teamSelect.push(
    <Option
      key={'personalReminder'}
      value="only me (direct message)"
      id={'personalReminder'}
    >
      only me (direct message)
    </Option>,
  )
  for (let i = 0; i < teams?.length; i++) {
    const team = teams[i]
    teamSelect.push(
      <Option key={team.id} value={team.name} id={team.id}>
        {team.name}
      </Option>,
    )
  }
  const teamValue = teamSelect.find((team) => team?.props?.id === teamStandupSelect?.key)
    ?.props?.value
  return (
    <div className="flex">
      <Select
        allowClear
        style={{ width: 260 }}
        placeholder="Select a team"
        value={{ value: teamValue }}
        labelInValue
        onChange={(e) => setTeamStandupSelect(e)}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        {teamSelect}
      </Select>
    </div>
  )
}

export const StandupSelectChannels = ({
  slackChannels,
  setChannelSelected,
  channelSelected,
  teamStandupSelect,
  user,
}) => {
  const channelSelectOptions = []

  for (let i = 0; i < slackChannels?.length; i++) {
    const channel = slackChannels[i]
    channelSelectOptions.push(
      <Option key={channel.id} value={channel.name} id={channel.id}>
        {channel.name}
      </Option>,
    )
  }

  channelSelectOptions.push(
    <Option key={'personalChannel'} value="Direct message" id={user.authed_user}>
      Direct message
    </Option>,
  )

  let value = channelSelectOptions.find((c) => c?.props?.id === channelSelected?.key)
    ?.props?.value
  let disabled = false
  // If I select a personal reminder, I can't change the channel
  if (teamStandupSelect?.key === 'personalReminder') {
    value = 'Direct message'
    disabled = true
  }
  return (
    <div className="flex">
      <Select
        allowClear
        style={{ width: 140 }}
        placeholder="Select a channel"
        value={{ value }}
        labelInValue
        onChange={(e) => {
          setChannelSelected(e)
        }}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
        disabled={disabled}
      >
        {channelSelectOptions}
      </Select>
    </div>
  )
}

export const StandupSelectTime = ({
  setTimeSelected,
  axios,
  daySelected,
  setDaySelected,
  timeSelected,
}) => {
  const format = 'HH:mm'

  async function onChangeTimePicker(time, timeString) {
    // Time is a moment object and timestring is formated
    await setTimeSelected(time)
  }

  function handleClickOnDay({ day }) {
    setDaySelected({ ...daySelected, [day]: !daySelected[day] })
  }

  return (
    <div className="">
      <AxoloTimePicker
        format={format}
        axios={axios}
        onChangeTimePicker={onChangeTimePicker}
        value={timeSelected}
        height={32}
        width={100}
        timeZoneWidth={150}
        shortTimezone={true}
      />
      <div className="absolute w-full">
        <AxoloDaySelector handleClickOnDay={handleClickOnDay} daySelected={daySelected} />
      </div>
    </div>
  )
}
