import React from 'react'
import { useEffectOnce } from 'react-use'
import { useState } from 'react'
import { URLBACK } from '../../assets/urls'
import { useAxiosWithHeader } from '../../utils'
import { Table } from 'antd'
import { colors } from '../../components/Colors'
import momentTimezone from 'moment-timezone'
import StandupSetting from '../../components/StandupSetting'
// request admin access :
// import ButtonRequest from '../PlayerNotAdmin/buttonRequest'
import { columnsEngineer } from './columnsEngineer'
import { useDispatch } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { Engineer } from '../../reducers/engineerReducer'

export default function EngineerDashboardStandup() {
  const axios = useAxiosWithHeader()
  const [engineer, setEngineer] = useState({})
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  // request admin access :
  // const [loading, setLoading] = useState(true)
  // const [adminUsername, setAdminUsername] = useState('')
  // const [adminId, setAdminId] = useState('')
  // const [engineerId, setEngineerId] = useState('')

  const teamColor = {}
  engineer?.teams?.forEach((team) => {
    // To define the color of a team I'm taking the char code of the first letter of their team name.
    let colorNumber = Math.abs(team.name[0].toLowerCase().charCodeAt(0) - 97)
    if (colorNumber > colors.length) {
      colorNumber = colorNumber % colors.length
    }
    teamColor[team.name] = colors[colorNumber]
  })
  // request admin access :
  // const setDataForRedirection = async () => {
  //   const res = await axios.get(`${URLBACK}organizations/queryDataForRedirection`)
  //   setAdminUsername(res.data.admin.username)
  //   setAdminId(res.data.admin.id)
  //   setEngineerId(res.data.user.id)
  //   setLoading(false)
  // }

  useEffectOnce(() => {
    // request admin access :
    // setDataForRedirection().then()
    axios
      .get(`${URLBACK}engineer/me`)
      .then((engineerResp) => {
        setEngineer(engineerResp.data)
        setLoading(false)
        dispatch(Organization.actions.setTimeZone(momentTimezone.tz.guess()))
        dispatch(Engineer.actions.setData(engineerResp.data))
      })
      .catch((error) => {
        console.log('error getting engineer info', error)
        setEngineer(error)
      })
  })
  let columns = columnsEngineer({ teamColor, engineer })

  columns = columns.filter((column) =>
    engineer.provider === 'github'
      ? column.title !== 'GitLab Auth'
      : column.title !== 'GitHub Auth',
  )
  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <h1 className="text-xl font-semibold text-gray-900">My Axolo Profile</h1>
        <Table
          columns={columns}
          dataSource={[engineer]}
          pagination={false}
          rowKey="id"
          loading={loading}
        />
      </div>
      <br></br>
      <StandupSetting engineer={engineer} />
      {/* Request admin access */}
      {/* <h1>Permissions:</h1>
          <div className="flex justify-center justify-between">
            <p>Request {adminUsername?.split('_')[0]} Axolo admin access</p>
            <ButtonRequest
              axios={axios}
              URL={`${URLBACK}organizations/request-admin-right?admin=${adminId}&engineer=${engineerId}`}
              text={`Request access`}
              adminName={adminUsername?.split('_')[0]}
            />
          </div> */}
      {/* Request admin access */}
    </>
  )
}
