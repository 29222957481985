import { Button, Image, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { URLBACK } from '../../assets/urls'
import OnboardingSteps from '../../components/OnboardingSteps'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import { User } from '../../reducers/userReducer'

const allsteps = [
  { id: 'Step 1', name: 'Install GitHub or GitLab', href: '#', status: 'complete' },
  { id: 'Step 2', name: 'Install Slack', href: '#', status: 'current' },
  { id: 'Step 3', name: 'Onboard your team', href: '#', status: 'upcoming' },
]

export default function SlackOnboarding() {
  const user = useSelector(User.selectors.selectUser)
  return (
    <MainDivOnboarding>
      <OnboardingSteps steps={allsteps} />
      <div className="mt-10 flex flex-col justify-center text-center">
        <Typography.Title level={4}>Add Slack to your workspace</Typography.Title>
      </div>
      <div className="flex justify-center justify-self-center text-center">
        <Button
          style={{
            backgroundColor: '#531853',
            color: 'white',
            display: 'flex',
          }}
          className="!rounded-sm"
          onClick={() => window.open(`${URLBACK}identify/slack?id=${user.id}`, '_self')}
          id={'slackButton'}
          icon={
            <Image
              preview={false}
              src={process.env.PUBLIC_URL + '/slack.png'}
              width={21}
            />
          }
        >
          Add to Slack
        </Button>
      </div>
    </MainDivOnboarding>
  )
}
