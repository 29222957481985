import moment from 'moment'
import { PlayerState } from '../reducers/playersReducer'
import { Organization } from '../reducers/organizationReducer'
import { setIsSelectMembersReady } from '../reducers/organizationReducer'
import { message } from 'antd'
import messageInteraction from '../components/Analytics/utils/messageInteraction'

export async function listAllChannels({ axios, dispatch }) {
  try {
    const result = await axios.get(`/slack/listAllChannels`)
    const { status, data } = result
    if (status === 200 && data.length > 0) {
      dispatch(Organization.actions.setSlackChannels(data))
    }
  } catch (e) {
    messageInteraction({
      content:
        'Error listing your Slack channels. Please try to log out and try again or contact support.',
      type: 'error',
    })
    console.log('Error listing listAllChannels', e)
  }
}

export async function listAllSlackUsers({ axios, dispatch }) {
  dispatch(Organization.actions.setSlackMembersCalled(true))
  const result = await axios.get(`/organizations/p/getSlackUsersFromUser`)
  const { status, data } = result
  if (status === 200 && data.length > 0) {
    dispatch(Organization.actions.setSlackMembers(data))
  }
  dispatch(Organization.actions.setSlackMembersCalled(false))
}

export default async function getOrgandSlackMembers({
  invocation = 1,
  organization,
  slackAuth,
  dispatch,
  user,
  axios,
  setIsOnboarded,
  requestRefresh = false,
}) {
  if (
    // Adding a setting check because otherwise cannot load home properly
    organization.setting &&
    organization.setting !== '' &&
    !requestRefresh &&
    organization?.players?.length > 0 &&
    organization?.lastUpdated &&
    moment(organization.lastUpdated).isAfter(moment().subtract(10, 'minutes')) &&
    !moment(organization.createdAt).isAfter(moment().subtract(1, 'hours'))
  ) {
    dispatch(setIsSelectMembersReady(true))
    return
  }

  if (user.organizations[0]?.id === undefined && organization?.id === undefined) {
    dispatch(setIsOnboarded(false))
    dispatch(setIsSelectMembersReady(true))
    return
  }

  dispatch(setIsSelectMembersReady(false))
  const info = await axios.get(
    `/organizations/p/onboarding/${user.organizations[0]?.id || organization?.id}`,
  )
  const { org, slackMembers, pullRequests, setting, paymentPlan, emojiList } = info.data
  if (info?.data?.org?.id?.length > 0) {
    dispatch(
      Organization.actions.setData({
        ...org,
        slackMembers,
        pullRequests,
        setting,
        paymentPlan,
        emojiList,
      }),
    )
  }

  if (!moment(organization.createdAt).isAfter(moment().subtract(4, 'days'))) {
    try {
      // This route is to update team channels from Slack
      const channelsFromSlackUpdated = await axios.get(
        `/settings/retrieveTeamChannels/${setting.id}`,
      )
      if (channelsFromSlackUpdated.status === 200) {
        dispatch(Organization.actions.setData({ setting: channelsFromSlackUpdated.data }))
      }
    } catch (e) {
      console.log('Error in retrieve team channels', e)
    }
  }

  listAllChannels({ axios, dispatch })

  // Sometimes the server takes a a few seconds to get the developers list from github
  // So this parts will call this functions again every 1.5 seconds maximum 10 times
  if (org?.players && org?.players.length > 0 && slackMembers?.length > 0) {
    dispatch(setIsSelectMembersReady(true))
    dispatch(PlayerState.actions.setInitialPlayers({ players: org.players }))
    const activePlayers = org.players?.filter((p) => p.communicationToolId?.length > 0)
    if (activePlayers?.length > 0) {
      dispatch(setIsOnboarded(true))
    } else {
      dispatch(setIsOnboarded(false))
    }
  } else {
    if (invocation < 10) {
      invocation += 1
      setTimeout(
        () =>
          getOrgandSlackMembers({
            invocation,
            organization,
            slackAuth,
            dispatch,
            user,
            axios,
          }),
        1500,
      )
    } else {
      if (slackAuth) {
        message.error(
          "Couldn't find any engineers in your organization, reload this page or contact support",
        )
      }

      dispatch(setIsSelectMembersReady(true))
      dispatch(PlayerState.actions.setInitialPlayers({ players: org.players }))
    }
  }
}
