import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { User } from '../../reducers/userReducer';

export default function PaywallModal({
  isModalPaywallVisible,
  setIsModalPaywallVisible,
}) {
  const user = useSelector(User.selectors.selectUser);

  const [billingRedirection, setBillingRedirection] = useState(false);

  const handleGoPremium = () => {
    setBillingRedirection(true);
  };

  const handleCancel = () => {
    setIsModalPaywallVisible(false);
  };

  if (billingRedirection) {
    if (user.organizations) return <Redirect to={'/admin/billing'} />;
    else setIsModalPaywallVisible(false);
  }

  return (
    <>
      <Modal
        width={'80%'}
        style={{ maxWidth: '700px', fontFamily: 'Poppins' }}
        height={'90%'}
        visible={isModalPaywallVisible}
        onOk={handleGoPremium}
        onCancel={handleCancel}
        okText="Switch to a paid plan"
        okButtonProps={{
          disabled: user.organizations ? false : true,
        }}
        cancelText="Close"
      >
        <h3>This is a premium feature.</h3>
        <p>
          You're currently in the Starter plan. You'll need to subscribe to a
          paid plan in order to:{' '}
        </p>
        <ul className="list-disc list-inside">
          <li>Set the auto-archive on for your pull request channels</li>
          <li>Onboard more than 5 engineers on Axolo</li>
          <li>Customize your emojis</li>
          <li>Create private channels instead of public</li>
          <li>Enable special workflows</li>
          <li>Create team-specific _axolo channels (only for Business plan)</li>
        </ul>
        <p>
          If you'd like more information on the Professional plan, you can{' '}
          <a
            href="#"
            className="text-primary"
            onClick={() => {
              window.$crisp.push(['do', 'chat:open']);
            }}
          >
            contact us on the live chat
          </a>{' '}
          directly.
        </p>
      </Modal>
    </>
  );
}
