import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { PlayersList } from '../PlayersList';

import { setIsManageMemberModalVisible } from '../../reducers/organizationReducer';
import { useDispatch } from "react-redux";
import { PlayerState } from '../../reducers/playersReducer'
import { useSelector } from 'react-redux';

export const ManageTeamMembers = ({ organization, isSelectMembersReady, teamView = false }) => {
  let { slackMembers, plan } = organization;
  let players = useSelector(PlayerState.selectors.getPlayers)
  const dispatch = useDispatch();

  const showModal = () => {
    dispatch(setIsManageMemberModalVisible(true))
  };

  const handleOk = () => {
    dispatch(setIsManageMemberModalVisible(false));

  };

  const handleCancel = () => {
    dispatch(setIsManageMemberModalVisible(false));

  };
  return (
    <div className="flex justify-between mb-8 items-center" >
      {teamView ? <div className="flex items-center justify-center">
        <h2 className="text-lg">Manage engineers on Axolo</h2>
      </div> :
        <p>
          Manage your team
        </p>
      }
      <button
        onClick={showModal}
        className='bg-primary hover:bg-hoverPrimary text-textWhite hover:text-textWhite rounded-md px-2 py-1'
      >
        Manage my team
      </button>
      <Modal
        maskClosable={false}
        title={"Manage my team"}
        width={'90%'}
        style={{ minWidth: '900px', width: '90%', maxWidth: "1300px" }}
        height={'90%'}
        visible={organization.isManageMemberModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ style: { backgroundColor: '#0049ff' } }}
        okText={'Confirm'}
        cancelText={'Close'}
        bodyStyle={{ paddingBottom: "0px" }}
      >
        <PlayersList plan={plan} slackMembers={slackMembers} teams={organization.teams} />
        {/* <Modal.confirm style={{ backgroudColor: '#0049ff' }} /> */}

      </Modal>
    </div>
  )
}