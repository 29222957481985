import CompanyGeneral from './companyGeneral'
import FilterMenu from './filterMenu'
import { useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'

export default function CompanyInfo() {
  const analytics = useSelector(Analytics.selectors.getAnalytics)

  return (
    <>
      <main className=" pb-8">
        <div className="max-w-3xl mx-auto lg:max-w-7xl">
          <h1 className="sr-only">{analytics.providerLogin} dashboard</h1>
          {/* Main 3 column grid */}
          <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-7 lg:gap-8">
            {/* Left column */}
            <div className="grid grid-cols-1 gap-4 lg:col-span-4 min-h-max row-span-3 self-stretch">
              <CompanyGeneral />
            </div>

            {/* Right column */}
            <div className="grid grid-cols-1 gap-4 lg:col-span-3 row-span-3">
              <section aria-labelledby="section-2-title">
                <h2 className="sr-only" id="section-2-title">
                  Section title
                </h2>
                <div className="rounded-lg bg-white overflow-hidden shadow contents ">
                  <div className="rounded-lg shadow bg-white p-5">
                    <FilterMenu />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
