export const stripeKey =
  process.env.NODE_ENV === "production"
    ? "pk_live_51HC5hTFrmG96iBkeUwdZ5VMvaHK0gmHNRC70a1iz3TI70xhZ3vhabRt3KuHmIwD5bb2pQIQonJWbwjuXlT2anRAh00IphUoPrh"
    : 'pk_test_51IyIPQGYQFoJsqNvXEM4KLoCv25hv4RBYrDIiqlnn6SgisFnVuDZmWHciZIzgnhVi4AgbyxPQ32rjWu5QBHYoXQc000dZAWMGj';

export const professionalPricingIds = {
  monthly: process.env.NODE_ENV === 'production' ? 'price_1J04kiFrmG96iBkeZLjzRu4c' : 'price_1IyYAfGYQFoJsqNvDS8gWQI2',
  yearly: process.env.NODE_ENV === 'production' ? 'price_1Jn0VaFrmG96iBkeIxJdviCI' : 'price_1Jn0sPGYQFoJsqNv9FjgiZWX'
}

export const businessPricingIds = {
  monthly: process.env.NODE_ENV === 'production' ? 'price_1KgmkjFrmG96iBkeA2bilDbf' : 'price_1KgmqDGYQFoJsqNvphY0xiwg',
  yearly: process.env.NODE_ENV === 'production' ? 'price_1KgmlPFrmG96iBkePYdioEs7' : 'price_1KgmqmGYQFoJsqNvWnwYvhZl'
}