import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { H2Settings } from './index'
import { AxoloTrashIcon } from './setting-helper'
import {
  AddNewLabel,
  BehaviorList,
  handleDeleteLabel,
  InputNewLabel,
} from './labelManager-helper'
import { Button } from 'antd'
import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useAxiosWithHeader } from '../../utils'
import { URLBACK } from '../../assets/urls'
import { LoadingLabels } from './labelManager-utils'
import messageInteraction from '../Analytics/utils/messageInteraction'

const Columns = () => {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6 w-[28rem]"
        >
          Labels
        </th>
        <th
          scope="col"
          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        >
          Behaviors
        </th>
        <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">Action</span>
        </th>
      </tr>
    </thead>
  )
}
export const LabelManager = ({ setIsModalPaywallVisible, updateCompanySetting }) => {
  const organization = useSelector(Organization.selectors.getOrganization)
  const {
    plan,
    setting,
    setting: { labelManager },
  } = organization
  const defaultNewSetting = {
    'open-channel': true,
    'live-comment-from-slack': true,
    'notification-to-team-channels': true,
  }
  const axios = useAxiosWithHeader()
  const [organizationLabels, setOrganizationLabels] = useState([])
  const [loading, setLoading] = useState(true)

  async function fetchOrganizationLabels() {
    try {
      if (organization.provider === 'github') {
        const res = await axios.get(`${URLBACK}settings/fetchGithubLabels/${setting._id}`)
        setOrganizationLabels(res.data)
      }
      setLoading(false)
    } catch (e) {
      messageInteraction({
        content: 'Error fetching your organization labels.',
        type: 'error',
      })
      console.log('error in label management fetcher:', e)
    }
  }

  useEffectOnce(() => {
    fetchOrganizationLabels()
  })
  // we don't save the 'new' label. we only use it here to save behaviors for new labels.
  const [labelManagerSetting, setLabelManagerSetting] = useState({
    ...labelManager,
    new: defaultNewSetting,
  })

  const [newLabelSelected, setNewLabelSelected] = useState(null)

  const orgIsPremium = plan === 'professional' || plan === 'trial' || plan === 'business'

  function handleNewLabelSelected(element) {
    setNewLabelSelected(element)
  }

  return (
    <div className="mt-4">
      <H2Settings content="Label Management" />
      <div className="ml-10">
        <div
          className="flex justify-between"
          onClick={() => {
            if (!orgIsPremium) {
              setIsModalPaywallVisible(true)
            }
          }}
        >
          <p className="">Define Axolo behavior upon specific GitHub labels</p>
        </div>
        <div className="pr-4 sm:pr-6 lg:pr-8">
          <div className="mt-4 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 overflow-visible contents">
              <div className=" inline-block min-w-full py-2 align-middle pl-1 md:pr-6 lg:pr-8  overflow-visible">
                <div className="relative shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300 table-fixed">
                    <Columns />
                    {loading ? (
                      <LoadingLabels />
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {/* This is the list of all labels we found in the labelManager setting of the organization  */}
                        {Object.keys(labelManagerSetting).map((label) => {
                          // if the organization deletes a label we don't want to see it here
                          if (label === 'new') return null
                          const githubLabel = organizationLabels.find(
                            (githubLabel) => githubLabel.name === label,
                          )
                          if (!githubLabel) return null

                          const color = `#${githubLabel.color}`
                          return (
                            <tr key={label}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <span
                                  className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium
                            border-2 bg-opacity-50`}
                                  style={{
                                    backgroundColor: `${color}40`,
                                    color: color,
                                    borderColor: color,
                                  }}
                                >
                                  {label.toUpperCase()}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <BehaviorList
                                  labelSetting={labelManagerSetting[label]}
                                  label={label}
                                  labelManagerSetting={labelManagerSetting}
                                  setLabelManagerSetting={setLabelManagerSetting}
                                  updateCompanySetting={updateCompanySetting}
                                />
                              </td>

                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6 ">
                                <Button
                                  icon={<AxoloTrashIcon />}
                                  shape="circle"
                                  type="danger"
                                  onClick={() =>
                                    handleDeleteLabel({
                                      label,
                                      labelManagerSetting,
                                      setLabelManagerSetting,
                                      updateCompanySetting,
                                    })
                                  }
                                />
                              </td>
                            </tr>
                          )
                        })}

                        {/* last line to add new labels  */}
                        <tr key="new">
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <InputNewLabel
                              handleNewLabelSelected={handleNewLabelSelected}
                              labelManagerSetting={labelManagerSetting}
                              organizationLabels={organizationLabels}
                              newLabelSelected={newLabelSelected}
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <BehaviorList
                              setLabelManagerSetting={setLabelManagerSetting}
                              labelManagerSetting={labelManagerSetting}
                              labelSetting={labelManagerSetting.new}
                              label="new"
                              updateCompanySetting={updateCompanySetting}
                            />
                          </td>

                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                            <AddNewLabel
                              defaultNewSetting={defaultNewSetting}
                              newLabelSelected={newLabelSelected}
                              setLabelManagerSetting={setLabelManagerSetting}
                              labelManagerSetting={labelManagerSetting}
                              updateCompanySetting={updateCompanySetting}
                              setNewLabelSelected={setNewLabelSelected}
                            />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
