import { User } from './userReducer'
import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  providerLogin: '',
  organization: {},
  avatarUrl: '',
  htmlUrl: '',
  githubAuthed: false,
  gitlabAuthed: false,
  slackAuthed: false,
  slackUpToDate: false,
  slackAvatar: '',
  slackUsername: '',
  communicationToolId: '',
  slackTeam: '',
  provider: '',
  NPS: '',
  teams: '',
  id: '',
}

export const engineerSlice = createSlice({
  name: 'engineer',
  initialState,
  reducers: {
    setData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(User.actions.logout, (_) => initialState)
  },
})

export const { setData } = engineerSlice.actions

const getEngineer = (state) => state.engineer

export const Engineer = {
  actions: engineerSlice.actions,
  selectors: { getEngineer },
}
