import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Twemoji } from 'react-emoji-render'
import axios from 'axios'
import { URLBACK } from '../../assets/urls'
import { CheckIcon } from '@heroicons/react/solid'
import { useEffectOnce } from 'react-use'
import { Button, Avatar, Image, Spin } from 'antd'
import { RandomAxoloAvatar } from '../../components/RandomAxoloAvatar'
import { UserOutlined } from '@ant-design/icons'
import HomeFooter from '../FooterHomepage/footerHomePage'

const GitlabAuth = ({ authentication }) => {
  const src = authentication.gitlabAvatar
    ? authentication.gitlabAvatar
    : 'https://github.com/github.png?size=460'
  return (
    <div className="">
      <Avatar
        size={120}
        icon={<UserOutlined />}
        src={<Image src={src} preview={false} />}
      />
      <p className="">{authentication.providerLogin}</p>

      {authentication.gitlabAuth ? (
        <Button
          disabled={true}
          className="!inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <CheckIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          GitLab oAuth successful
        </Button>
      ) : (
        <a href={authentication.gitlabUrl}>
          <Button className="!inline-flex items-center px-6 py-3 !border !border-transparent !shadow-sm !text-base !font-medium !rounded-md !text-white !bg-primary !hover:bg-hoverPrimary !focus:outline-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-indigo-500">
            Sign in with GitLab
          </Button>
        </a>
      )}
    </div>
  )
}

const GithubAuth = ({ authentication }) => {
  const src = authentication.githubAvatar
    ? authentication.githubAvatar
    : 'https://github.com/github.png?size=460'
  return (
    <div className="">
      <Avatar
        size={120}
        icon={<UserOutlined />}
        src={<Image src={src} preview={false} />}
      />
      <p className="">{authentication.providerLogin}</p>

      {authentication.githubAuth ? (
        <Button
          disabled={true}
          className="!inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <CheckIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          GitHub oAuth successful
        </Button>
      ) : (
        <a href={authentication.githubUrl}>
          <Button className="!inline-flex items-center px-6 py-3 !border !border-transparent !shadow-sm !text-base !font-medium !rounded-md !text-white !bg-primary !hover:bg-hoverPrimary !focus:outline-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-indigo-500">
            Sign in with GitHub
          </Button>
        </a>
      )}
    </div>
  )
}

const SlackAuth = ({ authentication }) => {
  const src = authentication?.slackAvatar
    ? authentication?.slackAvatar
    : 'https://pbs.twimg.com/media/EWAJB4WUcAAje8s.png'
  return (
    <div className="">
      <Avatar
        size={120}
        icon={<UserOutlined />}
        src={<Image src={src} preview={false} />}
      />
      <p className="">{authentication.userName}</p>

      {authentication.slackAuth ? (
        <Button
          disabled={true}
          className="!inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <CheckIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          Slack oAuth successful
        </Button>
      ) : (
        <a href={authentication.slackUrl}>
          <Button className="!inline-flex !items-center !px-6 !py-3 !border !border-transparent !shadow-sm !text-base !font-medium !rounded-md !text-white !bg-primary !hover:bg-hoverPrimary !focus:outline-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-indigo-500">
            Sign in with Slack
          </Button>
        </a>
      )}
    </div>
  )
}

export default function AuthSuccess() {
  let { state } = useParams()
  const [authentication, setAuthentication] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffectOnce(async () => {
    try {
      const resAuthentication = await axios.get(
        `${URLBACK}player/isEngineerAuthed/${state}`,
      )
      setAuthentication(resAuthentication.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(true)
      console.log('error:', e)
    }
  })
  let authedMessage = 'Please sign in with GitHub & Slack'
  let fullyAuthed = false
  if (
    authentication.slackAuth &&
    (authentication.githubAuth || authentication.gitlabAuth)
  ) {
    authedMessage = "Thanks! You're all set"
    fullyAuthed = true
  } else if (authentication.slackAuth) authedMessage = 'Please sign with GitHub'
  else if (authentication.githubAuth) authedMessage = 'Please sign in with Slack'
  const { provider } = authentication
  if (provider === 'gitlab') {
    authedMessage = authedMessage.replace('GitHub', 'GitLab')
  }

  if (error)
    return (
      <div className="h-screen w-screen flex justify-center items-center flex-col text-center ">
        <img
          className="top-10 mb-10 h-10 w-auto sm:h-20"
          src="/logo_axolo.png"
          alt="Logo Axolo"
        />
        <div className="bg-textWhite max-w-[800px] rounded-xl">
          <div className="my-12 mx-10">
            <p className=" text-lg">Oups... Unknown error.</p>
            <p className=" text-lg">Please contact support.</p>
            <div className="flex justify-center">
              <RandomAxoloAvatar />
            </div>
          </div>
        </div>
      </div>
    )
  if (loading)
    return (
      <div className=" bg-white h-screen">
        <div className="bg-white h-[88vh] flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-3xl">
            <img className="mx-auto h-12 w-auto" src="/logo_axolo.png" alt="Logo Axolo" />
            <div className="">
              <h2 className="mt-6 text-center text-3xl font-bold text-ourBlack">
                Loading
              </h2>
              <div className="mt-24 flex justify-center">
                <Spin spinning />
              </div>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    )
  return (
    <div className=" bg-white h-screen">
      <div className="h-[88vh] flex flex-col justify-center py-12 sm:px-6 lg:px-8 items-center">
        <img
          className="mx-auto h-12 w-auto mb-8"
          src="/logo_axolo.png"
          alt="Logo Axolo"
        />
        <div className="bg-textWhite max-w-[800px] rounded-xl text-center ">
          <div className="my-12 inline-grid justify-items-center">
            <p className=" text-lg">{authedMessage}</p>
            {fullyAuthed ? <Twemoji text="🎉" className="pb-10 text-5xl" /> : null}
            <div className="grid grid-cols-2 gap-12 mx-12 mt-12 rounded-xl">
              {provider === 'gitlab' ? (
                <GitlabAuth authentication={authentication} />
              ) : (
                <GithubAuth authentication={authentication} />
              )}
              <SlackAuth authentication={authentication} />
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  )
}
