import { useDispatch, useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'
import { Setting } from '../../../reducers/settingReducer'
import ContinuousCycleTimeGraph from '.'
import messageInteraction from "../utils/messageInteraction";
import { useState, useEffect } from 'react'
import { useAxiosWithHeader } from '../../../utils'
import { URLBACK_VELOFRAME } from '../../../assets/urls'
import { createStandardQuery } from '../utils/query'

export default function ContinuousCycleTimeContainer({pullRequestRelations = 'events,review_requests,pull_request_reviews' }) {
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  const axios = useAxiosWithHeader('analytics')
  const setting = useSelector(Setting.selectors.getSetting)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const { temporality, id: organizationId } = analytics
    setLoading(true)
    async function getContinuousCycleTime({ axios, setting, organizationId, dispatch }) {
      try {
        const res = await axios.get(
          `${URLBACK_VELOFRAME}api/pull-requests/findContinuousCycleTime?pullRequestRelations=${pullRequestRelations}&status=merged&temporality=${temporality}&${createStandardQuery(
            { setting, organizationId },
          )}`,
        )
        dispatch(Analytics.actions.setData({ continuousCycleTime: res.data }))
      } catch (e) {
        messageInteraction({ content: 'Error in get continuous cycle time. Please refresh or contact support.', type: 'error' })
        setError(true)
        console.log('Error get continuous cycle time: ', e)
      }
      setLoading(false)
    }
    if (!setting.loadingInitialQuery) {
      getContinuousCycleTime({ axios, setting, organizationId, dispatch })
    }
  }, [setting.startDate, setting.endDate, setting.selectedRepositories, setting.selectedTeams])

  return (
    <ContinuousCycleTimeGraph loading={loading} error={error} />
  )
}
