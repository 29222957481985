import 'styled-components/macro'
import React, { useRef, useState } from 'react'
import { PlusCircleOutlined, CheckOutlined } from '@ant-design/icons'
import { Popover, Tag, Input, message, Button, Tooltip } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { cloneDeep } from 'lodash'
import { Organization } from '../../reducers/organizationReducer'
import { useDispatch } from 'react-redux'
import { useAxiosWithHeader } from '../../utils'
const slugify = require('slugify')

export default function ChannelTitle({ organization, setting }) {
  const inputRef = useRef()
  const dispatch = useDispatch()
  const axios = useAxiosWithHeader()
  const [value, setValue] = useState(organization.setting.channelTitle)
  const [validation, setValidation] = useState({ status: '', message: '' })

  async function updateChannelTitle(channelTitle) {
    if (channelTitle.length > 90) {
      setValidation({ status: 'error', message: '90 chars maximum.' })
      return
    }
    const tags = [
      '[pull_request_title]',
      '[branch_name]',
      '[pull_request_number]',
      '[repository_name]',
    ]
    const checkDuplicate = tags
      .map(function (e) {
        return channelTitle.indexOf(e, channelTitle.indexOf(e) + 1) !== -1
      })
      .includes(true)

    if (checkDuplicate) {
      setValidation({ status: 'warning', message: 'Duplicate tags.' })
      return
    }
    if (
      !channelTitle.includes('[pull_request_title]') &&
      !channelTitle.includes('[pull_request_number]')
    ) {
      setValidation({
        status: 'warning',
        message: 'Must include [pull_request_title] or the [pull_request_number].',
      })
      return
    }
    try {
      await axios.put(`/settings/${setting._id}`, {
        channelTitle,
      })
      message.success(`Your title ${channelTitle} has been saved.`)
      let newSettings = cloneDeep(setting)
      newSettings.channelTitle = channelTitle
      dispatch(Organization.actions.setData({ setting: newSettings }))
    } catch (error) {
      setValidation({
        status: 'error',
        message: `Couldn't update channel title ${error}`,
      })
    }
  }

  function isTagAlreadyIncluded(tag) {
    return value?.includes(tag) || false
  }

  function onChange(e) {
    const { value } = e.target
    if (value.length > 90) {
      setValidation({ status: 'error', message: '90 chars maximum.' })
      return
    }
    const tags = [
      '[pull_request_title]',
      '[branch_name]',
      '[pull_request_number]',
      '[repository_name]',
      '[status]',
    ]
    const checkDuplicate = tags
      .map(function (e) {
        return value.indexOf(e, value.indexOf(e) + 1) !== -1
      })
      .includes(true)
    if (checkDuplicate) {
      setValidation({ status: 'warning', message: 'Duplicate tags.' })
    }
    setValidation({ status: '', message: '' })
    setValue(value)
  }

  function insertTag(tag) {
    console.log('tag', tag)
    if (value.includes(tag)) {
      setValidation({
        status: 'warning',
        message: `${tag} is already in your channel title.`,
      })
      return
    }
    var index = inputRef?.current?.input?.selectionStart
    if (index || index === 0) {
      setValue(value.slice(0, index) + '_' + tag + value.slice(index + 1))
      updateChannelTitle(value.slice(0, index) + '_' + tag + value.slice(index + 1))
    } else {
      console.log('value + tag', value + tag)
      setValue(value + tag)
      updateChannelTitle(value + tag)
    }
  }

  function example() {
    const { pullRequests } = organization
    let {
      title = 'pr_title',
      data = { head: { ref: 'branch_name' } },
      number = 434,
      status = 'draft',
    } = {}
    if (pullRequests && pullRequests?.length > 0) {
      ;({ title, data, number, status } = pullRequests[0])
    }
    const repo = pullRequests[0]?.repository
    let channelTitle = value
    channelTitle = channelTitle?.replace('[pull_request_title]', title || 'pr_title')
    channelTitle = channelTitle?.replace(
      '[branch_name]',
      data?.head?.ref || 'example_branch',
    )
    channelTitle = channelTitle?.replace(
      '[pull_request_number]',
      number.toString() || '123',
    )
    channelTitle = channelTitle?.replace(
      '[repository_name]',
      repo?.name || 'example_repository_name',
    )
    channelTitle = channelTitle?.replace(
      '[creator_name]',
      pullRequests[0]?.creator?.providerLogin || 'johndoe',
    )
    channelTitle = channelTitle?.replace('[status]', status || 'draft') || ''
    channelTitle = slugify(channelTitle, { replacement: '_', trim: false })
    channelTitle = channelTitle?.replace(/[^\w\s]/g, '_')
    return channelTitle
  }

  function resetValue() {
    const initialValue = '_pr_[repository_name]_[pull_request_title]'
    setValue(initialValue)
    updateChannelTitle(initialValue)
  }

  const TagIconDiv = ({ children }) => {
    return <div className="inline-flex justify-center mr-1 ">{children}</div>
  }

  return (
    <>
      <div className="mt-8">
        <div className="flex justify-between flex-col">
          <div className="flex justify-between flex-row">
            <p className="flex mb-0">
              Channel titles
              <span className="pl-3 flex pt-[4px]">
                <Popover
                  content={'Customize your pull request Slack channel title'}
                  title="Channel titles"
                >
                  <InfoCircleTwoTone twoToneColor="#0049ff" />
                </Popover>
              </span>
            </p>
            <div style={{ minWidth: 550 }}>
              <div className="flex">
                <Input
                  status={validation.status}
                  // onBlur={() => updateChannelTitle(value)}
                  onBlur={() => {
                    updateChannelTitle(value)
                  }}
                  ref={inputRef}
                  value={value}
                  onChange={(e) => onChange(e)}
                />
                <Button onClick={resetValue} className="ml-2">
                  Reset
                </Button>
              </div>
              <p className="font-medium text-gray-500">Example: {example()}</p>
              {validation.status === 'warning' ? (
                <p className="font-extralight absolute text-yellow-800">
                  {validation.message}
                </p>
              ) : (
                <p className="font-extralight absolute text-red-400">
                  {validation.message}
                </p>
              )}
            </div>
          </div>
          <br></br>
          <div className="flex items-end flex-col">
            <p>Click on the variable you want to add:</p>
            <div>
              <Button
                disabled={isTagAlreadyIncluded('[pull_request_title]')}
                className="cursor-pointer"
                size="small"
                onClick={() => insertTag('[pull_request_title]')}
                icon={
                  <TagIconDiv>
                    <PlusCircleOutlined />
                  </TagIconDiv>
                }
              >
                Pull Request Title
              </Button>
              <Tooltip title="When a PR is in draft, 'draft' will be written, otherwise nothing will appear.">
                <Button
                  className="!ml-1 inline-grid"
                  size={'small'}
                  disabled={isTagAlreadyIncluded('[draft]')}
                  onClick={() => insertTag('[draft]')}
                  icon={
                    <TagIconDiv>
                      <PlusCircleOutlined />
                    </TagIconDiv>
                  }
                >
                  Draft
                </Button>
              </Tooltip>
              <Button
                className="!ml-1 inline-grid"
                size={'small'}
                disabled={isTagAlreadyIncluded('[branch_name]')}
                onClick={() => insertTag('[branch_name]')}
                icon={
                  <TagIconDiv>
                    <PlusCircleOutlined />
                  </TagIconDiv>
                }
              >
                Branch Name
              </Button>
              <Button
                className="!ml-1 inline-grid"
                size={'small'}
                disabled={isTagAlreadyIncluded('[pull_request_number]')}
                onClick={() => insertTag('[pull_request_number]')}
                icon={
                  <TagIconDiv>
                    <PlusCircleOutlined />
                  </TagIconDiv>
                }
              >
                Pull Request Number
              </Button>
              <Button
                className="!ml-1 inline-grid"
                size={'small'}
                disabled={isTagAlreadyIncluded('[repository_name]')}
                onClick={() => insertTag('[repository_name]')}
                icon={
                  <TagIconDiv>
                    <PlusCircleOutlined />
                  </TagIconDiv>
                }
              >
                Repository Name
              </Button>
              <Button
                className="!ml-1 inline-grid"
                size={'small'}
                disabled={isTagAlreadyIncluded('[creator_name]')}
                onClick={() => insertTag('[creator_name]')}
                icon={
                  <TagIconDiv>
                    <PlusCircleOutlined />
                  </TagIconDiv>
                }
              >
                Creator Name
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
