import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { User } from '../../reducers/userReducer'
import { useAxiosWithHeader } from '../../utils'
import { DeploymentStatusMultiSelect } from './githubDeployment.js'
import momentTimezone from 'moment-timezone'
import PaywallModal from '../PaywallModal'
import EmojiPickers from './emojiPicker'
import ChannelDeploymentOption from './deploy-channel'
import { updateTimeZoneOffset } from './organization-timezone'
import TeamChannelTable from '../EngineerTeamChannelContainer/teamChannelTable'
import { useEffectOnce } from 'react-use'
import { URLBACK } from '../../assets/urls'
import { Engineer } from '../../reducers/engineerReducer'

const H2Settings = ({ content }) => {
  return <h2 className="text-xl font-semibold text-gray-900">{content}</h2>
}

export const TeamChannelComponent = ({ isSelectMembersReady }) => {
  const organization = useSelector(Organization.selectors.getOrganization)
  const engineer = useSelector(Engineer.selectors.getEngineer)
  const { plan } = organization
  const orgIsPremium = plan === 'professional' || plan === 'trial' || plan === 'business'
  const { setting } = organization
  const user = useSelector(User.selectors.selectUser)
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  const [isModalPaywallVisible, setIsModalPaywallVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  if (organization.timeZone === null || organization.timeZone === undefined) {
    const timeZone = momentTimezone.tz.guess()
    updateTimeZoneOffset(organization, axios, timeZone)
    dispatch(Organization.actions.setTimeZone(momentTimezone.tz.guess()))
  }

  useEffectOnce(() => {
    if (!engineer?.id) {
      setLoading(true)
      axios
        .get(`${URLBACK}engineer/me`)
        .then((engineerResp) => {
          setLoading(false)
          dispatch(Organization.actions.setTimeZone(momentTimezone.tz.guess()))
          dispatch(Engineer.actions.setData(engineerResp.data))
        })
        .catch((error) => {
          console.log('error getting engineer info', error)
        })
    }
  })

  return (
    <div className="">
      <TeamChannelTable loadingTeamChannels={loading} isAdmin={true} />
      <div className="mt-4 mb-8 flex justify-between">
        <p className="">Choose your own emoji reaction for each pull request status</p>
        <EmojiPickers
          setting={setting}
          emojiList={organization.emojiList}
          setIsModalPaywallVisible={setIsModalPaywallVisible}
          orgIsPremium={orgIsPremium}
        />
      </div>
      <H2Settings content="Deployments"></H2Settings>
      <div className="">
        <ChannelDeploymentOption
          organization={organization}
          setting={setting}
          user={user}
        />
      </div>
      <div className="">
        <div className="mt-8 mb-8 flex justify-between">
          <p className="">Select which deployment status you wish to receive</p>
          <DeploymentStatusMultiSelect
            setting={setting}
            statusSelected={setting?.githubDeploymentStatusNotifications}
            axios={axios}
          />
        </div>
      </div>{' '}
      <br />
      {isModalPaywallVisible ? (
        <PaywallModal
          isModalPaywallVisible={isModalPaywallVisible}
          setIsModalPaywallVisible={setIsModalPaywallVisible}
        />
      ) : null}
    </div>
  )
}
