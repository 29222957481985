import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { createBrowserHistory } from 'history'
import { User } from '../../reducers/userReducer'
import { Organization } from '../../reducers/organizationReducer'
import { setIsOnboarded, PlayerState } from '../../reducers/playersReducer'
import { AppContent } from '../../components/utils/utils'
import { useAxiosWithHeader, useQuery } from '../../utils'
import { useEffectOnce } from 'react-use'
import { Redirect } from 'react-router'
import getOrgandSlackMembers from '../../api/getOrgandSlackMembers'
import LoadingPage from '../Loading'
import { URLBACK } from '../../assets/urls'
import { Engineer } from '../../reducers/engineerReducer'

export function Home() {
  const history = createBrowserHistory()
  let prevPath = null

  history.listen((location) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname
      window.analytics.page()
    }
  })
  const [installingSlack, setinstallingSlack] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const user = useSelector(User.selectors.selectUser, isEqual)
  const organization = useSelector(Organization.selectors.getOrganization, isEqual)
  const isOnboarded = useSelector(PlayerState.selectors.isOnboarded)

  // This is so that getOrgandSlackMembers only gets called once at a time.
  let query = useQuery()
  const axios = useAxiosWithHeader()

  const dispatch = useDispatch()

  const slackAuth =
    user?.credential?.teamId.length > 0 ||
    user.slackAuth ||
    organization?.credentials?.find((credential) => credential.tool === 'slack') !==
      undefined
  const githubAuth = user.organizations[0]?.providerLogin?.length > 0

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffectOnce(() => {
    ;(async () => {
      const isSetup = query.get('github')
      if (
        user &&
        user.requestGithubApp === false &&
        isSetup &&
        organization.players?.length === 0
      ) {
        // need a time out so we can first get the user in due time
        await setTimeout(() => {
          getOrgandSlackMembers({ organization, dispatch, user, axios, setIsOnboarded })
        }, 1000)
      }
      if (!organization.slackMembers) {
        getOrgandSlackMembers({ organization, dispatch, user, axios, setIsOnboarded })
      }
    })()
  }, [axios, dispatch, query, user, getOrgandSlackMembers])

  useEffect(() => {
    ;(async () => {
      const isSetup = query.get('slack')
      if (isSetup) {
        setinstallingSlack(true)
      }
      if (user && isSetup && !user.slackAuth) {
        try {
          const { data } = await axios.put('/users/' + user.id, {
            slackAuth: true,
          })
          dispatch(User.actions.updateUser(data))
          message.destroy()
          message.success('Slack successfully installed', 2.5)

          // TODO should call those two functions in other places
          // May be when someone loggs in, so it gets refreshed in case there are new members
          await getOrgandSlackMembers({
            organization,
            dispatch,
            user,
            axios,
            setIsOnboarded,
          })
        } catch (e) {
          console.error({ e })
          message.error('Error, please try again or contact support')
        }
      }
    })()
  }, [axios, dispatch, query, user, getOrgandSlackMembers])

  // act that user has connected his github
  useEffect(() => {
    ;(async () => {
      const isSetup = query.get('setup_action')
      if (user && isSetup && !user.providerAuth) {
        try {
          const { data } = await axios.put('/users/' + user.id, {
            providerAuth: true,
          })
          dispatch(User.actions.updateUser(data))
          if (data && data.providerAuth && data.organizations.length > 0) {
            message.success('GitHub app successfully installed')
          }
        } catch (e) {
          console.error({ e })
          message.error('Error, please try again or contact support')
        }
      }
    })()
  }, [axios, dispatch, query, user])

  // User logs in when he is already connected to slack
  // we update his org and slackmembers
  async function updateUser() {
    try {
      const resp = await axios.get('/users/' + user.id)
      if (resp?.data?.email?.length > 0) {
        dispatch(User.actions.updateUser(resp.data))
      }
    } catch (error) {
      console.log('updateUser error', error)
    }
  }

  useEffectOnce(() => {
    const isSetup = query.get('slack')
    getOrgandSlackMembers({
      invocation: 10,
      organization,
      dispatch,
      user,
      axios,
      setIsOnboarded,
    })
    updateUser()
    if (isSetup === 'error') {
      message.destroy()
      message.error(
        'It seems there was an error when installing Slack. Please try again or contact support',
      )
    }
  }, [axios, dispatch, query, user, getOrgandSlackMembers, updateUser])

  // if admin has providerAuth === true but no organization that means it did not have the right to install the app in its organization
  useEffect(() => {
    ;(async () => {
      const activePlayers = organization.players?.filter(
        (p) => p.communicationToolId?.length > 0,
      )
      // If User install from GitHub and we are waiting for him to add Slack it goes here.
      if (
        slackAuth === false &&
        organization?.sender?.length > 0 &&
        user.username === organization.sender &&
        activePlayers.length === 0 &&
        isOnboarded === null
      ) {
        dispatch(setIsOnboarded(false))
      }
      // If user is GitHub Admin who just accepted to install GitHub then we redirect him
      if (
        organization?.requester?.length > 0 &&
        user.provider === 'github' &&
        user?.providerLogin !== organization?.requester
      ) {
        setRedirect(true)
        return
      }

      const isSetup = query.get('setup_action')
      message.destroy()
      if (
        user &&
        isSetup &&
        user.providerAuth &&
        user.organizations[0]?.providerLogin?.length <= 0 &&
        user.target_type === 'user'
      ) {
        message.error(
          'Axolo can only be installed on Organizations. Please install Axolo on an organization.',
        )
      } else if (
        user &&
        isSetup &&
        user.providerAuth &&
        user.organizations[0]?.providerLogin?.length <= 0
      )
        message.error(
          'It seems you do not have admin right on Github or that you tried to install Axolo on a personnal account, please install Axolo on your organization & come back!',
          10,
        )
      else if (
        user &&
        isSetup &&
        user.providerAuth &&
        user.organizations[0]?.providerLogin?.length > 0
      )
        message.success('Github app successfully installed')
    })()
  }, [axios, dispatch, query, user])

  useEffectOnce(() => {
    axios
      .get(`${URLBACK}engineer/me`)
      .then((engineerResp) => {
        dispatch(Engineer.actions.setData(engineerResp.data))
      })
      .catch((error) => {
        console.log('error getting engineer info', error)
      })
  })

  if (redirect) {
    return <Redirect to={`/githubInstalled/${user.username}`} />
  }
  if (isOnboarded === false) {
    // Order matters
    if (!slackAuth && githubAuth) {
      // This means the admin accepted to install Axolo on GitHub
      if (
        organization?.requester?.length > 0 &&
        user.provider === 'github' &&
        user?.providerLogin !== organization?.requester
      ) {
        return <Redirect to={`/githubInstalled/${user.username}`} />
      }
      return <Redirect to={'/home/onboarding/slack'} />
    }
    if (slackAuth && githubAuth) {
      return <Redirect to={'/home/onboarding/members'} />
    }
    if (slackAuth && user.requestGithubApp) {
      return <Redirect to={'/home/onboarding/github'} />
    }
    if (slackAuth && !githubAuth) {
      return <Redirect to={'/home/onboarding'} />
    }
    return (
      <div>
        <AppContent className="mt-4 flex">
          <h1 className="pt-8 text-center text-xl">Something wrong happened</h1>
          <p className="text-center">
            If you are seeing this screen, try to log out and log in again or contact
            support.
          </p>
          <span
            className="flex justify-center pt-8"
            onClick={() => dispatch(User.actions.logout({}))}
          >
            <button
              type="primary"
              className="rounded-lg border-none bg-primary px-4 py-1 text-textWhite hover:bg-indigo-700 hover:text-textWhite"
            >
              Logout
            </button>
          </span>
        </AppContent>
      </div>
    )
  }
  if (isOnboarded === null || !(organization?.players?.length > 0)) {
    return (
      <LoadingPage
        footer={false}
        title={'Fetching your information. Can take up to 2 minutes.. '}
      />
    )
  } else {
    return <Redirect to={`/settings/pull-request-channel`} />
  }
}

export default Home
