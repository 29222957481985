const allsteps = [
  { id: 'Step 1', name: 'Install Slack', href: '#', status: 'complete' },
  { id: 'Step 2', name: 'Install GitHub or GitLab', href: '#', status: 'current' },
  { id: 'Step 3', name: 'Onboard your team', href: '#', status: 'upcoming' },
]

export default function OnboardingSteps({ steps = allsteps, step = 1 }) {
  if (step > 2) {
    steps[1].status = 'complete'
    steps[2].status = 'current'
  }
  return (
    <nav aria-label="Progress">
      <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.status === 'complete' ? (
              <span
                href={step.href}
                className="group pl-4 py-2 flex flex-col border-l-4 border-primary hover:border-primary md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
              >
                <span className="text-xs text-primary font-semibold tracking-wide uppercase group-hover:text-primary">
                  {step.id}
                </span>
                <span className="text-sm font-medium text-slate-500">{step.name}</span>
              </span>
            ) : step.status === 'current' ? (
              <span
                href={step.href}
                className="pl-4 py-2 flex flex-col border-l-4 border-blue-500 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4 "
                aria-current="step"
              >
                <span className="text-xs text-blue-600 font-bold tracking-wide uppercase opacity:50">
                  {step.id}
                </span>
                <span className="text-sm font-medium text-black">{step.name}</span>
              </span>
            ) : (
              <span
                href={step.href}
                className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
              >
                <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
                  {step.id}
                </span>
                <span className="text-sm font-medium text-slate-600">{step.name}</span>
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}
