import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { URLBACK } from '../../assets/urls'
import StandupSetting from '../../components/StandupSetting'
import { useAxiosWithHeader } from '../../utils'

export default function StandupPage() {
  const axios = useAxiosWithHeader()
  const [engineer, setEngineer] = useState({})
  const [globalLoader, setGlobalLoader] = useState(true)

  useEffectOnce(() => {
    axios
      .get(`${URLBACK}engineer/me`)
      .then((engineerResp) => {
        setEngineer(engineerResp.data)
        setGlobalLoader(false)
      })
      .catch((error) => {
        console.log('error getting engineer info', error)
        setEngineer(error)
      })
  })
  // if (globalLoader) return 'bloop'
  return <StandupSetting engineer={engineer} globalLoader={globalLoader} />
}
