import React, { useState } from 'react';

import AvatarPlayer from '../AvatarPlayer';
import { useEffectOnce } from 'react-use';

export const RandomAxoloAvatar = () => {
  let [avatarPosition, setAvatarPosition] = useState({
    body: { x: -192 * Math.floor(Math.random() * 10), y: 0 },
    eyes: { x: -192 * Math.floor(Math.random() * 10), y: 0 },
    mouth: { x: -192 * Math.floor(Math.random() * 10), y: 0 },
  });

  useEffectOnce(() => {
    setInterval(function () {
      setAvatarPosition({
        body: { x: -192 * Math.floor(Math.random() * 10), y: 0 },
        eyes: { x: -192 * Math.floor(Math.random() * 10), y: 0 },
        mouth: { x: -192 * Math.floor(Math.random() * 10), y: 0 },
      });
    }, 5000);
  });

  return (
    <AvatarPlayer avatarInfo={avatarPosition} />
  )
}