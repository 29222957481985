import { Popconfirm } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getOrgandSlackMembers from '../../api/getOrgandSlackMembers'
import { URLBACK } from '../../assets/urls'
import { Organization } from '../../reducers/organizationReducer'
import { setIsOnboarded } from '../../reducers/playersReducer'
import { User } from '../../reducers/userReducer'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'
import { useAxiosWithHeader } from '../../utils'
import InviteAdminModal from './inviteUserModal'

const role = (type, role) => {
  if (type === 'billing') return 'Billing'
  if (type === 'restrictedAccess') return 'Restricted Access'
  if (role.name === 'Engineer') return 'Engineer'
  return 'Admin'
}

async function handleInviteAdmin({
  axios,
  email,
  organization,
  slackAuth,
  dispatch,
  user,
}) {
  try {
    const res = await axios.get(`${URLBACK}organizations/grantAdminRole?email=${email}`)
    if (res.status !== 200) {
      messageInteraction({
        content: 'Error granting an admin. Please try again or contact support',
        type: 'error',
      })
      console.log('Error granting an admin: ')
    } else {
      messageInteraction({ content: `${email} was added as an admin.`, type: 'success' })
      getOrgandSlackMembers({
        organization,
        slackAuth,
        dispatch,
        user,
        axios,
        setIsOnboarded,
        requestRefresh: true,
      })
    }
  } catch (e) {
    messageInteraction({
      content: 'Error granting an admin. Please try again or contact support',
      type: 'error',
    })
    console.log('Error granting an admin: ', e)
  }
}

const UserLine = ({ person, personIdx, open, setOpen }) => {
  const userRole = role(person.type, person.role)
  const axios = useAxiosWithHeader()
  const organization = useSelector(Organization.selectors.getOrganization)
  const user = useSelector(User.selectors.selectUser)
  const slackAuth =
    user?.credential?.teamId.length > 0 ||
    user.slackAuth ||
    organization?.credentials?.find((credential) => credential.tool === 'slack') !==
      undefined
  const dispatch = useDispatch()

  return (
    <tr key={person.email} className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {person.username?.split('_')?.[0] || person.username}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.email}
      </td>
      <td className="flex items-center justify-between whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {userRole}
        {userRole === 'Engineer' && (
          <Popconfirm
            title={`Are you sure you want to grant admin role to ${person.username}`}
            onConfirm={() =>
              handleInviteAdmin({
                axios,
                email: person.email,
                organization,
                slackAuth,
                dispatch,
                user,
              })
            }
            okText="Yes"
            cancelText="No"
          >
            <button
              type="button"
              className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-hoverPrimary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Grant admin role
            </button>
          </Popconfirm>
        )}
      </td>
    </tr>
  )
}

export default function Example() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const [openAccessModal, setOpenAccessModal] = useState(false)
  const [openInviteAdminModal, setOpenInviteAdminModal] = useState(false)

  const { users = [] } = organization
  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700">
            List of users who have logged in to Axolo. Admin and billing role have access
            to settings and billing section. Engineers can only see their profile.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() => setOpenInviteAdminModal(true)}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-hoverPrimary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Invite a user
          </button>
          <InviteAdminModal
            open={openInviteAdminModal}
            setOpen={setOpenInviteAdminModal}
          />
        </div>
      </div>
      <div className="mt-3 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th>
                    {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {users.map((person, personIdx) => (
                    <UserLine
                      key={personIdx}
                      person={person}
                      personIdx={personIdx}
                      open={openAccessModal}
                      setOpen={setOpenAccessModal}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
