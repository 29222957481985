import './App.less'
import 'antd/dist/antd.min.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Login } from './pages/Login/Login'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import HomeFooter from './pages/FooterHomepage/footerHomePage'

window.$crisp = []
window.CRISP_WEBSITE_ID = 'e55d5c33-8163-469b-8799-071e4bb34d9f'
;(function () {
  var d = document
  var s = d.createElement('script')
  s.src = 'https://client.crisp.chat/l.js'
  s.async = 1
  d.getElementsByTagName('head')[0].appendChild(s)
})()
window.$crisp.push(['safe', true])

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://3af51e4ffe364600a1682a83a3a72413@o992721.ingest.sentry.io/6105769',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

class App extends Component {
  render() {
    return (
      <div className="h-[100vh] bg-white">
        <Login />
        <HomeFooter />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(null, mapDispatchToProps)(App)
