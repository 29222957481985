import React, { useState } from 'react'
import moment from 'moment'
import { Button, Table, PageHeader, message } from 'antd'
import { useEffectOnce } from 'react-use'
import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'

function ButtonWithLoader(archiveSlackChannel, id) {
  async function archiveSlackChannelAction() {
    const hide = message.loading('Loading...', 0)
    const isItArchived = archiveSlackChannel(id)
    if (isItArchived) {
      hide()
    } else {
      hide()
      message.error('It seems Axolo is not in this channel and cannot archive it.')
    }
  }

  return <Button onClick={() => archiveSlackChannelAction()}>Archive Channel</Button>
}

export default function ArchiveChannels() {
  const axios = useAxiosWithHeader()
  // const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const organization = useSelector(Organization.selectors.getOrganization)

  useEffectOnce(() => {
    ;(async () => {
      getChannelsList()
    })()
  })

  const columns = [
    {
      width: 150,
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id) => ButtonWithLoader(archiveSlackChannel, id),
    },
    {
      width: 400,
      title: 'Name',
      dataIndex: 'name',
      key: 'id',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      width: 100,
      title: '# of members',
      dataIndex: 'num_members',
      key: 'id',
      sorter: (a, b) => a.num_members - b.num_members,
    },
    {
      width: 250,
      title: 'Created',
      dataIndex: 'created',
      key: 'id',
      render: (created) => moment.unix(created).format('MMM Do YYYY, h:mm:ss a'),
      sorter: (a, b) => a.created - b.created,
    },
  ]

  async function getChannelsList() {
    setLoadingTable(true)
    try {
      const result = await axios.get(`/slack/listChannels`)
      // const filteredChannels = result.data.filter((channel) =>
      //   channel.members.includes(organization.botSlackId),
      // );
      setDataSource(result.data)
    } catch (e) {
      messageInteraction({
        content:
          'Error listing your Slack channels. Please try to log out and try again or contact support',
        type: 'error',
      })
      console.log('Error listing Slack channels', e)
    }
    setLoadingTable(false)
  }

  async function archiveSlackChannel(id) {
    try {
      const result = await axios.get(`/slack/archiveChannels/${id}`)
      if (result.status === 200) {
        message.success('Channel archived')
        const newDataSource = dataSource.filter((channels) => channels.id !== id)
        setDataSource(newDataSource)
        return true
      }
    } catch (error) {
      return false
    }
    return false
  }

  return (
    <>
      <div className="block lg:flex items-end mb-4">
        <h2 className="text-lg !mb-0">Channels</h2>
        <h3 className="text-gray-400 font-normal lg:ml-8 !mb-0">
          Manually archive Slack channels
        </h3>
      </div>

      <div className="mt-12 flex justify-between">
        <Table
          loading={loadingTable}
          dataSource={dataSource}
          columns={columns}
          rowKey={(record) => record.id}
        />
      </div>
    </>
  )
}
