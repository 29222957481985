import React, { useState } from 'react'
import { Select, Tooltip, Button, Avatar } from 'antd'
import { cloneDeep, sortBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import CreatableSelect from 'react-select/creatable'
import { PlusOutlined } from '@ant-design/icons'
import { Engineer } from '../../reducers/engineerReducer'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { handleErrorsInputTeamChannel } from './helpers'
import {
  classActionColumn,
  classChannelColumn,
  classCreatorColumn,
  classTeamColumn,
} from '../EngineerTeamChannelContainer/teamChannelTableHelper'

const slugify = require('slugify')

const initialState = {
  title: '',
  titleStatus: '',
  existingChannel: false,
  teams: [],
  teamStatus: '',
}

export default function TeamChannelInputTable({ setLoading, loading, children }) {
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  const organization = useSelector(Organization.selectors.getOrganization)
  const engineer = useSelector(Engineer.selectors.getEngineer)
  const { setting } = organization
  const [state, setState] = useState(initialState)
  const [channelsState, setChannelsState] = useState()
  const [teamState, setTeamState] = useState()

  const shouldDisableInput = () => {
    const { plan } = organization
    const { channels } = setting
    if (plan === 'trial' || plan === 'business') {
      return false
    }
    if (!channels || channels?.length === 0) {
      return false
    }
    const channelEnabled = channels.reduce((channel) => !channel.desactivate)
    if (plan !== 'trial' && plan !== 'business' && channelEnabled) {
      return true
    }
    return false
  }

  const handleTagChange = (values) => {
    setTeamState(values)
    if (values.length === 0) {
      return setState({ ...state, teams: [], teamStatus: 'warning' })
    }
    const ids = values.map((value) => value.key)
    setState({ ...state, teams: ids, teamStatus: '' })
  }

  const onSave = async () => {
    setLoading(true)
    const hide = messageInteraction({
      content: 'Loading',
      type: 'loading',
      duration: 0,
    })
    const isError = handleErrorsInputTeamChannel({ state, setting })
    if (isError) {
      setLoading(false)
      hide()
      return
    }

    const { title, teams, id, existingChannel } = state
    try {
      let newSettings = cloneDeep(setting)
      const creator = {
        avatarUrl: engineer.avatarUrl,
        providerLogin: engineer.providerLogin,
        id: engineer.id,
        htmlUrl: engineer.htmlUrl,
      }
      if (newSettings.channels) {
        newSettings.channels.push({ title, teams, id, existingChannel, creator })
      } else {
        newSettings.channels = [{ title, teams, id, existingChannel, creator }]
      }
      const updatedSettingsResp = await axios.put(`/settings/${setting._id}`, {
        channels: newSettings.channels,
        updatingTeamChannel: true,
        newChannel: { title, teams, id, existingChannel, creator },
      })
      setLoading(false)
      hide()
      if (updatedSettingsResp.status === 200) {
        if (
          typeof updatedSettingsResp.data === 'string' &&
          updatedSettingsResp.data.includes('Error')
        ) {
          hide()
          messageInteraction({
            content: updatedSettingsResp.data,
            type: 'error',
          })
        } else {
          hide()
          messageInteraction({
            content: 'Your channel has been created.',
            type: 'success',
          })
          dispatch(Organization.actions.setData({ setting: updatedSettingsResp.data }))
          setState(initialState)
          // clearing input values for channel and teams
          setChannelsState(null)
          setTeamState(undefined)
        }
      }
    } catch (error) {
      console.log('Error creating a team channel', error)
      setLoading(false)
      if (error?.response?.status === 402) {
        messageInteraction({
          content: 'This feature is for Business plan only.',
          type: 'error',
        })
      } else {
        console.log("Couldn't create your channel", error)
        messageInteraction({
          content: "Couldn't create your channel. See logs.",
          type: 'error',
        })
      }
    }
  }

  const channelsOptions = sortBy(
    organization?.slackChannels?.map((c) => {
      const obj = {
        value: c.name,
        label: c.name,
        id: c.id,
      }
      return obj
    }),
    'value',
  )
  const handleTitleChange = (newValue) => {
    if (newValue?.label && newValue?.__isNew__) {
      let { label } = newValue
      label = slugify(label, { replacement: '_', trim: false })
      label = label?.replace(/[^\w\s]/g, '_').toLowerCase()
      newValue.label = label
      newValue.value = label
    }
    setChannelsState(newValue)
    if (newValue === null) {
      return
    }
    const { value = '', id = '' } = newValue
    if (newValue?.id?.length > 0) {
      setState({ ...state, title: value, id, existingChannel: true })
    } else if (newValue) {
      setState({ ...state, title: value, existingChannel: false })
    } else {
      setState({ ...state, title: '', titleStatus: '' })
    }
  }
  const formatLabel = (inputValue) => {
    inputValue = slugify(inputValue, { replacement: '_', trim: false })
    inputValue = inputValue?.replace(/[^\w\s]/g, '_').toLowerCase()
    return `Create new channel "${inputValue}"`
  }

  const disableInput = shouldDisableInput() || loading

  return (
    <tr key="newTeamChannelLine">
      <td className={classChannelColumn}>
        <CreatableSelect
          createOptionPosition="first"
          isClearable
          onChange={handleTitleChange}
          openMenuOnClick={false}
          options={channelsOptions}
          formatCreateLabel={formatLabel}
          value={channelsState}
          placeholder="Select or create a channel..."
          className="max-w-[275px]"
        />
      </td>
      <td className={classTeamColumn}>
        {' '}
        <Select
          status={state.teamStatus}
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select"
          labelInValue
          value={teamState}
          onChange={(e) => handleTagChange(e)}
          className="max-w-[400px]"
        >
          {children}
        </Select>
      </td>
      <td className={classCreatorColumn}>
        <div className="flex items-center">
          <Avatar src={engineer?.avatarUrl}></Avatar>
          <p className="mb-0 pl-2 text-gray-800">{engineer?.providerLogin}</p>
        </div>
      </td>

      <td className={classActionColumn}>
        <span href="#" className="text-indigo-600 hover:text-indigo-900">
          <div className="flex  justify-center">
            {disableInput ? (
              <Tooltip
                title="Upgrade to the business plan to access this functionality"
                placement="top"
                trigger={['hover']}
              >
                <div>
                  <Button
                    disabled={disableInput}
                    icon={<PlusOutlined />}
                    onClick={() => onSave()}
                    shape="circle"
                    type="primary"
                    className="!text-white"
                  />
                </div>
              </Tooltip>
            ) : (
              <Button
                disabled={disableInput}
                icon={<PlusOutlined />}
                onClick={() => onSave()}
                shape="circle"
                type="primary"
              />
            )}
          </div>
        </span>
      </td>
    </tr>
  )
}
