import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import App from './App.js'
import SlackAuth from './components/AuthSlack/index.js'
import { PrivateRoute } from './components/PrivateRoute'
import Home from './pages/Home/index.js'
import PlayerNotAdmin from './pages/PlayerNotAdmin/index.js'
import Billing from './pages/Billing'
import ArchiveChannels from './pages/Channels'
import GithubAuth from './components/AuthGithub/index.js'
import GithubInstalled from './pages/GithubInstalled/index.js'
import AuthSuccess from './pages/AuthSuccess'
import { AuthError } from './pages/AuthError'
import PullRequestChannelSettings from './pages/PullRequestChannelSettings/index.js'
import TeamChannelSettings from './pages/TeamChannelSettings/index.js'
import OpenChannels from './pages/OpenChannels/index.js'
import Team from './pages/Team'
import AdministrationPage from './pages/Administration/administration'
import { Error } from './pages/404/index.js'
import IntegrationPage from './pages/IntegrationPage/index.js'
import AnalyticsPage from './pages/Analytics/index.js'
import ProcessingInstallation from './components/Analytics/ProcessingInstallation/ProcessingInstallation.js'
import GitLabGroups from './pages/GitLabGroups/index.js'
import OnboardingInviteMembers from './pages/InviteMembersOnboarding/index.js'
import WaitingApprovalGitHub from './pages/Onboarding/index.js'
import GitHubOnboarding from './pages/Onboarding/githubOnboarding.js'
import SlackOnboarding from './pages/Onboarding/slackOnboarding.js'
import EngineerDashboardStandup from './pages/EngineerStandup/index.js'
import StandupPage from './pages/Standups/index.js'
import EngineerDashboardTeamChannel from './pages/EngineerTeamChannel/index.js'
import ReminderPage from './pages/Reminder/index.js'
import EngineerDashboardReminder from './pages/EngineerReminder/index.js'

export default function Root() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-axoloBlack to-secondaryBlack">
      <Router>
        <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route exact path="/auth/github/callback">
            <GithubAuth />
          </Route>
          <Route exact path="/auth/slack/callback">
            <SlackAuth />
          </Route>
          <PrivateRoute exact path="/redirection-admin">
            <PlayerNotAdmin />
          </PrivateRoute>
          <Route exact path="/githubInstalled/:user">
            <GithubInstalled />
          </Route>
          <Route exact path="/player/connect/:state">
            <AuthSuccess />
          </Route>
          <Route exact path="/player/connect/github/error">
            <AuthError />
          </Route>
          <Route exact path="/player/connect/slack/error">
            <AuthError />
          </Route>
          {/* BEGIN ONBOARDING */}
          <PrivateRoute exact path="/home">
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path="/engineer/settings/standups">
            <EngineerDashboardStandup />
          </PrivateRoute>
          <PrivateRoute exact path="/engineer/settings/team-channel">
            <EngineerDashboardTeamChannel />
          </PrivateRoute>
          <PrivateRoute exact path="/engineer/settings/reminders">
            <EngineerDashboardReminder />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding">
            <WaitingApprovalGitHub />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/slack">
            <SlackOnboarding />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/github">
            <GitHubOnboarding />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/gitlab">
            <GitLabGroups />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/members">
            <OnboardingInviteMembers />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/open-channels">
            <OpenChannels finishButton />
          </PrivateRoute>
          {/* END ONBOARDING */}
          <PrivateRoute exact path="/home/archive-channels">
            <ArchiveChannels />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/billing">
            <Billing />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/pull-request-channel">
            <PullRequestChannelSettings />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/team-channel">
            <TeamChannelSettings />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/standups">
            <StandupPage />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/reminders">
            <ReminderPage />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/integration">
            <IntegrationPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/billing/:status">
            <Billing />
          </PrivateRoute>
          <PrivateRoute exact path="/team/team-management">
            <Team />
          </PrivateRoute>
          <PrivateRoute exact path="/open/channels">
            <OpenChannels />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/users">
            <AdministrationPage />
          </PrivateRoute>
          <PrivateRoute exact path="/analytics/:path">
            <AnalyticsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/analytics/processing-installation">
            <ProcessingInstallation />
          </PrivateRoute>
          <Route path="*">
            <Error />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}
